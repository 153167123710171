import { useQuery } from '@tanstack/react-query';

import { cloudEnterpriseApi } from '../endpoints';
import { queryKeys } from './queryKeys';

export function useFleetMembers() {
  return useQuery({
    queryKey: queryKeys.fleet.members,
    queryFn: () => cloudEnterpriseApi.getMembers(),
    select: (data) => ({
      ...data,
      users: data?.users?.sort((a, b) => {
        const aName = a.email ?? '';
        const bName = b.email ?? '';
        return aName.localeCompare(bName);
      }),
    }),
  });
}
