import { HTMLAttributes } from 'react';

import { Typography } from '@remarkable/ark-web';

import { TestId } from 'src/testids';
import { AccountLinkingMismatchError } from 'src/utils/errors';
import { userHelpers } from 'src/utils/userHelpers';

import { Link2 } from './Link2';

interface Props extends HTMLAttributes<HTMLDivElement> {
  error: AccountLinkingMismatchError;
}

export function ErrorAccountLinkingMismatch({ error, ...props }: Props) {
  const currentUserEmail =
    userHelpers.getEmail(error.currentUser) ||
    userHelpers.getEmailFromClaims(error.currentUser);
  const expectedUserEmail =
    userHelpers.getEmail(error.expectedUser) ||
    userHelpers.getEmailFromClaims(error.expectedUser);

  return (
    <div
      data-cy={TestId.PageEnterpriseEnrollmentAccountLinkingMismatchError}
      {...props}
    >
      <div className="m-auto flex w-full max-w-screen-md flex-col items-center gap-24 px-24 py-64 text-center md:px-64 lg:py-128">
        <Typography variant="heading-lg" className="text-center">
          SSO verification failed
        </Typography>
        <Typography variant="body-md-regular">
          We expected you to log in with{' '}
          <b>{expectedUserEmail || 'unknown email'}</b>, but you were logged in
          as <b>{currentUserEmail || 'unknown email'}</b> instead.
        </Typography>
        <Typography variant="body-md-regular">
          This might have happened if your identity provider automatically
          signed you into an existing account. To fix this, sign out of your
          current SSO session or open this page in a private/incognito window.
          Then, try again to verify your SSO login.
        </Typography>

        <Link2
          to="/login"
          search={{
            redirect: error.redirect,
            login_hint: expectedUserEmail,
            connection:
              userHelpers.getConnectionFromUserId(error.expectedUser) ??
              undefined,
          }}
          as="button"
          variant="primary"
          className="ark-button ark-button--primary mt-32"
        >
          Sign back in
        </Link2>
      </div>
    </div>
  );
}
