import { HTMLAttributes } from 'react';

import { getRouteApi } from '@tanstack/react-router';
import clsx from 'clsx';
import { X } from 'phosphor-react';

import {
  SubscriptionCancellationFlowExited,
  SubscriptionCancellationFlowStarted,
} from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { ComponentLocations } from 'src/analytics/trackingTypes';
import { Button, Modal } from 'src/components';

import { MrfModal } from './MrfModal';

const routeApi = getRouteApi('/_auth/_layout/subscriptions/plan');

function parseStepNumber(
  cancel_subscription?:
    | 'step-1'
    | 'step-2'
    | 'step-3'
    | 'step-4'
    | 'step-5'
    | 'confirmation'
    | undefined
): number | null {
  if (!cancel_subscription) return null;
  const match = cancel_subscription?.match(/^step-(\d)$/);
  return match ? parseInt(match[1], 10) : null;
}

export const CancelSubscriptionButton = ({
  className,
  ...rest
}: HTMLAttributes<HTMLButtonElement>) => {
  const search = routeApi.useSearch();
  const navigate = routeApi.useNavigate();

  return (
    <Modal.Root
      onOpenChange={(open) => {
        if (open) {
          tracker.trackEvent(
            new SubscriptionCancellationFlowStarted({
              component_location:
                ComponentLocations.MANAGE_CONNECT.SUBSCRIPTION_PAGE,
              total_steps: 5,
            })
          );
          void navigate({ search: { cancel_subscription: 'step-1' } });
        } else {
          const mrfStep = parseStepNumber(search.cancel_subscription);
          if (mrfStep) {
            tracker.trackEvent(
              new SubscriptionCancellationFlowExited({
                component_location: ComponentLocations.MRF_MODAL.MRF_MODAL,
                total_steps: 5,
                step_number: mrfStep,
              })
            );
          }

          void navigate({ search: { cancel_subscription: undefined } });
        }
      }}
      open={
        search.cancel_subscription !== undefined &&
        search.cancel_subscription !== 'confirmation'
      }
    >
      <Modal.Trigger asChild>
        <Button
          size="large"
          variant="tertiary-neutral"
          className={clsx('w-full ls:w-fit', className)}
          data-cy="cancel-subscription"
          analytics={{
            text: 'Cancel subscription',
            location: ComponentLocations.MANAGE_CONNECT.SUBSCRIPTION_PAGE,
            action: 'open cancel subscription flow',
          }}
          {...rest}
        >
          <X />
          <span>Cancel subscription</span>
        </Button>
      </Modal.Trigger>

      <MrfModal />
    </Modal.Root>
  );
};
