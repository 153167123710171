import { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { z } from 'zod';

import { SomethingWentWrong, Spinner } from 'src/components';
import { LogCategory } from 'src/utils/logger.categories';
import { sanitizeRedirectUrl } from 'src/utils/sanitizeRedirectUrl';

const loginSearchSchema = z.object({
  redirect: z.string().startsWith('/').optional(),
  connection: z.string().optional(),
  login_hint: z.string().optional(),
  screen_hint: z.enum(['signup', 'login']).optional(),
  organization: z.string().optional(),
  prompt: z.enum(['none']).optional(),
  invitation: z.string().optional(),
});

export const Route = createFileRoute('/_public/login')({
  validateSearch: zodValidator(loginSearchSchema),
  component: () => <LoginPage />,
});

function LoginPage() {
  const auth = useAuth0();
  const search = Route.useSearch();

  const login = useMutation({
    mutationFn: () => {
      const connection =
        search.connection === 'email'
          ? 'Username-Password-Authentication'
          : search.connection;

      Sentry.addBreadcrumb({
        category: LogCategory.Auth,
        message: 'Logging in',
        data: {
          prompt: search.prompt,
          withOrganization: !!search.organization,
          withConnection: !!search.connection,
          withLoginHint: !!search.login_hint,
          withScreenHint: !!search.screen_hint,
          withInvitation: !!search.invitation,
          withRedirect: !!search.redirect,
        },
      });

      return auth.loginWithRedirect({
        authorizationParams: {
          prompt: search.prompt,
          organization: search.organization,
          connection,
          login_hint: search.login_hint,
          screen_hint: search.screen_hint,
          invitation: search.invitation,
          redirect_path: sanitizeRedirectUrl(search.redirect),
        },
        appState: {
          returnTo: sanitizeRedirectUrl(search.redirect),
        },
      });
    },
    onError(error) {
      Sentry?.captureEvent({
        message: 'Login failed',
        extra: {
          errorMessage: error.message,
        },
      });
    },
  });

  useEffect(() => {
    if (login.isIdle && !auth.isLoading) {
      login.mutate();
    }
  }, [login.isIdle, auth.isLoading]);

  if (login.error) {
    return <SomethingWentWrong error={login.error} />;
  }

  return <Spinner />;
}
