import { createFileRoute, redirect } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import jwtDecode from 'jwt-decode';
import { z } from 'zod';

import { RmClaims } from 'src/api/queries/user';
import { EnterpriseMembersPage } from 'src/apps/fleet/app/FleetApp';
import { createPageMetaTitle } from 'src/utils/createPageMetaTitle';
import { AccessDeniedError } from 'src/utils/errors';
import { userHelpers } from 'src/utils/userHelpers';

const routeSearchSchema = z.object({
  member: z.string().optional(),
  invite_by: z.enum(['email']).optional(),
});
export const Route = createFileRoute(
  '/_auth/_layout/enterprise/_with-org/_layout/members'
)({
  staticData: { title: createPageMetaTitle('Fleet') },
  validateSearch: zodValidator(routeSearchSchema),
  async beforeLoad({ context }) {
    const auth = await context.auth;

    if (!auth.user) {
      throw redirect({ to: '/login' });
    }

    const accessToken = await auth.getAccessTokenSilently();
    const claims = jwtDecode<RmClaims>(accessToken);

    if (!userHelpers.hasPermissionReadMembers(claims)) {
      throw new AccessDeniedError(
        'You do not have permission to view this page. Please contact your IT admin to request access.'
      );
    }
  },
  component: () => <EnterpriseMembersPage />,
});
