import { InputHTMLAttributes } from 'react';

import { Typography } from '@remarkable/ark-web';

import { cn } from 'src/utils/classNamesHelper';

import { CopyButton } from './CopyButton';

export function CopyText(
  props: InputHTMLAttributes<HTMLButtonElement> & { value?: string }
) {
  return (
    <div
      className={cn(
        'flex items-center justify-between rounded border border-neutral-light-8 py-8 pl-16 pr-12 ring-interaction-primary transition-all focus-within:border-interaction-primary focus-within:ring-1',
        props.className
      )}
    >
      <Typography variant="interface-md-regular" className="leading-5">
        {props.value}
      </Typography>
      <CopyButton
        noText
        value={props.value}
        variant="tertiary-neutral"
        {...props}
        size="medium"
      />
    </div>
  );
}
