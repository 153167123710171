import { useRef, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@remarkable/ark-web';
import { ToOptions } from '@tanstack/react-router';
import clsx from 'clsx';
import { ArrowUpRight, CaretUp, House, ShoppingCart, X } from 'phosphor-react';

import { tracker } from 'src/analytics/tracker';
import { ComponentLocations } from 'src/analytics/trackingTypes';
import { Link2 } from 'src/components/Link2';
import { LinkExternal } from 'src/components/LinkExternal';
import { defaultConfig } from 'src/config';
import { useSideMenuState } from 'src/hooks/useSideMenuState';
import { isUserRemarkableInternal } from 'src/utils/auth';
import { SUPPORT_URL } from 'src/utils/urls/supportUrls';
import { URLS } from 'src/utils/urls/urls';
import { useOnClickOutside } from 'src/utils/useOnClickOutside';
import { userHelpers } from 'src/utils/userHelpers';

export const SideMenu = () => {
  const sideMenu = useSideMenuState();
  const menuRef = useRef<HTMLDivElement>(null);
  const auth = useAuth0();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleMenu = () => sideMenu.toggle();
  useOnClickOutside(menuRef, () => sideMenu.close());

  if (!auth.user || auth.user?.email_verified === false) return null;

  return (
    <div
      ref={menuRef}
      className={`fixed left-0 top-0 z-50 flex h-full w-auto min-w-320 flex-col bg-neutral-light-3 pb-40 pl-40 pr-20 pt-20 transition-transform duration-300 lm:w-[480px] lm:pb-64 lm:pl-[70px] lm:pr-40 lm:pt-40 ${
        sideMenu.isOpen ? 'translate-x-0' : '-translate-x-full'
      }`}
    >
      <div className="my-16 flex w-full flex-row items-center justify-between">
        <div className="flex flex-row gap-24 lm:gap-32">
          <LinkExternal to={URLS.RM_COM}>
            <House size={32} /> Home
          </LinkExternal>
          <LinkExternal to={URLS.RM_COM_STORE}>
            <ShoppingCart size={32} /> Store
          </LinkExternal>
        </div>

        <button aria-label="Close menu" onClick={toggleMenu}>
          <X size={32} />
        </button>
      </div>

      <Link2 to="/" className="heading-subtle-2xl mt-32" onClick={toggleMenu}>
        my.reMarkable.com
      </Link2>
      <nav className="flex grow flex-col justify-between overflow-y-auto">
        <ul className="body-md-regular ml-48 mt-20 flex flex-col gap-12 font-medium lm:gap-16">
          <li>
            <Link2
              inline
              to="/account"
              onClick={toggleMenu}
              data-cy="nav-account"
              className="no-underline hover:underline"
              componentLocation={ComponentLocations.MENU.SIDE_MENU}
            >
              Account
            </Link2>
          </li>

          {userHelpers.isLoggedInToOrganization(auth.user) ? (
            <li>
              <Link2
                inline
                to="/enterprise"
                onClick={toggleMenu}
                data-cy="nav-enterprise"
                className="no-underline hover:underline"
                componentLocation={ComponentLocations.MENU.SIDE_MENU}
              >
                Enterprise
              </Link2>
            </li>
          ) : (
            <li>
              <Link2
                inline
                to="/subscriptions"
                onClick={toggleMenu}
                data-cy="nav-subscription"
                className="no-underline hover:underline"
                componentLocation={ComponentLocations.MENU.SIDE_MENU}
              >
                Subscription
              </Link2>
            </li>
          )}

          <li>
            <Link2
              inline
              to={URLS.DEVICE}
              onClick={toggleMenu}
              data-cy="nav-device-and-apps"
              className="no-underline hover:underline"
              componentLocation={ComponentLocations.MENU.SIDE_MENU}
            >
              Devices and apps
            </Link2>
          </li>
          <li>
            <Link2
              inline
              to={URLS.INTEGRATIONS}
              onClick={toggleMenu}
              data-cy="nav-integrations"
              className="no-underline hover:underline"
              componentLocation={ComponentLocations.MENU.SIDE_MENU}
            >
              Integrations
            </Link2>
          </li>
          {isUserRemarkableInternal(auth.user) && (
            <li>
              <Link2
                inline
                to={URLS.SCREENSHARE_HOME as ToOptions['to']}
                onClick={toggleMenu}
                data-cy="nav-screen-share"
                className="no-underline hover:underline"
                componentLocation={ComponentLocations.MENU.SIDE_MENU}
              >
                Screen Share
              </Link2>
            </li>
          )}
          <li className="relative">
            <div className="flex items-center gap-x-[10px]">
              <Link2
                to={URLS.MYFILES}
                onClick={toggleMenu}
                data-cy="nav-my-files"
                inline
                className="no-underline hover:underline"
                componentLocation={ComponentLocations.MENU.SIDE_MENU}
              >
                My files
              </Link2>

              <CaretUp
                className={clsx(
                  'inline-block cursor-pointer select-none',
                  'transition-transform duration-300',
                  isDropdownOpen ? 'rotate-0' : 'rotate-180'
                )}
                onClick={toggleDropdown}
                weight="light"
                size={24}
              />
            </div>
            {/* This is a good candidate for an AnimatePresence motion implementation */}
            {isDropdownOpen && (
              <ul className="rounded-md ml-16 mt-4">
                <li>
                  <Link2
                    to={URLS.MYFILES_TRASH as ToOptions['to']}
                    onClick={toggleMenu}
                    data-cy="nav-my-files"
                    className="inline-block"
                    componentLocation={ComponentLocations.MENU.SIDE_MENU}
                  >
                    <Typography
                      variant="interface-sm-medium"
                      className="text-black"
                    >
                      Trash
                    </Typography>
                  </Link2>
                </li>
              </ul>
            )}
          </li>
        </ul>

        <ul className="mt-8 flex flex-col gap-16 text-18 font-semibold">
          <li>
            <LinkExternal
              inline
              to={SUPPORT_URL.HOME}
              onClick={toggleMenu}
              data-cy="nav-support"
              className="no-underline hover:underline"
              componentLocation={ComponentLocations.MENU.SIDE_MENU}
            >
              <Typography variant="interface-sm-medium" className="text-black">
                <span>Support and FAQ</span>
                {/* FIXME - This size is not taking effect and something is off on hover */}
                <ArrowUpRight
                  size={24}
                  weight="light"
                  className="ml-[10px] inline max-h-[24px] max-w-[24px]"
                />
              </Typography>
            </LinkExternal>
          </li>
          <li>
            <LinkExternal
              inline
              to={defaultConfig.MethodsUrl}
              onClick={() => {
                toggleMenu();
                tracker.identify({
                  myrm_utm_parameters: JSON.stringify({
                    myrm_utm_source: 'sidebar_menu',
                  }),
                });
              }}
              data-cy="nav-remarkable-methods"
              className="no-underline hover:underline"
              componentLocation={ComponentLocations.MENU.SIDE_MENU}
            >
              <Typography variant="interface-sm-medium" className="text-black">
                <span>reMarkable Methods</span>
                {/* FIXME - This size is not taking effect and something is off on hover */}
                <ArrowUpRight
                  size={24}
                  weight="light"
                  className="ml-[10px] inline max-h-[24px] max-w-[24px]"
                />
              </Typography>
            </LinkExternal>
          </li>
          <li>
            <LinkExternal
              inline
              to={URLS.RM_COM_USING_REMARKABLE}
              onClick={toggleMenu}
              data-cy="nav-using-remarkable"
              className="no-underline hover:underline"
              componentLocation={ComponentLocations.MENU.SIDE_MENU}
            >
              <Typography variant="interface-sm-medium" className="text-black">
                <span>Using reMarkable</span>
                {/* FIXME - This size is not taking effect and something is off on hover */}
                <ArrowUpRight
                  size={24}
                  weight="light"
                  className="ml-[10px] inline max-h-[24px] max-w-[24px]"
                />
              </Typography>
            </LinkExternal>
          </li>
        </ul>
      </nav>
    </div>
  );
};
