import { useMemo } from 'react';

import { Check } from '@phosphor-icons/react';
import { Typography } from '@remarkable/ark-web';

import { Subscription } from 'src/api/endpoints/storeApi.types';
import { useStripeSubscription } from 'src/api/queries';
import { Divider, Label } from 'src/components';
import { StatusTag } from 'src/components/StatusTag';
import { formatDate } from 'src/utils/formatDate';
import { subscriptionHelpers } from 'src/utils/subscriptionHelpers';
import { valueProps } from 'src/utils/valueProps';

import { CardHeading } from './CardHeading';

const getActivationDate = (sub?: Subscription) => {
  switch (sub?.type) {
    case 'stripe':
      return formatDate(sub.createdAt);
    case 'free-connect':
      return null; // TODO: Update this when backend ships the data
    default:
      return null;
  }
};

export const ConnectSeatsDetails = () => {
  const subscription = useStripeSubscription();
  const activationDate = getActivationDate(subscription.data);

  const endDate = useMemo(() => {
    if (!subscription.data) return null;

    if (
      subscriptionHelpers.isDeactivated(subscription.data) ||
      subscriptionHelpers.isCancelled(subscription.data)
    ) {
      return formatDate(subscription.data.paymentInformation.cancellationAt);
    }

    return null;
  }, [subscription.data]);

  return (
    <div>
      <div
        data-cy="connect-for-business-card"
        className="flex flex-row flex-nowrap items-start justify-between gap-8"
      >
        <div>
          <CardHeading>Connect</CardHeading>
          <Label
            className="inline-block"
            data-cy="subscription-card-activated-label"
          >
            {endDate ? (
              <>
                All members will loose access to Connect by the end of the
                billing cycle ({endDate})
              </>
            ) : (
              <>Activated {activationDate}</>
            )}
          </Label>

          <div className="mt-16 flex items-center gap-8">
            <div className="rounded-full bg-green-light/50 p-4">
              <Check
                className="text-feedback-green-500"
                size={16}
                weight="bold"
              />
            </div>

            <Typography variant="interface-sm-caps-medium">
              {subscription.data?.access.canSeeMembers
                ? 'Manage multiple users'
                : "You're part of a group subscription"}
            </Typography>
          </div>
        </div>

        <StatusTag size="fluid" subscription={subscription.data} />
      </div>

      <Divider className="my-24" />

      <div
        data-cy="connect-offer-perks"
        className="flex w-full flex-col gap-16"
      >
        <Typography variant="body-lg-regular">
          Enjoy seamless note-taking with unlimited cloud storage and sync, and
          up to three years added device protection.
        </Typography>

        <ul className="grid gap-8 lm:grid-cols-2">
          {Object.values(valueProps.connectWithSeats).map((valueProp) => (
            <li key={valueProp.id} className="flex items-center gap-8">
              <div className="flex items-center gap-8">
                <valueProp.icon className="text-pen-blue" size={20} />
                <Typography variant="body-md-regular">
                  {valueProp.title}
                </Typography>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
