import { DotsThreeVertical, Plus } from '@phosphor-icons/react';
import { Tag, Typography } from '@remarkable/ark-web';
import { useSuspenseQuery } from '@tanstack/react-query';

import { EnterpriseDomain } from 'src/api/endpoints/cloudEnterpriseAdminApi.types';
import { queryEntepriseOrgSettings } from 'src/api/queries/enterpriseAdmin';
import {
  Button,
  Divider,
  FlexDivider,
  Input,
  Sheet,
  Tooltip,
} from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { CopyButton } from 'src/components/CopyButton';

const getDomainStatus = (domain: EnterpriseDomain) => {
  if (domain.homeRealmDiscoveryEnabled) {
    return 'Domain forwarding enabled';
  }

  if (domain.migrationEnabled) {
    return 'User migration enabled';
  }

  return 'Domain migration not enabled';
};

export function EnterpriseSettingsPage() {
  const orgInfo = useSuspenseQuery(queryEntepriseOrgSettings());

  const loginUrl = `https://my.remarkable.com/login?connection=${
    orgInfo.data?.name ?? ''
  }`;

  return (
    <AppPage.Content className="max-w-l2xl">
      <div className="flex w-full flex-col gap-24 lxl:flex-row lxl:items-start">
        <Sheet className="w-full">
          <div className="flex flex-wrap items-center justify-between gap-16">
            <Typography variant="heading-md">
              {orgInfo.data?.displayName}
            </Typography>
            <Typography
              variant="responsive-body-lg"
              className="my-0 font-regular text-neutral-dark-1 opacity-90"
            >
              Organization
            </Typography>
          </div>

          <Divider />

          <div className="flex flex-col items-end gap-24 ls:flex-row">
            <Input
              name="loginUrl"
              label="Login URL"
              disabled
              value={loginUrl}
              inputClassName="cursor-text bg-neutral-light-2"
            />
            <CopyButton value={loginUrl} className="w-full ls:w-fit" />
          </div>

          <div className="flex flex-col gap-16 pt-24">
            <Typography variant="heading-xs">
              Single sign-on settings
            </Typography>
            <Divider />
            <div className="flex flex-col gap-16">
              <div className="flex flex-col gap-8">
                <Typography variant="interface-sm-regular">
                  Organization ID: <b>{orgInfo.data?.id}</b>
                </Typography>
                <Typography variant="interface-sm-regular">
                  SSO connection ID: <b>{orgInfo.data?.name}</b>
                </Typography>
              </div>
            </div>
          </div>
        </Sheet>

        <div className="flex flex-col gap-16 bg-neutral-light-3 px-24 py-32">
          <div className="flex items-center justify-between gap-16">
            <Typography variant="heading-sm">Domains</Typography>

            <Tooltip title="Add domain" asChild>
              <Button variant="tertiary-neutral" size="medium">
                <Plus />
              </Button>
            </Tooltip>
          </div>
          <Divider />

          <ul>
            {orgInfo.data?.domains?.map((domain) => (
              <li key={domain.address}>
                <div className="flex items-center justify-between gap-16">
                  <div className="flex flex-col gap-8 lm:flex-row lm:items-center">
                    <Typography
                      variant="heading-xs"
                      className="whitespace-nowrap"
                    >
                      {domain.address}
                    </Typography>
                    {domain.isPrimary && (
                      <>
                        <div className="hidden h-16 lm:flex">
                          <FlexDivider />
                        </div>
                        <Typography variant="interface-sm-caps">
                          Primary
                        </Typography>
                      </>
                    )}
                  </div>
                  <Button
                    variant="tertiary-neutral"
                    className="!rounded"
                    size="medium"
                  >
                    <DotsThreeVertical />
                  </Button>
                </div>
                <div>
                  <Typography
                    variant="interface-sm-regular"
                    className="text-neutral-dark-1"
                  >
                    Domain verification:{' '}
                    {domain.verificationStatus === 'HOST_UNREACHABLE' ||
                    domain.verificationStatus === 'INVALID' ? (
                      <Tag variant="themed-filled" className="bg-warning-light">
                        {domain.verificationStatus}
                      </Tag>
                    ) : (
                      <b>{domain.verificationStatus}</b>
                    )}
                  </Typography>
                  <Typography
                    variant="interface-sm-regular"
                    className="text-neutral-dark-1"
                  >
                    Status: <b>{getDomainStatus(domain)}</b>
                  </Typography>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </AppPage.Content>
  );
}
