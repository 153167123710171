import { Divider, Typography } from '@remarkable/ark-web';

import { Sheet } from 'src/components';

import { UserPropertiesForm } from './components/UserPropertiesForm';

export const TestDataApp = () => {
  return (
    <>
      <Sheet className="container bg-paper-3 ">
        <Typography variant="heading-xl">Test data generator</Typography>
        <Typography variant="body-2xl-regular" textWrap="pretty">
          Easily create fake my.remarkable users
        </Typography>
        <Divider />

        <UserPropertiesForm />
      </Sheet>
    </>
  );
};
