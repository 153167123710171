import { ElementRef, forwardRef, HTMLProps } from 'react';

import { CaretDown } from 'phosphor-react';

import { COUNTRIES, CountryData } from 'src/utils/countryList';
import { useFlagImage } from 'src/utils/useFlagImage';

import { cn } from '../utils/classNamesHelper';

interface CountryPickerProps {
  countries?: Array<CountryData>;
  hasError?: boolean;
  value?: string;
}

export const CountryPicker2 = forwardRef<
  ElementRef<'select'>,
  CountryPickerProps & HTMLProps<HTMLSelectElement>
>(function RenderCountryPicker2(
  {
    className = '',
    countries = COUNTRIES,
    disabled = false,
    hasError = false,
    value = '',
    ...rest
  },
  ref
) {
  const displayValue = countries.find((c) => c.value === value)?.label;
  const flagImage = useFlagImage(value);

  return (
    <div
      className={cn('relative flex items-center rounded bg-white', className)}
    >
      {value ? (
        <img
          className="pointer-events-none absolute ml-12 h-32 w-32 rounded-full bg-neutral-light-4"
          src={flagImage.data}
          alt={`${displayValue ?? 'Country'} flag`}
        />
      ) : (
        <div className="absolute ml-12 h-32 w-32 rounded-full bg-neutral-light-4" />
      )}
      <select
        data-cy="country-picker"
        className={cn(
          'h-48 w-full cursor-pointer appearance-none rounded border bg-none p-12 pl-56',
          {
            'text-gray-500': !value,
            'cursor-wait': disabled,
            'border-feedback-red-500': hasError,
            'border-neutral-light-8': !hasError,
          }
        )}
        disabled={disabled}
        value={value}
        ref={ref}
        {...rest}
      >
        <option value="" disabled hidden>
          Select country
        </option>
        {countries
          .sort((a, b) => a.label.localeCompare(b.label))
          .map((option) => (
            <option
              key={option.value}
              value={option.value}
              aria-selected={value === option.value}
            >
              {option.label}
            </option>
          ))}
      </select>
      <CaretDown
        size={32}
        className="pointer-events-none absolute right-12 top-0 h-full text-pen-blue"
      />
    </div>
  );
});
