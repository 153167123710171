import { cloudProfileApiClient as profileApi } from '../clients/cloudProfileApiClient';

export const getProfileMetadata = () =>
  profileApi.GET('/profile/v1').then((r) => r.data ?? null);

export const linkProfiles = (vars: {
  /** Legacy account access token */
  primaryToken: string;
  /** SAML account access token */
  secondaryToken: string;
}) =>
  profileApi
    .POST('/profile/v1/link', {
      headers: {
        Authorization: `Bearer ${vars.primaryToken}`,
      },
      body: {
        token: vars.secondaryToken,
      },
    })
    .then((r) => r.data ?? null);
