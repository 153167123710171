import { createFileRoute, Outlet } from '@tanstack/react-router';

import { AppPage } from 'src/components/AppPage';

export const Route = createFileRoute('/_auth/_layout/store/methods-offer')({
  component: () => {
    return (
      <AppPage.Container>
        <Outlet />
      </AppPage.Container>
    );
  },
});
