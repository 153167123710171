import { paths } from 'apis/test-data-service-api-public';
import createClient from 'openapi-fetch';

import { throwOnErrorResponse } from './middleware/throwOnErrorResponse';

const BASE_URL = import.meta.env.PUBLIC_TEST_DATA_API_URL ?? '';
const API_KEY = import.meta.env.PUBLIC_TEST_DATA_API_KEY ?? '';

const testDataApiClient = createClient<paths>({
  // todo replace with vercel env var
  baseUrl: BASE_URL,

  headers: {
    'x-api-key': API_KEY,
  },
});

testDataApiClient.use(throwOnErrorResponse);

export { testDataApiClient };
