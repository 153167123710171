import { FleetDevice } from 'src/api/endpoints/cloudEnterprise.types';
import { getDeviceName } from 'src/apps/fleet/app/utils/getDeviceName';

/**
 * Sort by length, then by device name
 */
export function sortByDeviceLists(a?: FleetDevice[], b?: FleetDevice[]) {
  const aDeviceNames = a?.map((d) => getDeviceName(d), '') ?? [];
  const bDeviceNames = b?.map((d) => getDeviceName(d), '') ?? [];

  if (aDeviceNames.length === 0 && bDeviceNames.length === 0) {
    return aDeviceNames.join('').localeCompare(bDeviceNames.join(''));
  }

  return aDeviceNames.length - bDeviceNames.length;
}
