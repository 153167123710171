import { useMemo } from 'react';

import { FeedbackTargetValues } from 'src/api/endpoints/storeApi.types';
import { useSubscriptionSuspense } from 'src/api/queries';
import { Feedback } from 'src/components';
import { ConnectMultipleSeatsCard } from 'src/components/ConnectMultipleSeatsCard';
import { subscriptionHelpers } from 'src/utils/subscriptionHelpers';

import { SubscriptionCard } from '../components/SubscriptionCard';
import { SubscriptionFAQ } from '../components/SubscriptionFAQ';

export const SubscriptionPage = () => {
  const subscription = useSubscriptionSuspense();

  const hasNoStripeSubscription = !subscription.data;
  const isActiveAndManagementTypeSimple =
    subscriptionHelpers.isManagementTypeSimple(subscription?.data);
  const isFreeConnect = subscriptionHelpers.isFreeConnect(subscription?.data);

  const feedbackTargetId = useMemo((): FeedbackTargetValues | null => {
    if (!subscription.data) return null;

    if (isActiveAndManagementTypeSimple) {
      return 'connect-plan-page-v1';
    }

    // TODO: Check the member seat instead for the actual role name
    if (subscription.data.access.canSeeMembers) {
      return 'c4b-plan-page-for-owner-v1';
    }

    return 'c4b-plan-page-for-member-v1';
  }, [subscription.data]);

  const showMultipleSeatsCard =
    (hasNoStripeSubscription && !isFreeConnect) ||
    isActiveAndManagementTypeSimple;

  return (
    <div className="flex flex-col gap-24 lm:gap-32" data-cy="subscription-page">
      <SubscriptionCard />
      {showMultipleSeatsCard && <ConnectMultipleSeatsCard className="mt-24" />}
      <SubscriptionFAQ />
      {feedbackTargetId && (
        <Feedback
          feedbackTargetId={feedbackTargetId}
          title="Did you find what you were looking for?"
        />
      )}
    </div>
  );
};
