import { useQuery, useQueryClient } from '@tanstack/react-query';

import { getCountryDataForCountryCodeWithFallback } from 'src/utils/countryDetector';
import { CountryData } from 'src/utils/countryList';

import { myRmApi } from '../endpoints';
import { queryKeys } from './queryKeys';

export const useCountry = () => {
  const setCountry = useSetCountry();

  return {
    ...useQuery({
      queryKey: queryKeys.country.detected,
      queryFn: async () => {
        const countryCode = await myRmApi.getCountry();

        return getCountryDataForCountryCodeWithFallback(countryCode);
      },
      staleTime: Infinity,
    }),
    setCountry,
  };
};

export const useSetCountry = () => {
  const queryClient = useQueryClient();

  return (country: CountryData) =>
    queryClient.setQueryData(queryKeys.country.detected, country);
};
