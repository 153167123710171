import { RMpp } from '@remarkable/ark-icons';
import { Tag, Typography } from '@remarkable/ark-web';
import { ArrowRight, CloudArrowUp, Folder } from 'phosphor-react';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { Subscription } from 'src/api/endpoints/storeApi.types';
import { URLS } from 'src/utils/urls/urls';

import { FlexDivider } from './FlexDivider';
import { Link2 } from './Link2';
import { Sheet } from './Sheet';
import { StatusTag } from './StatusTag';

export const ActiveSubscriptionHero = ({
  activePaperTablets,
  subscription,
}: {
  activePaperTablets: number;
  subscription: Subscription;
}) => {
  return (
    <Sheet
      className="flex flex-col justify-between gap-0 !px-0 !py-32 text-left lm:flex-row"
      data-cy="hero-dashboard"
    >
      <div className="flex  w-full flex-1 flex-col justify-between gap-16 px-24">
        <Typography variant="heading-subtle-xl">
          <span className="flex items-center gap-8">
            <RMpp className="text-muted" size={24} />
            Paper tablet
          </span>
        </Typography>
        <Tag>{activePaperTablets} / 3 Devices paired</Tag>
        <Typography variant="body-sm-regular" className="text-muted">
          Add or edit devices connected to your account.
        </Typography>
        <Link2
          to={URLS.DEVICE_REMARKABLE}
          componentLocation={ComponentLocations.FRONT_PAGE.DASHBOARD}
          className="font-medium text-pen-blue"
        >
          Manage devices
          <ArrowRight />
        </Link2>
      </div>

      <FlexDivider className="mx-32 my-16 lm:m-0" />

      <div className="flex  w-full flex-1 flex-col justify-between gap-16 px-24">
        <Typography variant="heading-subtle-xl">
          <span className="flex items-center gap-8">
            <CloudArrowUp size={28} className="inline-block text-muted" />{' '}
            Connect
          </span>
        </Typography>
        <StatusTag subscription={subscription} />
        <Typography variant="body-sm-regular" className="text-muted">
          View your subscription details, payment and invoices.
        </Typography>
        <Link2
          to={URLS.SUBSCRIPTION}
          componentLocation={ComponentLocations.FRONT_PAGE.DASHBOARD}
          className="font-medium text-pen-blue"
        >
          Manage subscription
          <ArrowRight />
        </Link2>
      </div>

      <FlexDivider className="mx-32 my-16 lm:m-0" />

      <div className="flex w-full flex-1 flex-col gap-16 px-24">
        <Typography variant="heading-subtle-xl">
          <span className="flex items-center gap-8">
            <Folder size={28} className="inline-block text-muted" /> My files
          </span>
        </Typography>
        <Typography variant="body-sm-regular" className="text-muted">
          View, manage and upload files to your paper tablet.
        </Typography>
        <Link2
          to={URLS.MYFILES}
          componentLocation={ComponentLocations.FRONT_PAGE.DASHBOARD}
          className="mt-auto font-medium text-pen-blue"
        >
          Go to my files
          <ArrowRight />
        </Link2>
      </div>
    </Sheet>
  );
};
