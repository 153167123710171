import { useMemo } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { CheckboxField, Typography } from '@remarkable/ark-web';
import { useSearch } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useEnterpriseEnrollmentStatus } from 'src/api/queries/enterpriseEnrollment';
import {
  useAccountLinkingListener,
  useAuth0Claims,
  useLoginToLinkableAccount,
} from 'src/api/queries/user';
import {
  Button,
  Divider,
  Form,
  NotificationBox,
  Sheet,
  SomethingWentWrong,
  Spinner,
} from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { LinkExternal } from 'src/components/LinkExternal';
import { TestId } from 'src/testids';
import { URLS } from 'src/utils/urls/urls';

import { CancelSetupModal } from '../components/CancelSetup';
import { EnterpriseSteps } from '../components/EnterpriseSteps';
import { useHasLinkedAccounts } from '../utils/useHasLinkedAccounts';

interface FormState {
  hasVerifiedEmailInIdP: boolean;
}

export function VerifySamlPage() {
  const accountLinkingListener = useAccountLinkingListener({
    returnUrl: URLS.ENTERPRISE_CONFIRM_SETUP,
  });
  const loginToLinkableAccount = useLoginToLinkableAccount();
  const enrollmentStatus = useEnterpriseEnrollmentStatus();
  const linkedStatus = useHasLinkedAccounts();
  const form = useForm<FormState>();
  const { user } = useAuth0();
  const claims = useAuth0Claims();
  const search = useSearch({
    from: '/_auth/_layout/enterprise/enroll/verify-saml',
  });

  const idpDomain = useMemo(() => {
    try {
      const idpUrl = new URL(enrollmentStatus.data?.signInUrl ?? '');
      return idpUrl;
    } catch (error) {
      return;
    }
  }, [enrollmentStatus.data?.signInUrl]);

  const isLoggedInToSSO = !!claims.data?.org_id;

  if (accountLinkingListener.isPending || linkedStatus.isPending) {
    return <Spinner />;
  }

  if (linkedStatus.isError) {
    return <SomethingWentWrong />;
  }

  return (
    <AppPage.Container>
      <AppPage.Header title="Single sign-on setup" />
      <AppPage.Content
        className="max-w-ll"
        data-cy={TestId.PageEnterpriseEnrollmentVerifySAML}
      >
        <EnterpriseSteps />

        <Sheet className="gap-24">
          <div className="flex items-center justify-between gap-16">
            <Typography as="h2" variant="heading-sm">
              Verify the SAML settings
            </Typography>
          </div>

          <Form
            {...form}
            className="flex flex-col gap-24"
            onSubmit={() => {
              if (typeof enrollmentStatus.data?.connectionId !== 'string')
                return;

              if (!linkedStatus.isSuccess) return;

              loginToLinkableAccount.mutate({
                connection: enrollmentStatus.data.connectionId,
                startLinking: !linkedStatus.data.isLinked,
              });
            }}
          >
            {isLoggedInToSSO ? (
              <>
                <Typography variant="body-md-regular">
                  Your SAML setup has been verified.
                </Typography>

                <Divider />
              </>
            ) : (
              <div className="flex flex-col gap-24">
                <Typography variant="body-md-regular">
                  To confirm that everything is working correctly, add your
                  email address to your identity provider and verify by logging
                  in with SSO. If everything&apos;s set up as it should,
                  you&apos;ll be able to log right in.
                </Typography>

                <Divider />

                <CheckboxField
                  required
                  {...form.register('hasVerifiedEmailInIdP', {
                    required: true,
                  })}
                  data-cy={TestId.CheckboxEnterpriseVerifySAML}
                  label={
                    (
                      <>
                        I&apos;ve added my email address (
                        <b>{user?.email ?? 'email not found'}</b>) to my
                        identity provider.
                      </>
                    ) as unknown as string // The component supports ReactNode, but the type does not
                  }
                />

                <div className="w-full bg-neutral-light-3 px-12 py-8">
                  <Typography variant="body-sm-regular">
                    By verifying, you agree that:
                  </Typography>

                  <ul className="list-disc pl-24">
                    <li>
                      <Typography variant="body-sm-regular">
                        Control of your reMarkable account and content will be
                        transferred to your employer.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body-sm-regular">
                        The Terms and Conditions for reMarkable Accounts
                        you&apos;ve accepted, including any active
                        subscriptions, will end.
                      </Typography>
                    </li>
                  </ul>
                </div>
              </div>
            )}

            {search.accountLinkingError && (
              <NotificationBox
                variant="warning"
                title="Verify SSO session before continuing"
              >
                <Typography variant="body-md-regular">
                  The previous verification attempt failed because of
                  mismatching accounts. If you already have an active session
                  with a different account, please make sure you are logged out
                  of that before trying to verify again.{' '}
                </Typography>

                {idpDomain && (
                  <>
                    <Typography variant="interface-md-regular">
                      Your IdP domain is{' '}
                      <LinkExternal
                        inline
                        componentLocation={
                          ComponentLocations.ENTERPRISE.ENROLL_VERIFY_SAML
                        }
                        to={idpDomain.origin}
                      >
                        {idpDomain.hostname}
                      </LinkExternal>
                    </Typography>
                  </>
                )}
              </NotificationBox>
            )}

            <div className="flex w-full flex-col-reverse flex-wrap-reverse gap-24 lm:flex-row">
              <div className="flex flex-1 ls:justify-center lm:justify-start">
                <CancelSetupModal
                  trigger={
                    <Button
                      variant="tertiary-neutral"
                      className="w-full ls:w-fit"
                    >
                      Cancel
                    </Button>
                  }
                />
              </div>

              <div className="flex flex-col-reverse flex-wrap-reverse gap-24 ls:flex-row">
                <Button
                  as="a"
                  variant="secondary"
                  to={URLS.ENTERPRISE_SETUP_SAML}
                  className="w-full ls:w-fit ls:flex-1 lm:flex-initial"
                >
                  Previous
                </Button>
                {isLoggedInToSSO ? (
                  <Button
                    as="a"
                    to={URLS.ENTERPRISE_CONFIRM_SETUP}
                    variant="primary"
                    className="w-full ls:w-fit ls:flex-1 lm:flex-initial"
                  >
                    Continue
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    type="submit"
                    className="w-full ls:w-fit ls:flex-1 lm:flex-initial"
                    disabled={
                      loginToLinkableAccount.isPending || linkedStatus.isPending
                    }
                    loading={
                      loginToLinkableAccount.isPending || linkedStatus.isPending
                    }
                  >
                    {linkedStatus.data.isLinked ? 'Login to SSO' : 'Verify'}
                  </Button>
                )}
              </div>
            </div>
          </Form>

          <NotificationBox error={loginToLinkableAccount.error} />
        </Sheet>
      </AppPage.Content>
    </AppPage.Container>
  );
}
