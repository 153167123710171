import { createFileRoute, Navigate, useSearch } from '@tanstack/react-router';

// This Route is primarily hit by the Microsoft OneDrive auth flow
export const Route = createFileRoute('/_auth/_layout/integrations/callback')({
  component: () => {
    const search = useSearch({ strict: false });

    return (
      <Navigate to="/integrations" search={{ callback: true, ...search }} />
    );
  },
});
