import { useEffect } from 'react';

import { Typography } from '@remarkable/ark-web';
import { ArrowClockwise } from 'phosphor-react';
import { ZodError } from 'zod';

import { ComponentRendered } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { IS_DEVELOPMENT } from 'src/config';
import { TestId } from 'src/testids';
import {
  AccessDeniedError,
  AccountLinkingMismatchError,
} from 'src/utils/errors';

import { Button } from './Button';
import { ErrorAccountLinkingMismatch } from './ErrorAccountLinkingMismatch';
import { Link2 } from './Link2';
import { NotificationBox } from './NotificationBox';
import { SentryFeedbackReportButton } from './SentryFeedbackReportButton';

interface Props {
  error?: Error | null;
  sentryEventId?: string;
}
export const SomethingWentWrong = ({ error, sentryEventId }: Props) => {
  useEffect(() => {
    tracker.trackEvent(
      new ComponentRendered({
        component_name: 'SomethingWentWrong',
        component_type: 'error',
      })
    );
  }, []);

  if (error instanceof AccessDeniedError) {
    return (
      <div data-cy="error-page" className="w-full bg-neutral-light-2">
        <div
          data-cy={TestId.PageAccessDenied}
          className="m-auto flex w-full max-w-screen-md flex-col items-center gap-24 px-24 py-64 md:px-64 lg:py-128"
        >
          <Typography variant="heading-lg" className="text-center">
            Access Denied
          </Typography>
          <p className="max-w-[560px] text-center">
            {error.message ?? (
              <>
                You do not have permission to access this page. Please contact
                your administrator if you believe this is an error.
              </>
            )}
          </p>

          <div className="flex gap-16">
            <Link2
              to="/logout"
              as="button"
              variant="secondary"
              className="ark-button ark-button--secondary"
            >
              Logout
            </Link2>

            <Link2
              to="/"
              as="button"
              variant="primary"
              className="ark-button ark-button--primary"
            >
              Go to home
            </Link2>
          </div>
        </div>
      </div>
    );
  }

  if (error instanceof AccountLinkingMismatchError) {
    return <ErrorAccountLinkingMismatch error={error} />;
  }

  return (
    <div data-cy="error-page" className="w-full bg-neutral-light-2">
      <div className="m-auto flex w-full max-w-screen-md flex-col items-center gap-24 px-24 py-64 md:px-64 lg:py-128">
        <Typography variant="heading-lg" className="text-center">
          Sorry, something went wrong
        </Typography>
        <p className="max-w-[560px] text-center">
          This may be an issue with cookies, too many open login or a system
          outage. We&apos;re alerted about this type of issue automatically.
          Please try again later.
        </p>
        {IS_DEVELOPMENT && error ? (
          <NotificationBox
            variant="error"
            title={error.name}
            className="max-w-full"
          >
            <p>{error.message}</p>
            <pre className="w-full min-w-0 overflow-scroll text-12 text-muted">
              {error instanceof ZodError ? error.format()._errors : error.stack}
            </pre>
          </NotificationBox>
        ) : null}
        <Button
          className="mt-32"
          onClick={() => {
            window.location.reload();
          }}
        >
          <ArrowClockwise />
          <span>Refresh</span>
        </Button>
        <SentryFeedbackReportButton eventId={sentryEventId} />
      </div>
    </div>
  );
};
