import { ComponentLocations } from 'src/analytics/trackingTypes';
import { CheckoutType } from 'src/api/endpoints/storeApi.types';
import {
  useActivePaperTablets,
  useCheckoutAllowed,
  useSubscription,
} from 'src/api/queries';
import { SomethingWentWrong, Spinner } from 'src/components';
import { ActiveSubscriptionHero } from 'src/components/ActiveSubscriptionHero';
import { HeroOffer } from 'src/components/Offers/Offer';
import { WelcomeAndPairDeviceHero } from 'src/components/WelcomeAndPairDeviceHero';
import { subscriptionHelpers } from 'src/utils/subscriptionHelpers';

export function Hero() {
  const {
    data: subscriptionsData,
    isPending: isSubscriptionsPending,
    isError: isSubscriptionsError,
  } = useSubscription();
  const {
    data: activePaperTabletsData,
    isPending: isDevicesPending,
    isError: isDevicesError,
  } = useActivePaperTablets();
  const {
    data: checkoutAllowedData,
    isPending: isCheckoutPending,
    isError: isCheckoutError,
  } = useCheckoutAllowed();

  const getActiveOffer = (): {
    checkoutType: CheckoutType;
    expirationDate: string | null;
  } => {
    if (checkoutAllowedData?.connectOffer?.allowed) {
      return {
        checkoutType: 'connectOffer',
        expirationDate: checkoutAllowedData.connectOffer.expiresAt,
      };
    }
    if (checkoutAllowedData?.dmOffer?.allowed) {
      return {
        checkoutType: 'dmOffer',
        expirationDate: checkoutAllowedData.dmOffer.expiresAt,
      };
    }
    if (checkoutAllowedData?.winbackOffer?.allowed) {
      return {
        checkoutType: 'winbackOffer',
        expirationDate: checkoutAllowedData.winbackOffer.expiresAt,
      };
    } else
      return {
        checkoutType: 'store',
        expirationDate: null,
      };
  };

  const isLoading =
    isSubscriptionsPending || isDevicesPending || isCheckoutPending;
  const isError = isSubscriptionsError || isDevicesError || isCheckoutError;

  const hasActiveSubscription = subscriptionHelpers.isActive(subscriptionsData);
  const hasCancelledSubscription =
    subscriptionHelpers.isCancelled(subscriptionsData); //
  const hasOnHoldSubscription =
    subscriptionHelpers.isDeactivated(subscriptionsData);

  const offerType = getActiveOffer();
  const dataCy = 'hero-container';

  if (isLoading) return <Spinner />;
  if (isError) return <SomethingWentWrong />;

  if (!activePaperTabletsData.length) {
    return (
      <div data-cy={dataCy}>
        <WelcomeAndPairDeviceHero />
      </div>
    );
  }

  if (
    hasActiveSubscription ||
    hasOnHoldSubscription ||
    hasCancelledSubscription
  ) {
    return (
      <div data-cy={dataCy}>
        <ActiveSubscriptionHero
          activePaperTablets={activePaperTabletsData.length}
          subscription={subscriptionsData}
        />
      </div>
    );
  }

  return (
    <div data-cy={dataCy}>
      <HeroOffer
        checkoutType={offerType.checkoutType}
        componentLocation={ComponentLocations.FRONT_PAGE.HERO}
        expirationDate={offerType.expirationDate}
      />
    </div>
  );
}
