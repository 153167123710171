import { CountryCode } from 'src/api/endpoints/myRmApi.types';

export interface CountryData {
  flag: string;
  label: string;
  value: CountryCode;
  lang: string;
  continent: string;
  currency: string;
  countryCode: string;
  unions?: string[];
  taxIds?: {
    [key: string]: {
      id: string;
      description: string;
      example: string;
    };
  };
}

// Tax ID information were added 15. March 2024 from the following sources:
// OECD: https://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/tax-identification-numbers/
// Stripe: https://docs.stripe.com/billing/customer/tax-ids

export const COUNTRIES: CountryData[] = [
  {
    flag: 'australia',
    label: 'Australia',
    value: 'AU',
    lang: 'en',
    continent: 'Oceania',
    currency: 'AUD',
    countryCode: '61',
    taxIds: {
      au_abn: {
        id: 'au_abn',
        description: 'Australian Business Number (AU ABN)',
        example: '123456789123',
      },
      au_arn: {
        id: 'au_arn',
        description: 'Australian Taxation Office Reference Number',
        example: '123456789123',
      },
    },
  },
  {
    flag: 'austria',
    label: 'Austria',
    value: 'AT',
    lang: 'de',
    continent: 'Europe',
    currency: 'EUR',
    countryCode: '43',
    unions: ['EU'],
    taxIds: {
      eu_vat: {
        id: 'eu_vat',
        description: 'European VAT number',
        example: 'ATU12345678',
      },
    },
  },
  {
    flag: 'belgium',
    label: 'Belgium',
    value: 'BE',
    lang: 'de',
    continent: 'Europe',
    currency: 'EUR',
    countryCode: '32',
    unions: ['EU'],
    taxIds: {
      eu_vat: {
        id: 'eu_vat',
        description: 'European VAT number',
        example: 'BE0123456789',
      },
    },
  },
  {
    flag: 'bulgaria',
    label: 'Bulgaria',
    value: 'BG',
    lang: 'bg',
    continent: 'Europe',
    currency: 'EUR',
    countryCode: '359',
    unions: ['EU'],
    taxIds: {
      bg_uic: {
        id: 'bg_uic',
        description: 'Bulgaria Unified Identification Code',
        example: '123456789',
      },
      eu_vat: {
        id: 'eu_vat',
        description: 'European VAT number',
        example: 'BG0123456789',
      },
    },
  },
  {
    flag: 'canada',
    label: 'Canada',
    value: 'CA',
    lang: 'en',
    continent: 'North America',
    currency: 'CAD',
    countryCode: '1',
    taxIds: {
      ca_bn: {
        id: 'ca_bn',
        description: 'Canadian Business Number (CA BN)',
        example: '123456789',
      },
      ca_gst_hst: {
        id: 'ca_gst_hst',
        description: 'Canadian GST/HST number',
        example: '123456789RT0002',
      },
      ca_pst_bc: {
        id: 'ca_pst_bc',
        description: 'Canadian PST number (British Columbia)',
        example: 'PST-1234-5678',
      },
      ca_pst_mb: {
        id: 'ca_pst_mb',
        description: 'Canadian PST number (Manitoba)',
        example: '123456-7',
      },
      ca_pst_sk: {
        id: 'ca_pst_sk',
        description: 'Canadian PST number (Saskatchewan)',
        example: '1234567',
      },
      ca_qst: {
        id: 'ca_qst',
        description: 'Canadian QST number (Québec)',
        example: '1234567890TQ1234',
      },
    },
  },
  {
    flag: 'spain',
    label: 'Canary Islands',
    value: 'IC',
    lang: 'es',
    continent: 'Europe',
    currency: 'EUR',
    countryCode: '34',
    /** Assuming the same tax IDs as Spain */
    taxIds: {
      es_cif: {
        id: 'es_cif',
        description: 'Spanish NIF number (previously Spanish CIF number)',
        example: 'A12345678',
      },
      eu_vat: {
        id: 'eu_vat',
        description: 'European VAT number',
        example: 'ESX1234567X',
      },
    },
  },
  {
    flag: 'croatia',
    label: 'Croatia',
    value: 'HR',
    lang: 'hr',
    continent: 'Europe',
    currency: 'EUR',
    countryCode: '385',
    unions: ['EU'],
    taxIds: {
      eu_vat: {
        id: 'eu_vat',
        description: 'European VAT number',
        example: 'HR12345678901',
      },
    },
  },
  {
    flag: 'cyprus',
    label: 'Cyprus',
    value: 'CY',
    lang: 'el',
    continent: 'Europe',
    currency: 'EUR',
    countryCode: '357',
    unions: ['EU'],
    taxIds: {
      eu_vat: {
        id: 'eu_vat',
        description: 'European VAT number',
        example: 'CY12345678Z',
      },
    },
  },
  {
    flag: 'czech-republic',
    label: 'Czech Republic',
    value: 'CZ',
    lang: 'cs',
    continent: 'Europe',
    currency: 'EUR',
    countryCode: '420',
    unions: ['EU'],
    taxIds: {
      eu_vat: {
        id: 'eu_vat',
        description: 'European VAT number',
        example: 'CZ1234567890',
      },
    },
  },
  {
    flag: 'denmark',
    label: 'Denmark',
    value: 'DK',
    lang: 'da',
    continent: 'Europe',
    currency: 'DKK',
    countryCode: '45',
    unions: ['EU'],
    taxIds: {
      eu_vat: {
        id: 'eu_vat',
        description: 'European VAT number',
        example: 'DK12345678',
      },
    },
  },
  {
    flag: 'estonia',
    label: 'Estonia',
    value: 'EE',
    lang: 'et',
    continent: 'Europe',
    currency: 'EUR',
    countryCode: '372',
    unions: ['EU'],
    taxIds: {
      eu_vat: {
        id: 'eu_vat',
        description: 'European VAT number',
        example: 'EE123456789',
      },
    },
  },
  {
    flag: 'finland',
    label: 'Finland',
    value: 'FI',
    lang: 'fi',
    continent: 'Europe',
    currency: 'EUR',
    countryCode: '358',
    unions: ['EU'],
    taxIds: {
      eu_vat: {
        id: 'eu_vat',
        description: 'European VAT number',
        example: 'FI12345678',
      },
    },
  },
  {
    flag: 'france',
    label: 'France',
    value: 'FR',
    lang: 'fr',
    continent: 'Europe',
    currency: 'EUR',
    countryCode: '33',
    unions: ['EU'],
    taxIds: {
      eu_vat: {
        id: 'eu_vat',
        description: 'European VAT number',
        example: 'FRAB123456789',
      },
    },
  },
  {
    flag: 'germany',
    label: 'Germany',
    value: 'DE',
    lang: 'de',
    continent: 'Europe',
    currency: 'EUR',
    countryCode: '49',
    unions: ['EU'],
    taxIds: {
      eu_vat: {
        id: 'eu_vat',
        description: 'European VAT number',
        example: 'DE123456789',
      },
    },
  },
  {
    flag: 'greece',
    label: 'Greece',
    value: 'GR',
    lang: 'el',
    continent: 'Europe',
    currency: 'EUR',
    countryCode: '30',
    unions: ['EU'],
    taxIds: {
      eu_vat: {
        id: 'eu_vat',
        description: 'European VAT number',
        example: 'EL123456789',
      },
    },
  },
  {
    flag: 'hong-kong',
    label: 'Hong Kong',
    value: 'HK',
    lang: 'zh',
    continent: 'Asia',
    currency: 'HKD',
    countryCode: '852',
    taxIds: {
      hk_br: {
        id: 'hk_br',
        description: 'Hong Kong Business Registration Number',
        example: '12345678',
      },
    },
  },
  {
    flag: 'hungary',
    label: 'Hungary',
    value: 'HU',
    lang: 'hu',
    continent: 'Europe',
    currency: 'EUR',
    countryCode: '36',
    unions: ['EU'],
    taxIds: {
      eu_vat: {
        id: 'eu_vat',
        description: 'European VAT number',
        example: 'HU12345678',
      },
      hu_tin: {
        id: 'hu_tin',
        description: 'Hungary tax number (adószám)',
        example: '12345678-1-23',
      },
    },
  },
  {
    flag: 'iceland',
    label: 'Iceland',
    value: 'IS',
    lang: 'is',
    continent: 'Europe',
    currency: 'EUR',
    countryCode: '354',
    taxIds: {
      is_vat: {
        id: 'is_vat',
        description: 'Icelandic VAT number',
        example: '123456',
      },
    },
  },
  {
    flag: 'ireland',
    label: 'Ireland',
    value: 'IE',
    lang: 'ga',
    continent: 'Europe',
    currency: 'EUR',
    countryCode: '353',
    unions: ['EU'],
    taxIds: {
      eu_vat: {
        id: 'eu_vat',
        description: 'European VAT number',
        example: 'IE1234567AB',
      },
    },
  },
  {
    flag: 'israel',
    label: 'Israel',
    value: 'IL',
    lang: 'he',
    continent: 'Asia',
    currency: 'ILS',
    countryCode: '972',
    taxIds: {
      il_vat: {
        id: 'il_vat',
        description: 'Israel VAT',
        example: '000012345',
      },
    },
  },
  {
    flag: 'italy',
    label: 'Italy',
    value: 'IT',
    lang: 'it',
    continent: 'Europe',
    currency: 'EUR',
    countryCode: '39',
    unions: ['EU'],
    taxIds: {
      eu_vat: {
        id: 'eu_vat',
        description: 'European VAT number',
        example: 'IT12345678912',
      },
    },
  },
  {
    flag: 'japan',
    label: 'Japan',
    value: 'JP',
    lang: 'ja',
    continent: 'Asia',
    currency: 'JPY',
    countryCode: '81',
    taxIds: {
      jp_cn: {
        id: 'jp_cn',
        description: 'Japanese Corporate Number (Hōjin Bangō)',
        example: '1234567891234',
      },
      jp_rn: {
        id: 'jp_rn',
        description:
          "Japanese Registered Foreign Businesses' Registration Number (Tōroku Kokugai Jigyōsha no Tōroku Bangō)",
        example: '12345',
      },
      jp_trn: {
        id: 'jp_trn',
        description: 'Japanese Tax Registration Number (Tōroku Bangō)',
        example: 'T1234567891234',
      },
    },
  },
  {
    flag: 'jersey',
    label: 'Jersey',
    value: 'JE',
    lang: 'en',
    continent: 'Europe',
    currency: 'GBP',
    countryCode: '44',
    taxIds: {
      jersey_tin: {
        // source: https://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/tax-identification-numbers/Jersey-TIN.pdf
        id: 'jersey_tin',
        description: 'Jersey tax ID (TIN)',
        example: '100-100-1000',
      },
      jersey_tin_deprecated: {
        id: 'jersey_tin_deprecated',
        description: 'Jersey tax ID (TIN) (old format)',
        example: 'CC00000',
      },
    },
  },
  {
    flag: 'latvia',
    label: 'Latvia',
    value: 'LV',
    lang: 'lv',
    continent: 'Europe',
    currency: 'EUR',
    countryCode: '371',
    unions: ['EU'],
    taxIds: {
      eu_vat: {
        id: 'eu_vat',
        description: 'European VAT number',
        example: 'LV12345678912',
      },
    },
  },
  {
    flag: 'liechtenstein',
    label: 'Liechtenstein',
    value: 'LI',
    lang: 'de',
    continent: 'Europe',
    currency: 'EUR',
    countryCode: '423',
    taxIds: {
      li_uid: {
        id: 'li_uid',
        description: 'Liechtensteinian UID number',
        example: 'CHE123456789',
      },
    },
  },
  {
    flag: 'lithuania',
    label: 'Lithuania',
    value: 'LT',
    lang: 'lt',
    continent: 'Europe',
    currency: 'EUR',
    countryCode: '370',
    unions: ['EU'],
    taxIds: {
      eu_vat: {
        id: 'eu_vat',
        description: 'European VAT number',
        example: 'LT123456789123',
      },
    },
  },
  {
    flag: 'luxembourg',
    label: 'Luxembourg',
    value: 'LU',
    lang: 'fr',
    continent: 'Europe',
    currency: 'EUR',
    countryCode: '352',
    unions: ['EU'],
    taxIds: {
      eu_vat: {
        id: 'eu_vat',
        description: 'European VAT number',
        example: 'LU12345678',
      },
    },
  },
  {
    flag: 'malta',
    label: 'Malta',
    value: 'MT',
    lang: 'en',
    continent: 'Europe',
    currency: 'EUR',
    countryCode: '356',
    unions: ['EU'],
    taxIds: {
      eu_vat: {
        id: 'eu_vat',
        description: 'European VAT number',
        example: 'MT12345678',
      },
    },
  },
  {
    flag: 'netherlands',
    label: 'Netherlands',
    value: 'NL',
    lang: 'nl',
    continent: 'Europe',
    currency: 'EUR',
    countryCode: '31',
    unions: ['EU'],
    taxIds: {
      eu_vat: {
        id: 'eu_vat',
        description: 'European VAT number',
        example: 'NL123456789B12',
      },
    },
  },
  {
    flag: 'new-zealand',
    label: 'New Zealand',
    value: 'NZ',
    lang: 'en',
    continent: 'Oceania',
    currency: 'NZD',
    countryCode: '64',
    taxIds: {
      nz_gst: {
        id: 'nz_gst',
        description: 'New Zealand GST number',
        example: '123456789',
      },
    },
  },
  {
    flag: 'norway',
    label: 'Norway',
    value: 'NO',
    lang: 'no',
    continent: 'Europe',
    currency: 'NOK',
    countryCode: '47',
    taxIds: {
      no_vat: {
        id: 'no_vat',
        description: 'Norwegian VAT number',
        example: '123456789MVA',
      },
      no_voec: {
        id: 'no_voec',
        description: 'Norwegian VAT on e-commerce number',
        example: '1234567',
      },
    },
  },
  {
    flag: 'poland',
    label: 'Poland',
    value: 'PL',
    lang: 'pl',
    continent: 'Europe',
    currency: 'EUR',
    countryCode: '48',
    unions: ['EU'],
    taxIds: {
      eu_vat: {
        id: 'eu_vat',
        description: 'European VAT number',
        example: 'PL1234567890',
      },
    },
  },
  {
    flag: 'portugal',
    label: 'Portugal',
    value: 'PT',
    lang: 'pt',
    continent: 'Europe',
    currency: 'EUR',
    countryCode: '351',
    unions: ['EU'],
    taxIds: {
      eu_vat: {
        id: 'eu_vat',
        description: 'European VAT number',
        example: 'PT123456789',
      },
    },
  },
  {
    flag: 'qatar',
    label: 'Qatar',
    value: 'QA',
    lang: 'en',
    continent: 'Asia',
    currency: 'QAR',
    countryCode: '974',
    taxIds: {
      // source: https://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/tax-identification-numbers/Qatar-TIN.pdf
      qatar_tin_gta: {
        id: 'qatar_tin_gta',
        description: 'Qatar TIN (GTA)',
        example: '5000000001',
      },
      qatar_tin_qfca: {
        id: 'qatar_tin_qfca',
        description: 'Qatar TIN (QFCA)',
        example: 'T00001',
      },
    },
  },
  {
    flag: 'romania',
    label: 'Romania',
    value: 'RO',
    lang: 'ro',
    continent: 'Europe',
    currency: 'EUR',
    countryCode: '40',
    unions: ['EU'],
    taxIds: {
      eu_vat: {
        id: 'eu_vat',
        description: 'European VAT number',
        example: 'RO1234567891',
      },
      ro_tin: {
        id: 'ro_tin',
        description: 'Romanian tax ID number',
        example: '1234567890123',
      },
    },
  },
  {
    flag: 'saudi-arabia',
    label: 'Saudi Arabia',
    value: 'SA',
    lang: 'en',
    continent: 'Asia',
    currency: 'SAR',
    countryCode: '966',
    taxIds: {
      sa_vat: {
        id: 'sa_vat',
        description: 'Saudi Arabian VAT number',
        example: '123456789012345',
      },
    },
  },
  {
    flag: 'slovakia',
    label: 'Slovakia',
    value: 'SK',
    lang: 'sk',
    continent: 'Europe',
    currency: 'EUR',
    countryCode: '421',
    unions: ['EU'],
    taxIds: {
      eu_vat: {
        id: 'eu_vat',
        description: 'European VAT number',
        example: 'SK1234567891',
      },
    },
  },
  {
    flag: 'singapore',
    label: 'Singapore',
    value: 'SG',
    lang: 'ms',
    continent: 'Asia',
    currency: 'SGD',
    countryCode: '65',
    taxIds: {
      sg_gst: {
        id: 'sg_gst',
        description: 'Singaporean GST',
        example: 'M12345678X',
      },
      sg_uen: {
        id: 'sg_uen',
        description: 'Singaporean UEN',
        example: '123456789F',
      },
    },
  },
  {
    flag: 'slovenia',
    label: 'Slovenia',
    value: 'SI',
    lang: 'sl',
    continent: 'Europe',
    currency: 'EUR',
    countryCode: '386',
    unions: ['EU'],
    taxIds: {
      eu_vat: {
        id: 'eu_vat',
        description: 'European VAT number',
        example: 'SI12345678',
      },
      si_tin: {
        id: 'si_tin',
        description: 'Slovenia tax number (davčna številka)',
        example: '12345678',
      },
    },
  },
  {
    flag: 'south-korea',
    label: 'South Korea',
    value: 'KR',
    lang: 'ko',
    continent: 'Asia',
    currency: 'KRW',
    countryCode: '82',
    taxIds: {
      kr_brn: {
        id: 'kr_brn',
        description: 'Korean Business Registration Number (BRN)',
        example: '123-45-67890',
      },
    },
  },
  {
    flag: 'spain',
    label: 'Spain',
    value: 'ES',
    lang: 'es',
    continent: 'Europe',
    currency: 'EUR',
    countryCode: '34',
    unions: ['EU'],
    taxIds: {
      es_cif: {
        id: 'es_cif',
        description: 'Spanish NIF number (previously Spanish CIF number)',
        example: 'A12345678',
      },
      eu_vat: {
        id: 'eu_vat',
        description: 'European VAT number',
        example: 'ESA1234567Z',
      },
    },
  },
  {
    flag: 'sweden',
    label: 'Sweden',
    value: 'SE',
    lang: 'sv',
    continent: 'Europe',
    currency: 'SEK',
    countryCode: '46',
    unions: ['EU'],
    taxIds: {
      eu_vat: {
        id: 'eu_vat',
        description: 'European VAT number',
        example: 'SE123456789123',
      },
    },
  },
  {
    flag: 'switzerland',
    label: 'Switzerland',
    value: 'CH',
    lang: 'de',
    continent: 'Europe',
    currency: 'EUR',
    countryCode: '41',
    taxIds: {
      ch_vat: {
        id: 'ch_vat',
        description: 'Switzerland VAT number',
        example: 'CHE-123.456.789 MWST',
      },
    },
  },
  {
    flag: 'united-kingdom',
    label: 'United Kingdom',
    value: 'GB',
    lang: 'en',
    continent: 'Europe',
    currency: 'GBP',
    countryCode: '44',
    taxIds: {
      eu_vat: {
        id: 'eu_vat',
        description: 'Northern Ireland VAT number',
        example: 'XI123456789',
      },
      gb_vat: {
        id: 'gb_vat',
        description: 'United Kingdom VAT number',
        example: 'GB123456789',
      },
    },
  },
  {
    flag: 'taiwan',
    label: 'Taiwan',
    value: 'TW',
    lang: 'zh',
    continent: 'Asia',
    currency: 'TWD',
    countryCode: '158',
    taxIds: {
      tw_vat: {
        id: 'tw_vat',
        description: 'Taiwanese VAT',
        example: '12345678',
      },
    },
  },
  {
    flag: 'thailand',
    label: 'Thailand',
    value: 'TH',
    lang: 'th',
    continent: 'Asia',
    currency: 'THB',
    countryCode: '66',
    taxIds: {
      th_vat: {
        id: 'th_vat',
        description: 'Thai VAT number',
        example: '1234567891234',
      },
    },
  },
  {
    flag: 'united-arab-emirates',
    label: 'United Arab Emirates',
    value: 'AE',
    lang: 'en',
    continent: 'Asia',
    currency: 'AED',
    countryCode: '971',
    taxIds: {
      ae_trn: {
        id: 'ae_trn',
        description: 'United Arab Emirates TRN',
        example: '123456789012345',
      },
    },
  },
  {
    flag: 'united-states-of-america',
    label: 'United States',
    value: 'US',
    lang: 'en',
    continent: 'North America',
    currency: 'USD',
    countryCode: '1',
    taxIds: {
      us_ein: {
        id: 'us_ein',
        description: 'United States Employer Identification Number (EIN)',
        example: '12-3456789',
      },
    },
  },
  {
    flag: 'india',
    label: 'India',
    value: 'IN',
    lang: 'hi',
    continent: 'Asia',
    currency: 'INR',
    countryCode: '91',
    taxIds: {
      in_gst: {
        id: 'in_gst',
        description: 'Indian GST number',
        example: '12ABCDE3456FGZH',
      },
    },
  },
] as const;

export const DEFAULT_COUNTRY = 'US';
export const MIDDLE_EAST = ['SA', 'AE', 'QA'];
export const NORDIC = ['NO', 'SE', 'DK', 'FI'];

export const US_STATES = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District of Columbia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
] as { value: CountryCode; label: string }[];

export const CA_PROVINCES = [
  { value: 'AB', label: 'Alberta' },
  { value: 'BC', label: 'British Columbia' },
  { value: 'MB', label: 'Manitoba' },
  { value: 'NB', label: 'New Brunswick' },
  { value: 'NL', label: 'Newfoundland and Labrador' },
  { value: 'NS', label: 'Nova Scotia' },
  { value: 'NT', label: 'Northwest Territories' },
  { value: 'NU', label: 'Nunavut' },
  { value: 'ON', label: 'Ontario' },
  { value: 'PE', label: 'Prince Edward Island' },
  { value: 'QC', label: 'Québec' },
  { value: 'SK', label: 'Saskatchewan' },
  { value: 'YT', label: 'Yukon' },
] as { value: CountryCode; label: string }[];

export const getSupportedCountries = () => COUNTRIES;

export const getIsCountrySupported = (country: CountryCode | null) => {
  if (!country) {
    return false;
  }

  const supportedCountries = getSupportedCountries();

  return supportedCountries.some(({ value }) => country === value);
};
