import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

import { Input } from '@remarkable/ark-web';

import { cn } from 'src/utils/classNamesHelper';

export const Input2 = forwardRef<
  ElementRef<typeof Input>,
  ComponentPropsWithoutRef<typeof Input>
>(function renderInput({ className, ...props }, ref) {
  return (
    <Input
      className={cn(
        // These styles exists temporarily to allow for a gradual migration to Ark form components (so that they look like the legacy ones during the migration)
        'bg-white shadow-button-faded !shadow-neutral-light-8',
        className
      )}
      {...props}
      ref={ref}
    />
  );
});
