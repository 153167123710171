import * as Sentry from '@sentry/react';
import { createFileRoute, invariant, redirect } from '@tanstack/react-router';
import jwtDecode from 'jwt-decode';

import { queryEnterpriseEnrollmentStatus } from 'src/api/queries/enterpriseEnrollment';
import { RmClaims } from 'src/api/queries/user';
import { LogCategory } from 'src/utils/logger.categories';
import { userHelpers } from 'src/utils/userHelpers';

export const Route = createFileRoute('/_auth/_layout/enterprise/')({
  async beforeLoad({ context }) {
    const auth = await context.auth;
    const accessToken = jwtDecode<RmClaims>(
      await auth.getAccessTokenSilently()
    );

    invariant(auth.user, 'User not found');

    if (userHelpers.hasActiveEnterpriseEnrollment(auth.user)) {
      // We can't fully trust the ID Token to be up to date, so we need to
      // double check the enrollment status with the server.
      const enrollmentStatus = await context.queryClient.ensureQueryData(
        queryEnterpriseEnrollmentStatus()
      );

      if (
        enrollmentStatus?.enrollment === 'INITIAL' ||
        enrollmentStatus?.enrollment === 'CONNECTED'
      ) {
        Sentry.addBreadcrumb({
          category: LogCategory.Auth,
          message:
            'User has active enterprise enrollment, redirecting to enroll',
        });
        throw redirect({
          from: '/enterprise',
          to: '/enterprise/enroll',
          replace: true,
        });
      }
    }

    if (!userHelpers.isOrganizationMember(auth.user)) {
      Sentry.addBreadcrumb({
        category: LogCategory.Auth,
        message:
          'User is not an organization member, redirecting to /enterprise/enroll',
      });
      throw redirect({
        from: '/enterprise',
        to: '/enterprise/enroll',
        replace: true,
      });
    }

    if (userHelpers.hasPermissionReadMembers(accessToken)) {
      Sentry.addBreadcrumb({
        category: LogCategory.Auth,
        message:
          'User has permission to read members, redirecting to /enterprise/members',
      });
      throw redirect({
        from: '/enterprise',
        to: '/enterprise/members',
        replace: true,
      });
    }

    Sentry.addBreadcrumb({
      category: LogCategory.Auth,
      message: 'Redirecting to /enterprise/plan',
    });

    throw redirect({
      from: '/enterprise',
      to: '/enterprise/plan',
    });
  },
});
