import * as Sentry from '@sentry/react';

import { LogCategory } from 'src/utils/logger.categories';

import { CountryCode } from './myRmApi.types';

const defaultCountry: CountryCode = 'US';

export const getCountry = () => {
  return fetch('/api/country')
    .then(async (res) => {
      const body = await res.text();

      if (body.includes('import')) {
        Sentry.addBreadcrumb({
          category: LogCategory.Locale,
          message:
            'Detected import statement in country response, the edge function is probably not running, using default (US)',
        });
        return defaultCountry;
      }

      if (body) {
        Sentry.addBreadcrumb({
          category: LogCategory.Locale,
          message: `Detected country: ${body}`,
        });

        return body as CountryCode;
      }

      Sentry.addBreadcrumb({
        category: LogCategory.Locale,
        message: 'Failed to detect country, using default (US)',
      });

      return defaultCountry;
    })
    .catch(() => defaultCountry);
};
