import { CheckoutTypeParam } from 'src/api/endpoints/storeApi.types';
import { Feedback, SomethingWentWrong, Spinner } from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { URLS } from 'src/utils/urls/urls';

import { SubscriptionSummaryCard } from '../components/SubscriptionSummaryCard';
import { useCheckoutConfirmation } from '../useCheckoutConfirmation';

export const WinbackOfferConfirmationPage = () => {
  const { isPending, isError, paymentConfirm, subscription } =
    useCheckoutConfirmation({
      checkoutType: CheckoutTypeParam.WINBACK_OFFER,
    });

  if (isPending) {
    return <Spinner />;
  }
  if (isError || !paymentConfirm || !subscription) {
    return <SomethingWentWrong />;
  }

  const nextBillingDate = new Date(subscription.createdAt ?? '');
  nextBillingDate.setMonth(nextBillingDate.getMonth() + 1);

  return (
    <AppPage.Content className="max-w-ll">
      <SubscriptionSummaryCard
        className="mt-32 lm:mt-64"
        subscription={subscription}
        paymentConfirm={paymentConfirm}
        checkoutType="winbackOffer"
        to={URLS.STORE_METHODS_OFFER_DOWNLOAD_APPS}
      />

      <Feedback
        className="my-32"
        title="Did you find what you were looking for?"
        feedbackTargetId="store-winback-offer-confirmation"
      />
    </AppPage.Content>
  );
};
