import { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';

import {
  CheckoutCountryChanged,
  CheckoutStepCompleted,
  PaymentFailed,
  PaymentSucceeded,
  SetupStepCompleted,
} from 'src/ampli';
import { AnalyticsCoupons } from 'src/analytics';
import { tracker } from 'src/analytics/tracker';
import { CHECKOUT_STEPS } from 'src/analytics/trackingTypes';
import { MONTHLY_CONNECT_PRICE_IN_USD_FOR_TRACKING } from 'src/analytics/utils';
import {
  mapCheckoutTypeToRouteType,
  useCheckoutType,
} from 'src/apps/checkout/app/useCheckoutType';
import { useUrlSearchParams } from 'src/hooks/useUrlSearchParams';
import {
  COUNTRIES,
  CountryData,
  DEFAULT_COUNTRY,
  getIsCountrySupported,
} from 'src/utils/countryList';
import { subscriptionHelpers } from 'src/utils/subscriptionHelpers';

import { HTTPError } from '../createApiClient.types';
import { storeApi } from '../endpoints';
import {
  CheckoutCartTaxBody,
  CheckoutType,
  CheckoutTypeParam,
  PaymentInterval,
} from '../endpoints/storeApi.types';
import { cartIdStore } from '../orderIdStore';
import { queryKeys } from '../queries/queryKeys';
import { useCountry } from './country';

export const createQueryCheckoutAllowed = () =>
  queryOptions({
    queryKey: queryKeys.checkout.allowed,
    queryFn: storeApi.getCheckoutAllowed,
    staleTime: Infinity,
  });

export const useCheckoutAllowed = () => {
  return useQuery(createQueryCheckoutAllowed());
};

export const useCheckoutAllowedSuspense = () =>
  useSuspenseQuery(createQueryCheckoutAllowed());

const getTrackingCoupon = (checkoutType: CheckoutType | null) => {
  switch (checkoutType) {
    case 'connectOffer':
      return AnalyticsCoupons.CONNECT_OFFER;
    case 'dmOffer':
      return AnalyticsCoupons.DM_OFFER;
    case 'winbackOffer':
      return AnalyticsCoupons.WINBACK_OFFER;
    case 'store':
    default:
      return AnalyticsCoupons.NONE;
  }
};

export const useCart = () => {
  const checkoutType = useCheckoutType();
  const selectedCheckoutType = checkoutType ?? 'store';
  const checkoutAllowed = useCheckoutAllowed();
  const queryClient = useQueryClient();
  const country = useCountry();
  const isCountrySupported = getIsCountrySupported(country.data?.value || null);

  const setup = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: queryKeys.checkout.setup(selectedCheckoutType),
    queryFn: async () => {
      const countryCode = country.data?.value ?? '';
      Sentry?.addBreadcrumb({
        category: 'Checkout',
        message: 'Cart setup started',
        data: {
          type: selectedCheckoutType,
          country: countryCode,
        },
      });

      const response = await storeApi.setupNewCart({
        type: mapCheckoutTypeToRouteType[selectedCheckoutType],
        country: countryCode,
        paymentInterval: PaymentInterval.MONTHLY,
      });

      queryClient.setQueryData(
        queryKeys.checkout.details(selectedCheckoutType, response.cartId),
        { data: response }
      );

      return response;
    },
    enabled:
      !!country.data &&
      !!checkoutType &&
      checkoutAllowed.data?.[checkoutType].allowed === true &&
      isCountrySupported,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!setup.error) return;

    async function trackError() {
      if (setup.error instanceof HTTPError) {
        Sentry?.captureEvent({
          message: `[Checkout]: Setup cart failed with status ${setup.error.response.status}`,
          extra: {
            errorResponseBody: await setup.error.response.text(),
          },
        });
      } else {
        Sentry?.captureException(setup.error);
      }
    }

    void trackError();
  }, [setup.error]);

  useEffect(() => {
    if (!setup.data) return;

    Sentry?.addBreadcrumb({
      category: 'Checkout',
      data: { cart_id: setup.data.cartId },
      message: 'Cart setup',
    });

    tracker.trackEvent(
      new CheckoutStepCompleted({
        checkout_step: CHECKOUT_STEPS.CHECKOUT_PROCESS_STARTED,
        coupon: getTrackingCoupon(checkoutType),
        products: [
          {
            sku: setup.data.product?.sku,
            name: setup.data.product?.name,
            quantity: 1,
          },
        ],
      })
    );
  }, [setup.data, checkoutType, queryClient]);

  const details = useQuery({
    queryKey: queryKeys.checkout.details(
      selectedCheckoutType,
      setup.data?.cartId
    ),
    queryFn: () => {
      Sentry?.addBreadcrumb({
        category: 'Checkout',
        message: 'Requesting cart details',
        data: {
          cartId: setup.data?.cartId,
        },
      });

      return storeApi.getCartDetails({
        type: mapCheckoutTypeToRouteType[selectedCheckoutType],
        id: setup.data?.cartId ?? '',
        paymentInterval: PaymentInterval.MONTHLY,
      });
    },
    staleTime: Infinity,
    enabled: !!country.data?.currency && !!setup.data?.cartId,
  });

  const updateCurrency = useMutation({
    mutationKey: queryKeys.checkout.currency,
    mutationFn: (country: CountryData) => {
      Sentry?.addBreadcrumb({
        category: 'Checkout',
        message: 'Update currency started',
        data: {
          cartId: setup.data?.cartId,
          country: country,
        },
      });

      const params = {
        type: mapCheckoutTypeToRouteType[checkoutType ?? 'store'],
        country: country.value,
        id: setup.data?.cartId ?? '',
        secret: setup.data?.clientSecret ?? '',
        paymentInterval: PaymentInterval.MONTHLY,
      };

      return storeApi.updateCartDetails(params);
    },
    onMutate(newCountry: CountryData) {
      Sentry?.addBreadcrumb({
        category: 'Checkout',
        message: 'Optimistic update on currency (country) change',
        data: {
          cartId: setup.data?.cartId,
          country: country.data,
        },
      });
      // optimistic update so that the UI updates immediately
      const previousCountry = country.data;
      country.setCountry(newCountry);

      tracker.trackEvent(
        new CheckoutCountryChanged({
          from_country: previousCountry?.value ?? 'unknown',
          to_country: newCountry.value,
        })
      );

      return { previousCountry };
    },
    onSuccess(response) {
      Sentry?.addBreadcrumb({
        category: 'Checkout',
        message: 'Update currency success',
      });
      queryClient.setQueryData(
        queryKeys.checkout.details(selectedCheckoutType, setup.data?.cartId),
        response
      );
    },
    async onError(error, country, context) {
      if (error instanceof HTTPError) {
        Sentry?.captureEvent({
          message: `[Checkout]: Updating currency (via country) failed with status ${error.response.status}`,
          extra: {
            attemptedCountry: country,
            errorResponseBody: await error.response.text(),
            cartId: setup.data?.cartId,
          },
        });
      } else {
        Sentry?.captureException(error);
      }
      // revert optimistic update if the request fails
      queryClient.setQueryData(
        queryKeys.checkout.country,
        context?.previousCountry
      );
    },
  });

  const updateTax = useMutation({
    mutationKey: queryKeys.checkout.tax,
    mutationFn: (data: CheckoutCartTaxBody) => {
      Sentry?.addBreadcrumb({
        category: 'Checkout',
        message: 'Updating tax started',
        data: {
          cartId: setup.data?.cartId,
          country: country.data?.value,
        },
      });

      const params = {
        type: mapCheckoutTypeToRouteType[checkoutType ?? 'store'],
        country: country.data?.value ?? '',
        id: setup.data?.cartId ?? '',
        taxData: data,
        secret: setup.data?.clientSecret ?? '',
        paymentInterval: PaymentInterval.MONTHLY,
      };

      return storeApi.updateCartDetails(params);
    },
    onSuccess: (details) => {
      Sentry?.addBreadcrumb({
        category: 'Checkout',
        message: 'Updating tax success',
        data: {
          cartId: setup.data?.cartId,
        },
      });
      queryClient.setQueryData(
        queryKeys.checkout.details(selectedCheckoutType, setup.data?.cartId),
        details
      );
    },
    async onError(error) {
      if (error instanceof HTTPError) {
        if (error.response.status < 500) {
          Sentry?.addBreadcrumb({
            category: 'Checkout',
            message: `Updating tax failed with status ${error.response.status}`,
            data: {
              errorResponseBody: await error.response.text(),
              cartId: setup.data?.cartId,
            },
          });
        } else {
          Sentry?.captureEvent({
            message: `[Checkout]: Updating tax failed with status ${error.response.status}`,
            extra: {
              errorResponseBody: await error.response.text(),
              cartId: setup.data?.cartId,
            },
          });
        }
      } else {
        Sentry?.captureException(error);
      }
    },
  });

  const checkout = useMutation({
    mutationKey: queryKeys.checkout.checkoutCart,
    mutationFn: (details: Record<string, unknown>) => {
      Sentry?.addBreadcrumb({
        category: 'Checkout',
        message: 'Checking out the cart started',
        data: {
          cartId: setup.data?.cartId,
        },
      });

      return storeApi.checkoutCart({
        type: mapCheckoutTypeToRouteType[checkoutType ?? 'store'],
        data: {
          intent_client_secret: setup.data?.clientSecret,
          cart_id: setup.data?.cartId,
          order_id: undefined,
          details: details,
        },
      });
    },
    onSuccess: (data) => {
      if (!data.cartId) {
        throw new Error(
          `No cart id returned from checkout for cart ${
            setup.data?.cartId ?? ''
          }`
        );
      }
      Sentry?.addBreadcrumb({
        category: 'Checkout',
        message:
          'Checking out success, storing cart ID to prepare for Stripe checkout',
        data: {
          cartId: data.cartId,
        },
      });
      cartIdStore.set(data.cartId);
    },

    async onError(error) {
      if (error instanceof HTTPError) {
        Sentry?.captureEvent({
          message: `[Checkout]: Checking out failed with status ${error.response.status}`,
          extra: {
            cartId: setup.data?.cartId,
            errorResponseBody: await error.response.text(),
          },
        });
      } else {
        Sentry?.captureException(error);
      }
    },
  });

  // Checking if country is supported, as country can be cached from an instance where it is supported
  useEffect(() => {
    if (!country.data?.value || !checkoutType) return;

    if (!isCountrySupported) {
      Sentry?.addBreadcrumb({
        category: 'Checkout',
        message: `The country "${country.data.value}" is not supported, changing to default country "${DEFAULT_COUNTRY}"`,
      });
      const defaultCountry = COUNTRIES.find(
        ({ value }) => value === DEFAULT_COUNTRY
      );
      if (!defaultCountry) {
        Sentry?.captureEvent({
          message:
            '[Checkout]: Unsupported country selected, but no fallback country found for default country code',
          extra: {
            selectedCountry: country.data.value,
            defaultFallbackCountry: DEFAULT_COUNTRY,
          },
        });
        return;
      }
      // Will also update country
      updateCurrency.mutate(defaultCountry);
    }
  }, [country.data?.value, checkoutType, isCountrySupported]);

  return {
    setup,
    details,
    country,
    setCountry: (country: CountryData) => {
      updateCurrency.mutate(country);
    },
    updateCurrency,
    updateTax,
    checkout,
    checkoutType,
  };
};

export const useConfirmPayment = () => {
  const queryClient = useQueryClient();
  const checkoutType = useCheckoutType();

  const [
    {
      setup_intent_client_secret: setupSecret,
      payment_intent_client_secret: paymentSecret,
    },
  ] = useUrlSearchParams();

  return useMutation({
    mutationFn: (vars: { type: CheckoutTypeParam }) => {
      const cartId = cartIdStore.get();
      Sentry?.addBreadcrumb({
        category: 'Checkout',
        message: 'Confirm Payment started',
        data: {
          cartId,
        },
      });
      if (!cartId) {
        throw new Error('[ConfirmPayment]: No cart id');
      }

      if (!setupSecret && !paymentSecret) {
        throw new Error('[ConfirmPayment]: No client secret');
      }

      return storeApi.postCheckoutPaymentVerifyIntent({
        type: vars.type,
        payload: {
          order_id: cartId,
          paymentInterval: PaymentInterval.MONTHLY,
          setup_intent_client_secret: setupSecret ?? undefined,
          payment_intent_client_secret: paymentSecret ?? undefined,
        },
      });
    },
    onSuccess: async ({ data }) => {
      Sentry?.addBreadcrumb({
        category: 'Checkout',
        message: 'Confirm payment success',
      });

      const subscription = await storeApi.getSubscription();

      tracker.trackEvent(
        new PaymentSucceeded({
          order_id: data.orderId,
          currency: data.currency,
          products: [
            {
              sku: { value: data.product?.sku },
              name: { value: data.product?.name },
              quantity: { value: 1 },
            },
          ],
          revenue: MONTHLY_CONNECT_PRICE_IN_USD_FOR_TRACKING.toString(),
          // We want the same revenue regardless of the currency, so we use USD
          // TODO: Look into getting the price from the API instead of hardcoding it
          coupon: getTrackingCoupon(checkoutType),
          payment_method:
            subscriptionHelpers.getPaymentMethodType(subscription),
        })
      );
      tracker.trackEvent(
        new SetupStepCompleted({
          step_name: 'Activate your free Connect',
          step_number: 3,
        })
      );

      cartIdStore.remove();
      void queryClient.invalidateQueries({
        queryKey: queryKeys.checkoutBaseKey,
      });
      void queryClient.invalidateQueries({
        queryKey: queryKeys.dev.stripeTestClock,
      }); // QoL
      return queryClient.invalidateQueries({
        queryKey: queryKeys.subscriptions.all,
      });
    },
    async onError(error) {
      let error_message;
      if (error instanceof HTTPError) {
        error_message = `Http status ${error.response.status}`;
        Sentry?.captureEvent({
          message: `[ConfirmPayment]: Confirm payment failed with status ${error.response.status}`,
          extra: {
            errorResponseBody: await error.response.text(),
            cartId:
              cartIdStore.get() ??
              'See breadcrumb "[ConfirmPayment]: Confirm Payment started"',
          },
        });
      } else {
        error_message = error.message;
        Sentry?.captureException(error);
      }

      cartIdStore.remove();
      tracker.trackEvent(new PaymentFailed({ error_message }));
    },
    mutationKey: queryKeys.checkout.confirmPayment,
  });
};
