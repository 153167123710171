import { Typography } from '@remarkable/ark-web';
import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { Integration } from 'src/api/endpoints/cloudApi.types';
import {
  useDeleteIntegration,
  useGetProviderAuthURI,
  useIntegrationProviders,
  useIntegrations,
  useUpdateIntegration,
} from 'src/api/queries';
import {
  Button,
  Divider,
  Modal,
  NotificationBox,
  Spinner,
  Table,
} from 'src/components';
import { DropdownMenu } from 'src/components/DropdownMenu';
import { IntegrationIcon } from 'src/components/IntegrationIcon';
import { formatDate } from 'src/utils/formatDate';

import { getProviderDisplayName } from '../utils/getProviderDisplayName';
import { hasProviderRevokedAccess } from '../utils/hasProviderRevokedAccess';
import { EditIntegrationNamePopover } from './EditNamePopover';
import { ReAuthorizeIntegrationButton } from './ReAuthoriseIntegration';
import { RemoveIntegrationModal } from './RemoveIntegrationModal';

const columnHelper = createColumnHelper<Integration>();

export const IntegrationsList = () => {
  const integrations = useIntegrations();
  const providers = useIntegrationProviders();
  const providerAuthUri = useGetProviderAuthURI();
  const deleteIntegration = useDeleteIntegration();
  const updateIntegration = useUpdateIntegration();

  const columns = [
    columnHelper.accessor('name', {
      header: 'Integration',
      cell: (info) => {
        return (
          <span className="flex flex-row items-center gap-8">
            <IntegrationIcon
              className="size-24"
              provider={info.row.original.provider}
              alt={info.row.original.provider}
            />
            <Typography variant="interface-md-medium">
              {info.cell.getValue()}
            </Typography>
          </span>
        );
      },
    }),
    columnHelper.accessor(
      (integration) => getProviderDisplayName(integration.provider),
      {
        header: 'Service',
      }
    ),
    columnHelper.accessor((integration) => formatDate(integration.added), {
      header: 'Authenticated',
    }),
    columnHelper.display({
      id: 'reauthorize',
      cell: (info) => {
        if (hasProviderRevokedAccess(info.row.original)) {
          return (
            <ReAuthorizeIntegrationButton
              integration={info.row.original}
              providerAuthUri={providerAuthUri}
              dataCy="renew-access-button-desktop"
            />
          );
        }
      },
    }),
    columnHelper.display({
      id: 'menu',
      meta: {
        align: 'center',
        colWidth: 'w-[50px]',
        truncate: false,
      },
      cell: (info) => (
        <DropdownMenu
          trigger={
            <Button
              variant="tertiary-neutral"
              className="!rounded data-[state=open]:bg-interaction-tertiary-active"
              size="small"
              data-cy={`integration-option-button-${info.row.original.id}`}
            >
              Edit
            </Button>
          }
        >
          <Modal.Root>
            <RemoveIntegrationModal
              integrationId={info.row.original.id}
              integrationName={getProviderDisplayName(info.row.original.name)}
            />
            <Modal.Trigger asChild>
              <Button
                variant="tertiary-neutral"
                data-cy="remove-integration-button"
                size="medium"
              >
                <span>Remove</span>
              </Button>
            </Modal.Trigger>
            <EditIntegrationNamePopover
              id={info.row.original.id}
              name={info.row.original.name}
            />
          </Modal.Root>
        </DropdownMenu>
      ),
    }),
  ];

  const table = useReactTable({
    data: integrations.data || [],
    columns: columns as ColumnDef<Integration>[],
    getRowId: ({ id }) => id,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    renderFallbackValue: () => '-',
  });

  if (integrations.isPending || providers.isPending) return <Spinner />;
  if (
    integrations.isError ||
    providers.isError ||
    deleteIntegration.isError ||
    updateIntegration.isError
  )
    return (
      <NotificationBox
        className="mt-24"
        variant="error"
        title="Something went wrong. Please try again later."
      />
    );

  if (integrations.data?.length === 0) return null;

  return (
    <>
      <Divider
        title={`Your Integrations (${integrations.data?.length ?? 0})`}
        className="mb-32"
      />
      <Table
        table={table}
        data-cy="integrations-table"
        className="hidden lm:table"
      />
      <ul
        className="border-t border-gray-100 lm:hidden"
        data-cy="integrations-list"
      >
        {table.getRowModel().rows.map((row) => (
          <li
            data-cy={`integration-row-${row.id}`}
            key={row.id}
            className="border-b border-gray-100 py-12"
          >
            <div className="flex flex-row justify-between">
              <div className="flex flex-col items-start  justify-between ">
                <Typography variant="body-sm-regular" className="truncate">
                  <span className="font-semibold">{row.original.name}</span>
                  <span className="mx-4">|</span>
                  {getProviderDisplayName(row.original.provider)}
                </Typography>{' '}
                <Typography variant="body-sm-regular" className="italic">
                  {formatDate(row.original.added)}
                </Typography>
              </div>
              <div className="m-auto">
                {hasProviderRevokedAccess(row.original) && (
                  <ReAuthorizeIntegrationButton
                    dataCy="renew-access-button-mobile"
                    integration={row.original}
                    providerAuthUri={providerAuthUri}
                  />
                )}
              </div>

              <DropdownMenu
                trigger={
                  <Button
                    variant="tertiary-neutral"
                    className="!rounded data-[state=open]:bg-interaction-tertiary-active"
                    size="small"
                  >
                    Edit
                  </Button>
                }
              >
                <Modal.Root>
                  <RemoveIntegrationModal
                    integrationId={row.original.id}
                    integrationName={getProviderDisplayName(row.original.name)}
                  />
                  <Modal.Trigger asChild>
                    <Button
                      variant="tertiary-neutral"
                      data-cy="remove-integration-button"
                      size="medium"
                    >
                      <span>Remove</span>
                    </Button>
                  </Modal.Trigger>
                  <EditIntegrationNamePopover
                    id={row.original.id}
                    name={row.original.name}
                  />
                </Modal.Root>
              </DropdownMenu>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};
