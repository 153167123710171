import { HTMLAttributes, ReactNode } from 'react';

import { Typography } from '@remarkable/ark-web';
import { useLocation } from '@tanstack/react-router';
import clsx from 'clsx';

import { Link2, Link2Props } from 'src/components/Link2';

export const PillNav = ({
  className,
  children,
  ...rest
}: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={clsx(
        'mx-auto flex h-[60px] w-fit flex-row items-center gap-6 rounded-full bg-neutral-light-3 p-6 shadow-lg backdrop-blur-md md:gap-8 md:p-8',
        "[-ms-overflow-style:'none'] [scrollbar-width:none] [&::-webkit-scrollbar]:hidden",
        'mb-32 lm:mb-48',
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

export const PillTab = ({
  to,
  disabled,
  children,
  className,
  ...rest
}: Link2Props) => {
  const { pathname } = useLocation();
  const active = pathname === to;
  return (
    <Link2
      className={clsx(
        className,
        'flex items-center justify-center rounded-full px-12 py-6 no-underline transition-colors duration-300 hover:no-underline  max-md:w-[90px] md:w-[104px] md:px-16 md:py-8',
        active
          ? 'bg-pill-tertiary/10 text-neutral-dark-8 hover:bg-neutral-light-6'
          : 'bg-neutral-light-3 text-muted hover:bg-neutral-light-5 hover:text-neutral-dark-5'
      )}
      to={to}
      disabled={disabled}
      aria-disabled={disabled}
      tab-index={disabled ? -1 : undefined}
      data-selected={active}
      {...rest}
    >
      <Typography
        as="span"
        variant="fluid-body-2xs"
        textWrap="nowrap"
        className={active ? 'font-semibold' : 'font-medium'}
      >
        {children as ReactNode}
      </Typography>
    </Link2>
  );
};
