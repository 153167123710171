import { useMemo } from 'react';

import { Typography } from '@remarkable/ark-web';
import { Pencil } from 'phosphor-react';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useBillingInfo } from 'src/api/queries';
import { Button, FlexDivider, NotificationBox, Spinner } from 'src/components';
import { COUNTRIES } from 'src/utils/countryList';

import { BillingInfoModal } from './BillingInfoModal';
import { CardHeading } from './CardHeading';

export const BillingInfoCard = () => {
  return (
    <div className="flex flex-col gap-16">
      <CardHeading>Billing information</CardHeading>

      <div className="flex flex-col justify-between gap-16 lm:flex-row">
        <BillingInfoDetails />

        <BillingInfoModal
          trigger={
            <Button
              variant="tertiary"
              size="medium"
              className="w-full shrink-0 self-start whitespace-nowrap ls:w-fit"
              analytics={{
                text: 'Edit billing information',
                location: ComponentLocations.MANAGE_CONNECT.PAYMENT_PAGE,
                action: 'open edit billing information modal',
              }}
              data-cy="edit-billing-info-button"
            >
              <Pencil />
              <span>Edit billing information</span>
            </Button>
          }
        />
      </div>
    </div>
  );
};

export const BillingInfoDetails = () => {
  const billingInfo = useBillingInfo();
  const countryName = useMemo(() => {
    if (!billingInfo.data?.address.country) return null;

    const country = COUNTRIES.find(
      (c) => c.value === billingInfo.data?.address.country
    );

    return country?.label ?? billingInfo.data.address.country;
  }, [billingInfo.data?.address.country]);

  if (billingInfo.isPending) {
    return <Spinner />;
  }

  if (billingInfo.isError) {
    return (
      <NotificationBox variant="error" title="Failed to load billing info">
        Reload the page or contact support if the problem persists.
      </NotificationBox>
    );
  }

  const hasUserInfo = Boolean(
    billingInfo.data?.name ||
      billingInfo.data?.address.line1 ||
      billingInfo.data?.address.line2 ||
      billingInfo.data?.address.city ||
      billingInfo.data?.address.zip ||
      billingInfo.data?.address.country ||
      billingInfo.data?.address.state
  );

  const hasCompanyInfo = Boolean(
    billingInfo.data?.companyName || billingInfo.data?.vatNumber
  );

  return (
    <div
      data-cy="billing-info-details"
      className="flex w-full min-w-0 grow flex-col gap-16 ls:flex-row lm:flex-col ll:flex-row"
    >
      {hasUserInfo && (
        <ul className="min-w-0 grow">
          <li>{billingInfo.data?.name}</li>
          {billingInfo.data?.address.line1 && (
            <li>{billingInfo.data?.address.line1}</li>
          )}
          {billingInfo.data?.address.line2 && (
            <li>{billingInfo.data?.address.line2}</li>
          )}
          <li>
            {[
              billingInfo.data?.address.city,
              billingInfo.data?.address.state,
              billingInfo.data?.address.zip,
              countryName,
            ]
              .filter(Boolean)
              .join(', ')}
          </li>
        </ul>
      )}

      {hasUserInfo && hasCompanyInfo && (
        <FlexDivider className="hidden ls:flex lm:hidden ll:flex" />
      )}

      {hasCompanyInfo && (
        <ul className="min-w-0 grow">
          <li>{billingInfo.data?.companyName}</li>
          <li>{billingInfo.data?.vatNumber}</li>
          <li className="truncate" title={billingInfo.data?.email ?? undefined}>
            {billingInfo.data?.email}
          </li>
        </ul>
      )}

      {!hasUserInfo && !hasCompanyInfo && (
        <Typography variant="body-md-regular">
          No billing info available
        </Typography>
      )}
    </div>
  );
};
