import {
  Desktop,
  DeviceMobile,
  GoogleChromeLogo,
  IconWeight,
  Planet,
} from '@phosphor-icons/react';
import { RM1, RM2, RMpp } from '@remarkable/ark-icons';

import msPowerPoint from 'src/assets/icons/msPowerPoint.svg';
import msWord from 'src/assets/icons/msWord.svg';
import { AnyDevice, deviceHelpers } from 'src/utils/deviceHelpers';

export const DeviceIcon = (props: {
  device: AnyDevice;
  size?: number;
  className?: string;
  weight?: IconWeight;
  id?: string;
}) => {
  const size = props.size ?? 24;

  const deviceType = deviceHelpers.getDeviceType(props.device);
  const id = props.id ?? `device-icon-${deviceType}`;

  switch (deviceType) {
    case 'RM1':
      return (
        <RM1
          size={size}
          weight={props.weight}
          className={props.className}
          data-cy="RM1-icon"
          id={id}
        />
      );
    case 'RM2':
      return (
        <RM2
          size={size}
          weight={props.weight}
          className={props.className}
          data-cy="RM2-icon"
          id={id}
        />
      );
    case 'RMPP':
      return (
        <RMpp
          size={size}
          weight={props.weight}
          className={props.className}
          data-cy="RMPP-icon"
          id={id}
        />
      );
    case 'RM_UNKNOWN':
      return (
        <RM2
          size={size}
          weight={props.weight}
          className={props.className}
          data-cy="RM-unknown-icon"
          id={id}
        />
      );
    case 'DESKTOP_LINUX':
    case 'DESKTOP_MACOS':
    case 'DESKTOP_WINDOWS':
    case 'DESKTOP_UNKNOWN':
      return (
        <Desktop
          size={size}
          weight={props.weight}
          className={props.className}
          data-cy="desktop-icon"
          id={id}
        />
      );
    case 'MOBILE_ANDROID':
    case 'MOBILE_IOS':
    case 'MOBILE_UNKNOWN':
      return (
        <DeviceMobile
          size={size}
          weight={props.weight}
          className={props.className}
          data-cy="mobile-icon"
          id={id}
        />
      );
    case 'WORD_EXTENSION':
      return (
        <img
          className={props.className}
          height={size}
          width={size}
          src={msWord}
          alt="Word extension icon"
          data-cy="word-extension-icon"
          id={id}
        ></img>
      );
    case 'POWERPOINT_EXTENSION':
      return (
        <img
          className={props.className}
          height={size}
          width={size}
          src={msPowerPoint}
          alt="PowerPoint extension icon"
          data-cy="powerpoint-extension-icon"
          id={id}
        ></img>
      );
    case 'BROWSER_CHROME':
      return (
        <GoogleChromeLogo
          size={size}
          weight={props.weight}
          className={props.className}
          data-cy="browser-chrome-icon"
          id={id}
        />
      );
    case 'BROWSER_UNKNOWN':
      return (
        <Planet
          size={size}
          weight={props.weight}
          className={props.className}
          data-cy="browser-unknown-icon"
          id={id}
        />
      );
    case 'UNKNOWN':
    default:
      return (
        <Planet
          size={size}
          weight={props.weight}
          className={props.className}
          data-cy="unknown-device-icon"
          id={id}
        />
      );
  }
};
