import { HTMLAttributes, useEffect } from 'react';

import { Check, Copy } from '@phosphor-icons/react';
import toast from 'react-hot-toast';

import { useCopyToClipboard } from 'src/utils/useCopyToClipboard';

import { Button } from './Button';
import { Tooltip } from './Tooltip';

export function CopyButton({
  value,
  children,
  variant = 'tertiary',
  size,
  disabled,
  noText,
  onClick,
  title = 'Copy',
  ...rest
}: HTMLAttributes<HTMLButtonElement> & {
  value?: string;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'tertiary-neutral';
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  noText?: boolean;
}) {
  const copyToClipboard = useCopyToClipboard();

  useEffect(() => {
    if (copyToClipboard.isSuccess) {
      const id = setTimeout(() => {
        copyToClipboard.reset();
      }, 2000);

      return () => clearTimeout(id);
    }
  }, [copyToClipboard.isSuccess]);

  return (
    <Tooltip title={title} enabled={!!noText} side="right" asChild>
      <Button
        variant={variant}
        size={size}
        disabled={!value || disabled || copyToClipboard.isPending}
        loading={copyToClipboard.isPending}
        aria-busy={copyToClipboard.isPending}
        onClick={(e) => {
          copyToClipboard.mutate(value, {
            onError() {
              toast.error('Failed to copy to clipboard');
            },
          });
          onClick?.(e);
        }}
        {...rest}
      >
        {!noText && <span className="truncate">{children ?? 'Copy'}</span>}
        {copyToClipboard.isSuccess ? (
          <Check className="shrink-0 animate-fade-in-up-short" />
        ) : (
          <Copy className="shrink-0 animate-fade-in-up-short" />
        )}
      </Button>
    </Tooltip>
  );
}
