import { useMutation } from '@tanstack/react-query';

import { catchHttpError, onStatus } from '../catchHttpError';
import { testDataApiClient } from '../clients/testDataApiClient';
import {
  DeviceTypes,
  FailedUserCreationError,
  SubscriptionTypes,
  TestDataApiError,
  TestDataApiValidationError,
} from './testDataApi.types';

export const createUser = async (vars: {
  username: string;
  password: string;
  devices: DeviceTypes[] | null;
  subscription: SubscriptionTypes | null;
}) => {
  await catchHttpError(
    testDataApiClient.POST('/users/', {
      body: {
        username: vars.username,
        password: vars.password,
        devices: vars.devices,
        subscription: vars.subscription,
      },
    }),
    onStatus(422, async (error) => {
      const validationError =
        (await error.response.json()) as TestDataApiValidationError;
      const message =
        validationError.detail?.map((err) => err.msg).join(', ') ||
        'Invalid request body';
      throw new FailedUserCreationError(message);
    }),
    onStatus(400, async (error) => {
      const message = (await error.response.json()) as TestDataApiError;
      throw new FailedUserCreationError(message.detail);
    })
  );
};

export const useCreateUserRequest = () =>
  useMutation({
    mutationFn: createUser,
  });
