import { FleetIntegration } from 'src/api/endpoints/cloudEnterprise.types';

/**
 * Sort by length, then by provider name
 */
export function sortByIntegrationLists(
  a?: FleetIntegration[],
  b?: FleetIntegration[]
) {
  const aProviders = a?.map((integration) => integration.provider) ?? [];
  const bProviders = b?.map((integration) => integration.provider) ?? [];

  if (aProviders.length === 0 && bProviders.length === 0) {
    return aProviders.join('').localeCompare(bProviders.join(''));
  }

  return aProviders.length - bProviders.length;
}
