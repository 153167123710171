import { useCheckoutAllowed } from 'src/api/queries';
import { ConnectOfferLandingComponent } from 'src/apps/checkout/app/components/ConnectOfferLandingComponent';
import { Spinner } from 'src/components';

export function ConnectOfferLandingPage() {
  const checkoutAllowed = useCheckoutAllowed();

  if (checkoutAllowed.isPending) {
    return <Spinner />;
  }

  if (checkoutAllowed.data?.connectOffer.allowed) {
    return <ConnectOfferLandingComponent />;
  }
}
