import { useEffect } from 'react';

import { Check, File, FileX, UploadSimple } from '@phosphor-icons/react';
import { Label, Typography } from '@remarkable/ark-web';
import { useQueryClient } from '@tanstack/react-query';
import { getRouteApi } from '@tanstack/react-router';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';

import { queryKeys } from 'src/api/queries';
import {
  useEnterpriseEnrollmentStatus,
  useEnterpriseUpdateConnection,
} from 'src/api/queries/enterpriseEnrollment';
import {
  Button,
  Divider,
  Form,
  Input,
  NotificationBox,
  Sheet,
  Table,
  TableCell,
  TableHeader,
  Tooltip,
} from 'src/components';
import { Abbreviation } from 'src/components/Abbreviation';
import { AppPage } from 'src/components/AppPage';
import { CopyText } from 'src/components/CopyText';
import { URLS } from 'src/utils/urls/urls';

import { CancelSetupModal } from '../components/CancelSetup';
import { EnterpriseSteps } from '../components/EnterpriseSteps';

interface FormData {
  signInUrl: string;
  samlCertificate: FileList;
}

const routeApi = getRouteApi('/_auth/_layout/enterprise/enroll/setup-saml');

export function SetupSamlPage() {
  const enrollmentStatus = useEnterpriseEnrollmentStatus();
  const navigate = routeApi.useNavigate();
  const submitSamlCertificate = useEnterpriseUpdateConnection();
  const queryClient = useQueryClient();

  const form = useForm<FormData>();

  useEffect(() => {
    if (!enrollmentStatus.data) return;

    form.reset({
      signInUrl: enrollmentStatus.data.signInUrl,
    });
  }, [enrollmentStatus.data]);

  const onSubmit = async (data: FormData) => {
    const certificate = samlCertificateFile?.[0]
      ? await samlCertificateFile[0].text()
      : enrollmentStatus.data?.base64Cert;

    if (!certificate) {
      // TODO: Should there be an error thrown here?
      return;
    }

    const isCertificateUnchanged =
      enrollmentStatus.data?.base64Cert === certificate;
    const isSignInUrlUntouched =
      enrollmentStatus.data?.signInUrl === data.signInUrl;
    if (isCertificateUnchanged && isSignInUrlUntouched) {
      return navigate({ to: '../verify-saml' });
    }

    submitSamlCertificate.mutate(
      {
        signInUrl: data.signInUrl,
        certificate: certificate,
      },
      {
        async onSettled() {
          await queryClient.invalidateQueries({
            queryKey: queryKeys.enterpriseEnrollment.status,
          });
        },
        onSuccess() {
          return navigate({ to: '../verify-saml' });
        },
      }
    );
  };

  const samlCertificateFile = form.watch('samlCertificate');
  const hasCertificate = !!(
    samlCertificateFile?.[0] ?? enrollmentStatus.data?.base64Cert
  );
  const fileName =
    samlCertificateFile?.[0]?.name ||
    (enrollmentStatus.data?.base64Cert ? 'Certificate' : 'No file');

  return (
    <AppPage.Container>
      <AppPage.Header title="Single sign-on setup" />
      <AppPage.Content className="max-w-ll">
        <EnterpriseSteps />

        <Sheet className="gap-24">
          <Typography as="h2" variant="heading-sm">
            Connect your identity provider
          </Typography>

          <Typography variant="body-md-regular">
            To securely connect your identity provider to our application and
            enable single sign-on, you&apos;ll need to share{' '}
            <Abbreviation title="Security Assertion Markup Language — open standard for exchanging authentication and authorization data">
              SAML
            </Abbreviation>{' '}
            URLs and a certificate.{' '}
            <Abbreviation title="Assertion Consumer Service URL — Used by your IdP to send a SAML response back to the service provider (us) during authentication.">
              ACS URL
            </Abbreviation>{' '}
            and Entity ID into your identity provider (IdP). Then, from your
            IdP, copy and paste the Sign-in URL here and upload the certificate.
            You may need to configure additional mappings for successful
            authentication:
          </Typography>

          <Divider />

          <div>
            <Typography variant="body-md-bold" className="font-medium">
              1. Copy the entity ID and add it to your identity provider
            </Typography>
            <Typography variant="body-sm-regular">
              This is used by your identity provider to recognize your
              application.
            </Typography>
            <CopyText
              value={enrollmentStatus.data?.entityId}
              aria-label="Copy entity ID"
            />
          </div>

          <div>
            <Typography variant="body-md-bold" className="font-medium">
              2. Copy reply/ACS URL and add it to your identity provider
            </Typography>
            <Typography variant="body-sm-regular">
              Your identity provider will send login information to this URL.
            </Typography>
            <CopyText
              value={enrollmentStatus.data?.replyUrl}
              aria-label="Copy reply URL"
            />
          </div>

          <Form {...form} onSubmit={onSubmit} className="flex flex-col gap-24">
            <input type="hidden" value="something" />
            <Input
              name="signInUrl"
              label="3. Add the sign-in URL"
              type="url"
              description="This is the address users will use to log in with single sign-on. You can find this in your identity provider."
              placeholder="https://example.com/saml/login?SAMLRequest=..."
              required
            />

            <div>
              <Label htmlFor="samlCertificate">
                4. Upload your SAML certificate in PEM or CER format
              </Label>
              <Typography
                variant="interface-xs-regular"
                className="text-neutral-dark-1"
              >
                SAMLP server public key encoded in PEM or CER format. Provided
                by your IdP when setting up new integrations.
              </Typography>

              <Tooltip
                asChild
                title={'Certificate received'}
                enabled={!!enrollmentStatus.data?.base64Cert}
              >
                <label
                  htmlFor="samlCertificate"
                  className={clsx(
                    'group flex h-48 w-full cursor-pointer items-end justify-between gap-24'
                  )}
                  data-cy="saml-certificate-field"
                >
                  <div className="flex h-48 w-full items-center justify-between gap-8 rounded border border-dashed px-16 focus-within:outline focus-within:outline-pen-blue">
                    <div className="flex items-center gap-8">
                      {hasCertificate ? (
                        <File
                          className="inline shrink-0 text-neutral-dark-1"
                          size={24}
                          weight="light"
                          data-cy="saml-certificate-icon"
                        />
                      ) : (
                        <FileX
                          className="inline shrink-0 text-neutral-dark-1"
                          size={24}
                          weight="light"
                        />
                      )}
                      <Typography
                        variant="interface-md-regular"
                        className="truncate"
                      >
                        {fileName}
                      </Typography>
                      <Input
                        className="w-0 opacity-0"
                        name="samlCertificate"
                        type="file"
                        required={!enrollmentStatus.data?.base64Cert}
                      />
                    </div>

                    {enrollmentStatus.data?.base64Cert && (
                      <Check
                        size={24}
                        className="text-pen-blue"
                        data-cy="saml-certificate-submitted-icon"
                      />
                    )}
                  </div>

                  {!enrollmentStatus.data?.base64Cert && (
                    <div
                      role="button"
                      tabIndex={-1}
                      className="ark-button ark-button-large ark-button--tertiary group-focus-within:bg-pen-blue-light"
                    >
                      <span>Upload</span>
                      <UploadSimple size={24} />
                    </div>
                  )}
                </label>
              </Tooltip>
            </div>

            <div>
              <Typography variant="body-md-regular" className="font-medium">
                5. Attribute mapping
              </Typography>

              <Typography variant="body-sm-regular" className="text-pretty">
                The following attributes are required for successful
                authentication and should be mapped in your identity provider.
              </Typography>

              <Table className="mt-16 border-separate border-spacing-0 overflow-hidden rounded border border-neutral-light-8">
                <colgroup>
                  <col className="w-1/3" />
                  <col className="w-2/3" />
                </colgroup>
                <thead className="bg-neutral-light-3">
                  <tr>
                    <TableHeader>
                      <Typography variant="interface-sm-semibold">
                        Name
                      </Typography>
                    </TableHeader>
                    <TableHeader>
                      <Typography variant="interface-sm-semibold">
                        Value
                      </Typography>
                    </TableHeader>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <TableCell>
                      <code>email</code>
                    </TableCell>
                    <TableCell>The user&apos;s email address</TableCell>
                  </tr>
                  <tr>
                    <TableCell>
                      <code>family_name</code>
                    </TableCell>
                    <TableCell>
                      The user&apos;s last name (will be displayed in the
                      overview)
                    </TableCell>
                  </tr>
                  <tr>
                    <TableCell className="border-b-0">
                      <code>given_name</code>
                    </TableCell>
                    <TableCell className="border-b-0">
                      The user&apos;s first name (will be displayed in the
                      overview)
                    </TableCell>
                  </tr>
                </tbody>
              </Table>
            </div>

            <div className="flex w-full flex-col-reverse flex-wrap-reverse gap-24 lm:flex-row">
              <div className="flex flex-1 ls:justify-center lm:justify-start">
                <CancelSetupModal
                  trigger={
                    <Button
                      variant="tertiary-neutral"
                      className="w-full ls:w-fit"
                    >
                      Cancel
                    </Button>
                  }
                />
              </div>

              <div className="flex flex-col-reverse flex-wrap-reverse gap-24 ls:flex-row">
                <Button
                  as="a"
                  variant="secondary"
                  to={URLS.ENTERPRISE_VERIFY_DOMAIN}
                  className="w-full ls:w-fit ls:flex-1 lm:flex-initial"
                >
                  Previous
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  loading={submitSamlCertificate.isPending}
                  disabled={submitSamlCertificate.isPending}
                  className="w-full ls:w-fit ls:flex-1 lm:flex-initial"
                >
                  Continue
                </Button>
              </div>
            </div>
          </Form>

          <NotificationBox error={submitSamlCertificate.error} />
        </Sheet>
      </AppPage.Content>
    </AppPage.Container>
  );
}
