import { atom, useAtom } from 'jotai';

const sideMenuAtom = atom<boolean>(false);
export function useSideMenuState() {
  const [isOpen, setIsOpen] = useAtom(sideMenuAtom);
  return {
    isOpen,
    open() {
      setIsOpen(true);
    },
    close() {
      setIsOpen(false);
    },
    toggle() {
      setIsOpen(!isOpen);
    },
  };
}
