import { useEffect, useMemo } from 'react';

import { Typography } from '@remarkable/ark-web';
import { useLocation, useNavigate } from '@tanstack/react-router';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useActivePaperTablets, useSubscription } from 'src/api/queries';
import { Feedback } from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { ChangeToAnnualBanner } from 'src/components/ChangeToAnnualBanner';
import { PairingCompleteNotification } from 'src/components/PairingCompleteNotification';
import { UpdatePaymentCardBanner } from 'src/components/UpdatePaymentCardBanner';
import { UxSignals } from 'src/components/UxSignals/UxSignals';
import { subscriptionHelpers } from 'src/utils/subscriptionHelpers';

import { AppsAndExtensions } from './components/AppsAndExtensions';
import { AppsPromo } from './components/AppsPromo';
import { DropZone } from './components/DropZone/DropZone';
import { Hero } from './components/Hero';
import { LearningContent } from './components/LearningContent';
import { MethodsCard } from './components/MethodsCard';
import { PasscodeReset } from './components/PasscodeReset';
import { PausedSubscriptionBanner } from './components/PausedSubscriptionBanner';
import { WelcomeBanner } from './components/WelcomeBanner';

export const Home = () => {
  const subscription = useSubscription();
  const navigate = useNavigate();
  const location = useLocation();

  // TODO: Move this to top level of app when we move email verified out
  useEffect(() => {
    if (location.hash.includes('browser')) {
      void navigate({ to: '/device/browser/connect' });
      return;
    }

    if (location.hash.includes('mobile')) {
      void navigate({ to: '/device/mobile/connect' });
      return;
    }

    if (location.hash.includes('desktop')) {
      void navigate({ to: '/device/desktop/connect' });
      return;
    }
  }, [location.hash]);

  const showPairingComplete = useMemo(() => {
    const params = new URLSearchParams(location.searchStr);
    return params.get('flow') === 'pairing-complete';
  }, [location.searchStr]);

  const activePaperTablets = useActivePaperTablets();

  const hasDevice = !!activePaperTablets.data?.length;

  return (
    <AppPage.Container>
      <AppPage.Content className="max-w-l2xl">
        <div id="app-home">
          <div className="mx-auto mb-128 mt-16 max-w-l2xl px-16 text-center">
            {showPairingComplete && <PairingCompleteNotification />}

            <PasscodeReset />
            <ChangeToAnnualBanner
              subscription={subscription.data}
              componentLocation={ComponentLocations.NOTIFICATIONS.FRONT_PAGE}
            />
            <UpdatePaymentCardBanner subscription={subscription} />
            <PausedSubscriptionBanner subscription={subscription.data} />
            <WelcomeBanner />
            <div className="flex w-full flex-col gap-80">
              <Hero />
              {hasDevice && (
                <div className="flex flex-col gap-32 text-left">
                  <Typography variant="heading-sm" as="h2">
                    Upload files to your reMarkable
                  </Typography>
                  <DropZone />
                </div>
              )}

              <UxSignals id="panel-zzsoz0v1w" />

              <MethodsCard
                hasSubscription={subscriptionHelpers.isActive(
                  subscription.data
                )}
              />
              <LearningContent
                hasDevice={hasDevice}
                hasSubscription={subscriptionHelpers.isActive(
                  subscription.data
                )}
              />

              {subscriptionHelpers.isActive(subscription.data) && <AppsPromo />}

              <AppsAndExtensions />

              <Feedback
                title={'Did you find what you were looking for?'}
                feedbackTargetId={'home-page-v1'}
              />
            </div>
          </div>
        </div>
      </AppPage.Content>
    </AppPage.Container>
  );
};
