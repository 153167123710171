import { Typography } from '@remarkable/ark-web';

import { useStripeSubscription } from 'src/api/queries';
import { NotificationBox } from 'src/components';
import { subscriptionHelpers } from 'src/utils/subscriptionHelpers';

export const PaymentNotifications = () => {
  const subscription = useStripeSubscription();

  if (subscription.isPending || subscription.isError || !subscription.data) {
    return null;
  }

  if (
    !subscription.data.paymentInformation ||
    !subscription.data.paymentInformation.payment
  ) {
    return (
      <NotificationBox
        title="Missing payment details"
        data-cy="missing-payment-card-notification"
        variant="error"
      >
        <Typography variant="body-sm-regular">
          Please add your payment information for monthly or annual billing.
        </Typography>
      </NotificationBox>
    );
  }

  if (!subscriptionHelpers.isPaymentFailing(subscription.data)) {
    return null;
  }

  if (subscription.data.paymentInformation.requiresAction) {
    return (
      <NotificationBox
        title="Payment couldn't be authorized"
        data-cy="subscription-manual-action-required-notification"
        variant="error"
      >
        <Typography variant="body-sm-regular">
          We couldn&apos;t process your{' '}
          {subscription.data.paymentInformation.paymentInterval === 'monthly'
            ? 'monthly'
            : 'annual'}{' '}
          payment. Please verify your payment method and reauthorise.
        </Typography>
      </NotificationBox>
    );
  }

  return (
    <NotificationBox
      title="Automatic billing failed"
      data-cy="subscription-billing-overdue"
      variant="error"
    >
      <Typography variant="body-sm-regular">
        We couldn&apos;t process your{' '}
        {subscription.data.paymentInformation.paymentInterval === 'monthly'
          ? 'monthly'
          : 'annual'}{' '}
        payment. Please update your payment details to keep using your
        subscription.
      </Typography>
    </NotificationBox>
  );
};
