import {
  ComponentProps,
  forwardRef,
  PropsWithChildren,
  ReactNode,
} from 'react';

import * as Dropdown from '@radix-ui/react-dropdown-menu';
import { Typography } from '@remarkable/ark-web';
import clsx from 'clsx';
import { CaretRight, Check } from 'phosphor-react';

export interface DropdownMenuItem {
  label: ReactNode;
  id: string;
  onClick?: () => void;
  disabled?: boolean;
  subMenu?: DropdownMenuItem[];
}

export const DropdownMenu = ({
  items,
  children,
  side = 'bottom',
  trigger,
  collisionPadding = 16,
  align = 'start',
  sticky = 'always',
  open,
  onOpenChange,
  ...props
}: {
  items?: DropdownMenuItem[];
  trigger: ReactNode;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
} & ComponentProps<typeof Dropdown.Content>) => {
  return (
    <Dropdown.Root modal={false} open={open} onOpenChange={onOpenChange}>
      <Dropdown.Trigger asChild>{trigger}</Dropdown.Trigger>

      <Dropdown.Portal>
        <Dropdown.Content
          className="flex flex-col gap-4 rounded bg-white p-4 shadow-lg"
          sideOffset={4}
          side={side}
          collisionPadding={collisionPadding}
          align={align}
          sticky={sticky}
          {...props}
        >
          {items ? renderMenuItems(items) : children}
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  );
};

const renderMenuItems = (items?: DropdownMenuItem[]) =>
  items?.map((item) =>
    !item.subMenu ? (
      <DropdownMenuItem
        key={item.id}
        onClick={item.onClick}
        disabled={item.disabled}
      >
        {item.label}
      </DropdownMenuItem>
    ) : (
      <Dropdown.Sub key={item.id}>
        <Dropdown.SubTrigger
          className={clsx(
            'interface-md-regular rounded px-16 py-12 hover:outline-none',
            {
              'cursor-pointer hover:bg-neutral-light-3': !item.disabled,
              'opacity-40': item.disabled,
            }
          )}
        >
          {item.label}
          <CaretRight className="ml-8 inline" />
        </Dropdown.SubTrigger>
        <Dropdown.Portal>
          <Dropdown.SubContent
            className="flex flex-col gap-4 rounded bg-white p-8 shadow-lg"
            sideOffset={2}
            alignOffset={-5}
          >
            {renderMenuItems(item.subMenu)}
          </Dropdown.SubContent>
        </Dropdown.Portal>
      </Dropdown.Sub>
    )
  ) ?? null;

export const DropdownMenuItem = forwardRef<
  HTMLDivElement,
  ComponentProps<typeof Dropdown.Item>
>(function renderDropdownMenuItem({ className, children, ...props }, ref) {
  return (
    <Dropdown.Item
      ref={ref}
      className={clsx(
        'interface-md-regular rounded px-[14px] py-[10px] text-14 hover:outline-none',
        {
          'cursor-pointer hover:bg-neutral-light-3': !props.disabled,
          'cursor-not-allowed opacity-40': props.disabled,
        },
        className
      )}
      onClick={props.onClick}
      {...props}
    >
      {children}
    </Dropdown.Item>
  );
});

export const DropdownMenuSeparator = () => (
  <Dropdown.Separator className="mx-16 my-12 h-[1px] border-neutral-light-4" />
);

export const DropdownMenuLabel = (props: PropsWithChildren) => (
  <Dropdown.DropdownMenuLabel className="px-[14px] py-4">
    <Typography variant="interface-xs-regular" className="text-muted">
      {props.children}
    </Typography>
  </Dropdown.DropdownMenuLabel>
);

export const DropdownMenuCheckboxItem = forwardRef<
  HTMLDivElement,
  ComponentProps<typeof Dropdown.CheckboxItem>
>(function renderDropdownMenuItem({ className, children, ...props }, ref) {
  return (
    <Dropdown.CheckboxItem
      ref={ref}
      className={clsx(
        'interface-md-regular flex items-center gap-8 rounded px-[14px] py-[10px] text-14 hover:outline-none',
        {
          'cursor-pointer hover:bg-neutral-light-3': !props.disabled,
          'cursor-not-allowed opacity-40': props.disabled,
        },
        className
      )}
      onClick={props.onClick}
      {...props}
    >
      <Dropdown.ItemIndicator>
        <Check />
      </Dropdown.ItemIndicator>
      <span>{children}</span>
    </Dropdown.CheckboxItem>
  );
});
