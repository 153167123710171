export function sortByDate(a?: Date, b?: Date) {
  if (!a && !b) {
    return 0;
  }

  if (!a) {
    return 1;
  }

  if (!b) {
    return -1;
  }

  return b.getTime() - a.getTime();
}
