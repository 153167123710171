import { useMemo } from 'react';

import { Subscription } from 'src/api/endpoints/storeApi.types';
import { formatDate, formatShortDate } from 'src/utils/formatDate';
import { formatAmountBrowserDefaultLocale } from 'src/utils/productUtils';
import { subscriptionHasPaymentMethod } from 'src/utils/subscriptionPayment';

export const useFormattedPaymentInfo = (sub?: Subscription) => {
  const paymentInformation = useMemo(() => {
    if (!subscriptionHasPaymentMethod(sub)) {
      return null;
    }
    const payment = sub.paymentInformation.payment;

    const nextBillingDate = sub.paymentInformation.nextInvoice?.dueDate;

    const nextBilling = formatDate(nextBillingDate);

    const nextBillingShort = formatShortDate(nextBillingDate);

    const amount = sub.paymentInformation.nextInvoice?.amount;
    const nextBillingAmount = amount
      ? formatAmountBrowserDefaultLocale(amount.total, amount.currency)
      : 'N/A';

    if (payment.type === 'card') {
      return {
        type: 'card',
        details: {
          cardBrand: payment.details.brand.toUpperCase(),
          lastDigits: payment.details.last4,
          expireMonth: payment.details.expireMonth.toString().padStart(2, '0'),
          expireYear: payment.details.expireYear.toString().slice(-2),
          hasExpired: false, // TODO: Figure out if we need this
          wallet: payment.details.wallet,
        },
        nextBilling,
        nextBillingShort,
        nextBillingAmount,
      };
    }

    return {
      type: payment.type,
      details: payment.details,
      nextBilling,
      nextBillingShort,
      nextBillingAmount,
    };
  }, [sub]);

  return paymentInformation;
};
