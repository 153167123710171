import { Typography } from '@remarkable/ark-web';
import { Medal } from 'phosphor-react';

export const FreeConnectDetails = () => {
  return (
    <div data-cy="free-connect-details">
      <div className="mb-24 flex items-center gap-8">
        <Medal size={28} />
        <Typography variant="interface-sm-regular" className="uppercase">
          Free access
        </Typography>
      </div>
      <Typography variant="body-sm-regular">
        As one of our valued early customers, you have full free access to
        Connect. This is our way to thank you for believing in us from the
        start. Enjoy seamless note-taking with{' '}
        <strong>unlimited cloud storage</strong> and sync in our apps, and up to{' '}
        <strong>three years added protection</strong> for your reMarkable paper
        tablet.
      </Typography>
    </div>
  );
};
