import { HTMLProps, ReactNode, useState } from 'react';

import { Typography } from '@remarkable/ark-web';
import { Controller, useForm } from 'react-hook-form';

import { BillingInfoCountryChanged } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { ComponentLocations } from 'src/analytics/trackingTypes';
import { BillingInfoForm } from 'src/api/endpoints/storeApi.types';
import {
  useBillingInfo,
  useStripeSubscription,
  useUpdateBillingInfo,
} from 'src/api/queries';
import {
  Button,
  CountryPicker,
  Form,
  Input,
  Modal,
  NotificationBox,
  Spinner,
  StatePicker,
} from 'src/components';
import { InputTaxId } from 'src/components/InputTaxId';
import { getSupportedCountries } from 'src/utils/countryList';

import { cn } from '../../../../utils/classNamesHelper';

interface EditBillingInfoFormProps extends HTMLProps<HTMLDivElement> {
  onSuccess?: () => void;
  onCancel?: () => void;
}

const EditBillingInfoForm = ({
  onSuccess,
  onCancel,
  ...props
}: EditBillingInfoFormProps) => {
  const updateBillingInfo = useUpdateBillingInfo();
  const billingInfo = useBillingInfo();
  const subscription = useStripeSubscription();
  const isSeats = subscription.data?.managementType === 'seats';
  const supportedCountries = getSupportedCountries();

  const form = useForm<BillingInfoForm>({
    defaultValues: billingInfo.data,
  });

  const onSubmit = (data: BillingInfoForm) => {
    data.companyName ||= null;
    data.vatNumber ||= null;

    updateBillingInfo.mutate(data, {
      onSuccess: () =>
        billingInfo.refetch().then(() => {
          onSuccess?.();
        }),
    });
  };

  if (billingInfo.isPending || subscription.isPending) {
    return <Spinner />;
  }

  if (billingInfo.isError || subscription.isError) {
    return (
      <NotificationBox variant="error" title="Failed to load billing info">
        Reload the page or contact support if the problem persists.
      </NotificationBox>
    );
  }

  return (
    <div {...props} className={cn('text-left', props.className)}>
      <Form onSubmit={onSubmit} {...form} className="flex flex-col gap-24">
        <Input
          data-cy="billing-name"
          name="name"
          label="Name"
          autoComplete="name"
          focusOnIsEditable
        />
        {isSeats ? (
          <Input
            data-cy="billing-email"
            name="email"
            label="Email"
            required={isSeats}
            type="email"
          />
        ) : null}

        <Input
          data-cy="billing-company-name"
          name="companyName"
          label="Company name"
          required={isSeats}
        />

        <InputTaxId name="vatNumber" required={isSeats} />

        <Input
          data-cy="billing-address1"
          label="Billing address 1"
          autoComplete="address-level1"
          name="address.line1"
        />

        <Input
          data-cy="billing-address2"
          label="Billing address 2"
          autoComplete="address-level2"
          name="address.line2"
        />

        <div className="flex flex-col gap-16 sm:flex-row">
          <Input
            className="flex-1"
            data-cy="billing-zip"
            label="Zip"
            autoComplete="zip"
            name="address.zip"
          />

          <Input
            className="flex-1"
            data-cy="billing-city"
            label="City"
            autoComplete="city"
            name="address.city"
          />
        </div>

        <div className="flex flex-col gap-16 sm:flex-row">
          <StatePicker
            className="flex-1"
            name="address.state"
            countryFieldName="address.country"
            data-cy="billing-state"
          />
          <Controller
            control={form.control}
            name="address.country"
            render={({ field }) => (
              <CountryPicker
                className="flex-1"
                data-cy="billing-country"
                countries={supportedCountries}
                onChange={(e) => {
                  const oldValue = field.value || 'unknown';
                  const newValue = e.target.value;

                  tracker.trackEvent(
                    new BillingInfoCountryChanged({
                      from_country: oldValue,
                      to_country: newValue,
                    })
                  );
                }}
                value={field.value}
                name={field.name}
              />
            )}
          />
        </div>

        <div className="mt-24 flex justify-center gap-16">
          <Modal.Close asChild>
            <Button
              type="button"
              onClick={() => {
                form.reset();
                updateBillingInfo.reset();
                onCancel?.();
              }}
              variant="secondary"
              data-cy="cancel-edit-billing-info-button"
              analytics={{
                text: 'Cancel',
                location: ComponentLocations.MANAGE_CONNECT.MAIN,
                action: 'cancel edit billing info',
              }}
            >
              Cancel
            </Button>
          </Modal.Close>

          <Button
            type="submit"
            loading={updateBillingInfo.isPending}
            data-cy="save-billing-info-button"
            disabled={!form.formState.isDirty}
            analytics={{
              text: 'Save',
              location: ComponentLocations.MANAGE_CONNECT.MAIN,
              action: 'save billing info',
            }}
          >
            Save
          </Button>
        </div>

        {updateBillingInfo.isError && (
          <NotificationBox
            className="mt-24"
            variant="error"
            title="Unable to update billing info"
            data-cy="update-billing-info-error"
          >
            <Typography variant="body-md-regular">
              Please check if your information is correct
            </Typography>
          </NotificationBox>
        )}
      </Form>
    </div>
  );
};

export const BillingInfoModal = ({ trigger }: { trigger: ReactNode }) => {
  const [open, setOpen] = useState(false);
  return (
    <Modal.Root open={open} onOpenChange={setOpen}>
      <Modal.Trigger asChild>{trigger}</Modal.Trigger>

      <Modal.Content title="Billing information">
        <EditBillingInfoForm onSuccess={() => setOpen(false)} />
      </Modal.Content>
    </Modal.Root>
  );
};
