import { CheckCircle } from '@phosphor-icons/react';
import { Tag, Typography } from '@remarkable/ark-web';
import { ArrowRight } from 'phosphor-react';

import { Button } from 'src/components';
import { StackedSheet } from 'src/components/StackedSheet';
import { SKU } from 'src/utils/skus';
import { URLS } from 'src/utils/urls/urls';
import { valueProps } from 'src/utils/valueProps';

import { Highlight } from '../Highlight';
import { Price } from '../Price';

export const ConnectOffer = ({
  componentLocation,
}: {
  componentLocation: string;
}) => {
  return (
    <StackedSheet dataCy="connect-offer-hero">
      <div data-cy="connect-offer" className="flex gap-24 ll:gap-0">
        <div className="flex flex-col gap-16 text-left lm:gap-24">
          <Typography variant="interface-sm-caps">
            Connect subscription
          </Typography>
          <Typography variant="responsive-heading-lg" className="text-pretty">
            Start your <Highlight scale={320}>100-day free</Highlight> trial
          </Typography>

          <div
            data-cy="connect-offer-perks"
            className="flex w-full flex-col items-start gap-16 ll:flex-row"
          >
            <ul className="grid w-full grid-cols-1 gap-x-40 gap-y-16 lm:grid-cols-[minmax(0,max-content)_minmax(0,max-content)]">
              {Object.values(valueProps.connectOfferHero).map((vp) => (
                <li key={vp.title} className="flex w-full items-start gap-8">
                  <CheckCircle size={24} className="shrink-0 text-pen-blue" />

                  <Typography variant="body-sm-regular" className="m-0 flex-1">
                    {vp.title}
                    {vp.isNew && <Tag className="ml-8 inline">NEW</Tag>}
                  </Typography>
                </li>
              ))}
            </ul>
          </div>

          <Button
            as="a"
            variant="primary"
            to={URLS.STORE_CONNECT_OFFER_CHECKOUT}
            className="my-20 w-full lm:w-fit"
            data-cy="connect-offer-hero-activate-button"
            analytics={{
              action: 'activate connect offer',
              location: componentLocation,
              text: 'Activate free trial',
            }}
          >
            <span data-cy="connect-offer-activate-button">
              Start free trial
            </span>
            <ArrowRight />
          </Button>

          <Typography variant="body-sm" className="text-pretty">
            The offer is valid for 30 days after pairing your device. Connect
            costs <Price sku={SKU.connectMonthly} />
            /mo. when your free trial ends. <b>Cancel anytime</b>.
          </Typography>
        </div>
      </div>
    </StackedSheet>
  );
};
