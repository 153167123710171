import { ReactNode } from 'react';

import { Link, TextBody } from 'src/components';
import { Price } from 'src/components/Price';
import { SKU } from 'src/utils/skus';

export interface FAQBlock {
  title: string;
  id: string;
  body: ReactNode;
}

type FAQConfig = {
  [group: string]: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [name: string]: (...args: any[]) => FAQBlock;
  };
};

export const FAQ = {
  account: {
    whatIsMFA: () => ({
      title: 'What is multi-factor authentication?',
      id: 'account.what-is-mfa',
      body: (
        <TextBody>
          Multi-factor authentication (MFA) is an authentication method that
          requires you to provide two or more verification factors to log in to
          My reMarkable. This reduces the risk of someone being able to hack
          your account by getting hold of your password.
        </TextBody>
      ),
    }),
    whatHappensIfIEnableMFA: () => ({
      title: 'What happens if I enable MFA?',
      id: 'account.what-is-happens-when-mfa-is-enabled',
      body: (
        <TextBody>
          When you log in, you’ll have to provide a security code from an
          authenticator app — like Authy or Google Authenticator — as well as
          your password. This helps us verify that it’s really you trying to log
          in.
        </TextBody>
      ),
    }),
  },
  connectWithSeats: {
    canOtherUsersGetAccessToMyDocuments: () => ({
      title: 'Can other users get access to my documents?',
      id: 'connectWithSeats.can-other-users-get-access-to-my-documents',
      body: (
        <TextBody>
          No, only you can access your notes and documents. If you&apos;d like
          to share a document, you can send it by email.
        </TextBody>
      ),
    }),
    invitedToConnectWithSeatsWillPriceChange: () => ({
      title:
        "I've been invited to a Connect subscriptin. Will the price of my subscription change?",
      id: 'connectWithSeats.invited-to-connect-with-seats-will-price-change',
      body: (
        <TextBody>
          When you join a Connect subscription, the subscription fees will be
          covered by the owner of that subscription, and you won&apos;t need to
          make any payments.
        </TextBody>
      ),
    }),
    whatHappensToMyFilesIfIJoin: () => ({
      title: 'What happens to my files if I join?',
      id: 'connectWithSeats.what-happens-to-my-files-if-i-join',
      body: (
        <TextBody>
          Nothing will happen to your notes and files. All your documents will
          remain private, and you&apos;ll still have access to any files that
          have been synced to the cloud.
        </TextBody>
      ),
    }),
    whatHappensToTeamMembersFiles: () => ({
      title: 'When new users join my subscription, do they keep their files?',
      id: 'connectWithSeats.what-happens-to-team-members-files',
      body: (
        <TextBody>
          Yes. When a user joins your subscription, no changes are made to their
          files.
        </TextBody>
      ),
    }),
    canIAccessTeamMembersFiles: () => ({
      title:
        'When I invite new users to my subscription, can I access their files?',
      id: 'connectWithSeats.can-i-access-team-members-files',
      body: (
        <TextBody>
          No, notes or documents are not shared when new members join your
          subscription. All files remain private. If a member leaves your
          subscription, they&apos;ll keep access to their files.
        </TextBody>
      ),
    }),
    alreadyHaveConnect: () => ({
      title: 'I already have Connect. What happens to my current subscription?',
      id: 'connectWithSeats.already-have-connect',
      body: (
        <TextBody>
          If you accept the invitation, your company will pay for your
          subscription. You&apos;ll still have access to all benefits and
          features that come with a regular Connect subscription.
        </TextBody>
      ),
    }),
    whatHappensWithTrial: () => ({
      title: 'What happens if I have a free trial?',
      id: 'connectWithSeats.what-happens-with-trial',
      body: (
        <TextBody>
          You&apos;ll keep your free trial if you join Connect. Once your trial
          ends, your employer will pay for your subscription. If you leave
          Connect, your free trial can&apos;t be resumed.
        </TextBody>
      ),
    }),
    whyDoYouNeedMyCompanyDetails: () => ({
      title: 'Why do you need my company details?',
      id: 'why-do-you-need-my-company-details',
      body: (
        <span>
          We need your company details to ensure accurate billing and to provide
          sales documentation.
        </span>
      ),
    }),
    howDoesBillingWork: (sku?: SKU) => ({
      id: 'how-does-billing-work',
      title: 'How does billing work?',
      body: (
        <span>
          The subscription price is linked to your team&apos;s size and updates
          automatically when you add or remove members. New users can be added
          for <Price sku={sku} />
          /mo. each, and are free until the next billing period. You won&apos;t
          be charged for members who have a free Connect trial. We accept cards
          and other digital payment methods.
        </span>
      ),
    }),
    whenWillIBeCharged: () => ({
      id: 'when-will-i-be-charged',
      title: 'When will I be charged?',
      body: (
        <span>
          You&apos;ll be charged on the same day each month as when you signed
          up for Connect. You can view your billing date in Payment history. You
          won&apos;t be charged for members who have a free Connect trial.
        </span>
      ),
    }),
    whatHappensIfIRemoveAUser: () => ({
      id: 'what-happend-if-i-remove-a-user',
      title: 'What happens if I remove a user from my subscription?',
      body: (
        <span>
          They will keep access to their files, but lose access to all
          subscription features and benefits. On the next billing date, you
          won&apos;t be charged for users who have been removed from your
          subscription.
        </span>
      ),
    }),
    doYouOfferDeviceManagement: () => ({
      id: 'do-you-offer-device-management',
      title: 'Do you offer device management?',
      body: (
        <span>
          Device management isn&apos;t currently available, but this is
          something we&apos;re working on.{' '}
        </span>
      ),
    }),
    whatPaymentOptionsDoYouOffer: () => ({
      title: 'What payment options do you offer?',
      id: 'what-payment-options-do-you-offer',
      body: (
        <span>
          We accept cards and other digital payment methods.
          <br />
          Under <b>Subscription</b>, you can change your payment method and find
          your payment history.
        </span>
      ),
    }),
    whatDoesActiveSeatMean: () => ({
      id: 'what-does-active-seat-mean',
      title: 'What does “active seat” mean?',
      body: (
        <>
          <TextBody>
            The number of active seats shows you how many users in your
            subscription have access to paid features.
          </TextBody>
          <TextBody>
            On your monthly payment, you&apos;ll be billed for active seats.
          </TextBody>
        </>
      ),
    }),
    whyWasMyPaymentDeclined: () => ({
      id: 'why-was-my-payment-declined',
      title: 'Why was my payment declined?',
      body: (
        <TextBody>
          There are several reasons why a payment may be declined. Usually, it
          happens because the payment method lacked sufficient funds, or because
          the card has expired.
        </TextBody>
      ),
    }),
    howOftenWillIBeChargedForConnectWithSeats: () => ({
      id: 'how-often-will-i-be-charged-for-connect-with-seats',
      title: 'How often will I be charged?',
      body: (
        <TextBody>
          You&apos;ll be charged monthly or yearly, depending on your chosen
          billing schedule. Your billing date is the same date as when you
          subscribed to Connect.
        </TextBody>
      ),
    }),
    whyWasMyBillingDifferentThanExpected: () => ({
      id: 'why-was-my-billing-different-than-expected',
      title: 'Why was my billing different than I expected?',
      body: (
        <TextBody>
          The subscription price is linked to your team&apos;s size and updates
          automatically when you add or remove members. If you have unexpected
          charges, check if you&apos;ve added new members to your subscription.
        </TextBody>
      ),
    }),
    willThePriceChangeWhenTheBetaPeriodEnds: () => ({
      id: 'will-the-price-change-when-the-beta-period-ends',
      title: 'Will the price change when the beta period ends?',
      body: (
        <TextBody>
          The price and the terms of use may change after the beta period. You
          can cancel your subscription at any time.
        </TextBody>
      ),
    }),
    asAnOwnerIDontNeedAccessToSubscriptionFeatures: () => ({
      id: 'as-an-owner-i-dont-need-access-to-subscription-features-can-i-remove-these',
      title:
        "As an owner, I don't need access to subscription features. Can I remove these?",
      body: (
        <>
          <TextBody>
            Yes, you can remove subscription features from your seat. Click
            Users, then Deactivate next to your email address in the members
            overview.
          </TextBody>
          <TextBody>
            You&apos;ll then no longer have access to unlimited cloud storage,
            automatic sync, creating and editing in our apps, or the reMarkable
            Protection Plan.
          </TextBody>
        </>
      ),
    }),
    whatIsTheDifferenceBetweenTheUserManagerAndOwnerRoles: () => ({
      id: 'what-is-the-difference-between-the-user-manager-and-owner-roles',
      title: 'What is the difference between the user manager and owner roles?',
      body: (
        <TextBody>
          User managers and owners have different levels of access and
          permissions.
          <br />
          <br />
          <b>User managers</b> can invite and remove members, and see all
          members and pending invitations.
          <br />
          <br />
          <b>Owners</b> can change the payment method, view billing information,
          see receipts, invite and remove members, cancel your subscription, and
          assign or remove owner access.
        </TextBody>
      ),
    }),
    myPaperTabletIsPairedWithPersonalAccount: () => ({
      id: 'my-paper-tablet-is-paired-with-personal-account',
      title:
        'My paper tablet is paired with a personal account. How do I join another Connect subscription without losing my documents?',
      body: (
        <TextBody>
          You don&apos;t need to re-pair your device with a new account to join
          a Connect subscription, just change the email address associated with
          your existing account. On{' '}
          <Link inline to="https://my.remarkable.com">
            my.remarkable.com
          </Link>
          , click <b>Account</b> in the menu in the upper-left corner. Under
          Contact details, change your email to the one invited to the new
          Connect subscription.
        </TextBody>
      ),
    }),
    canIManageASubscriptionWithoutPayingForConnectForMyself: () => ({
      id: 'can-i-manage-a-subscription-without-paying-for-connect-for-myself',
      title:
        'Can I manage a subscription without paying for Connect for myself?',
      body: (
        <TextBody>
          Yes. If you don&apos;t need access to subscription features, you can
          choose to deactivate these. To remove subscription features from your
          seat, click the <b>Members</b> tab, then <b>Deactivate</b> next to
          your email address. You&apos;ll then no longer have access to
          unlimited cloud storage, mobile and desktop note-taking, or the
          reMarkable Protection Plan.
        </TextBody>
      ),
    }),
  },
  enterprise: {
    whatHappensToMyFilesIfIActivateMyEnterpriseAccount: () => ({
      id: 'what-happens-to-my-files-if-i-activate-my-enterprise-account',
      title: 'What happens to my files if I activate my enterprise account?',
      body: (
        <TextBody>
          No changes are made to the content of your files. When you start using
          your enterprise account, control will be transferred to your employer.
          Your employer will then have administrative control of your account,
          and can delete it, which will erase all its files. Your employer
          won&apos;t be able to see or read your files.
        </TextBody>
      ),
    }),
    canAnyoneElseGetAccessToMyDocuments: () => ({
      id: 'can-anyone-else-get-access-to-my-documents',
      title: 'Can anyone else get access to my documents?',
      body: (
        <TextBody>
          Even though control of the account is transferred to your employer,
          others will not get access to your documents. However, your employer
          will have administrative control of your account, and can delete it,
          which will erase all its files.
        </TextBody>
      ),
    }),

    whatHappensToTheTermsAndConditionsForRemarkableAccounts: () => ({
      id: 'what-happens-to-the-terms-and-conditions-for-remarkable-accounts',
      title:
        "What happens to the Terms and Conditions for reMarkable Accounts that I've agreed to?",
      body: (
        <TextBody>
          If you activate an enterprise account, the terms you&apos;ve agreed to
          will end. The contractual relationship will then be between reMarkable
          and your employer.
        </TextBody>
      ),
    }),
    whatHappensIfIHaveAFreeConnectTrial: () => ({
      id: 'what-happens-if-i-have-a-free-connect-trial',
      title: 'What happens if I have a free Connect trial?',
      body: (
        <TextBody>
          You&apos;ll keep your free trial. Once your trial ends, your employer
          will pay for your subscription.
        </TextBody>
      ),
    }),
    iHaveFreeAccessToConnect: () => ({
      id: 'i-have-free-access-to-connect',
      title:
        'I have free access to Connect. What happens if I join a Connect subscription?',
      body: (
        <TextBody>
          You&apos;ll still get Connect for free, since the subscription will be
          paid by your employer. If you leave your employer&apos;s subscription,
          you&apos;ll lose free access to Connect.
        </TextBody>
      ),
    }),
    whatDoesItMeanForMyEmployerToHaveAdministrativeControlOverMyFiles: () => ({
      id: 'what-does-it-mean-for-my-employer-to-have-administrative-control-over-my-files',
      title:
        'What does it mean for my employer to have "administrative control" over my files?',
      body: (
        <TextBody>
          If you activate your enterprise account, you can still organize, edit,
          and move files as you like. You can even create a private account and
          transfer files there.
          <br />
          Since your employer owns the Connect Enterprise account, they can
          request deletion of your content and account. However, this
          doesn&apos;t mean they can see or read your files.
          <br />
          In terms of compliance with privacy and data protection laws, and to
          the extent the General Data Protection Regulation 2016/679 (GDPR)
          applies, this means that your employer will become a controller of
          processed personal data necessary to provide Connect Enterprise, and
          reMarkable will act as a processor. In other words, your employer will
          be able to determine the purposes and means of the personal data
          processing. To the extent the California Consumer Privacy Act at Cal.
          Civ. Code § 1798.100 et seq. and its implementing regulations (CCPA)
          apply, your employer will act as the business and reMarkable as the
          service provider. Terms such as “process/processing,” “controller,”
          “processor,” “business,” “service provider,” and “personal data” have
          the same meaning depending on what law applies.
        </TextBody>
      ),
    }),
  },
  enterpriseEnrollment: {
    canITestSSOSolution: () => ({
      id: 'enterpriseEnrollment.can-i-test-sso-solution',
      title:
        'Can I test your SSO solution with a small group of users before rolling it out to everyone?',
      body: (
        <TextBody>
          Yes, you can invite a small group of users to test before applying our
          SSO solution company-wide. These users need to be added in your
          identity provider (IdP) first. The testing phase helps ensure that
          everything is set up correctly for your company.
        </TextBody>
      ),
    }),
    isThereAutomatedProcessForInvitingUsers: () => ({
      id: 'enterpriseEnrollment.is-there-automated-process-for-inviting-users',
      title: 'Is there an automated process for inviting users?',
      body: (
        <TextBody>
          You can manually invite a small group of users for testing by adding
          them to your identity provider. Or, you can start the migration phase,
          which will automatically include all existing users without needing
          individual invitations.
        </TextBody>
      ),
    }),
    whatHappensDuringMigrationPhase: () => ({
      id: 'enterpriseEnrollment.what-happens-during-migration-phase',
      title: 'What happens during the migration phase?',
      body: (
        <TextBody>
          The migration phase marks all users with your domain as claimed.
          They&apos;ll get an email about the migration, and a banner appears on
          their accounts asking them to either migrate or update their email
          address. Users can still log in with their old credentials while
          completing the transition.
        </TextBody>
      ),
    }),
    willUsersStillUseOldCredentials: () => ({
      id: 'enterpriseEnrollment.will-users-still-use-old-credentials',
      title:
        'Will users still be able to use their old credentials once the migration is done?',
      body: (
        <TextBody>
          No, once the migration is complete and domain forwarding has been
          activated, users will have to log in with single sign-on. Their
          previous login credentials will no longer work.
        </TextBody>
      ),
    }),
    howAreUsersInformedAboutMigration: () => ({
      id: 'enterpriseEnrollment.how-are-users-informed-about-migration',
      title: 'How are users informed about the migration process?',
      body: (
        <TextBody>
          They&apos;ll receive an email that explains how the migration process
          works and what to do.
        </TextBody>
      ),
    }),
    whatHappensToAccountsNotUsingDomain: () => ({
      id: 'enterpriseEnrollment.what-happens-to-accounts-not-using-domain',
      title: "What happens to accounts that don't use our domain?",
      body: (
        <TextBody>
          Accounts that aren&apos;t registered on your domain won&apos;t be
          affected by the migration or domain forwarding. These users can keep
          using their current credentials to log in.
        </TextBody>
      ),
    }),
    whatHappensToFilesWhenActivatingEnterpriseAccount: () => ({
      id: 'enterpriseEnrollment.what-happens-to-files-when-activating-enterprise-account',
      title:
        "What happens to an employee's files when they activate their enterprise account?",
      body: (
        <TextBody>
          No changes are made to the content of their files. Once an employee
          activates their enterprise account, administrative control of their
          account will transfer to you as their employer. You&apos;ll then have
          the ability to manage their account, including deleting it if
          necessary, which will erase all their files. However, you won&apos;t
          be able to see or access the content of their files.
        </TextBody>
      ),
    }),
    whatHappensIfEmployeeHasFreeTrial: () => ({
      id: 'enterpriseEnrollment.what-happens-if-employee-has-free-trial',
      title: 'What happens if an employee has a free Connect trial?',
      body: (
        <TextBody>
          Employees keep their free trials. Once the trial ends, your
          organization will handle payment for their subscription.
        </TextBody>
      ),
    }),
    whatHappensToFreeAccessIfJoinSubscription: () => ({
      id: 'enterpriseEnrollment.what-happens-to-free-access-if-join-subscription',
      title:
        "What happens to an employee's free access to Connect if they join my subscription?",
      body: (
        <TextBody>
          Employees who already have free access to Connect will continue to
          enjoy this benefit under your organization&apos;s subscription, as the
          subscription will now be covered by your company. If they leave your
          subscription, they&apos;ll lose their free access to Connect.
        </TextBody>
      ),
    }),
  },
} as const satisfies FAQConfig;
