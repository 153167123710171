/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as PublicImport } from './routes/_public'
import { Route as AuthImport } from './routes/_auth'
import { Route as PublicTestDataImport } from './routes/_public/test-data'
import { Route as PublicSamlErrorImport } from './routes/_public/saml-error'
import { Route as PublicLogoutImport } from './routes/_public/logout'
import { Route as PublicLoginImport } from './routes/_public/login'
import { Route as PublicAuthenticationErrorImport } from './routes/_public/authentication-error'
import { Route as PublicAccessDeniedImport } from './routes/_public/access-denied'
import { Route as PublicLayoutImport } from './routes/_public/_layout'
import { Route as AuthOnlyHeaderImport } from './routes/_auth/_only-header'
import { Route as AuthLayoutImport } from './routes/_auth/_layout'
import { Route as redirectsSettingsImport } from './routes/(redirects)/settings'
import { Route as redirectsFleetImport } from './routes/(redirects)/fleet'
import { Route as redirectsConnectForBusinessImport } from './routes/(redirects)/connect-for-business'
import { Route as redirectsCheckoutImport } from './routes/(redirects)/checkout'
import { Route as AuthLayoutIndexImport } from './routes/_auth/_layout/index'
import { Route as PublicLandingPageDeviceSetupImport } from './routes/_public/landing-page.device-setup'
import { Route as PublicDownloadsMobileAppImport } from './routes/_public/downloads.mobile-app'
import { Route as AuthPairAppImport } from './routes/_auth/pair.app'
import { Route as AuthOnlyHeaderVerifyNewEmailImport } from './routes/_auth/_only-header/verify-new-email'
import { Route as AuthLayoutSubscriptionsImport } from './routes/_auth/_layout/subscriptions'
import { Route as AuthLayoutStoreImport } from './routes/_auth/_layout/store'
import { Route as AuthLayoutScreenshareImport } from './routes/_auth/_layout/screenshare'
import { Route as AuthLayoutMyfilesImport } from './routes/_auth/_layout/myfiles'
import { Route as AuthLayoutIntegrationsImport } from './routes/_auth/_layout/integrations'
import { Route as AuthLayoutEnterpriseImport } from './routes/_auth/_layout/enterprise'
import { Route as AuthLayoutDeviceImport } from './routes/_auth/_layout/device'
import { Route as AuthLayoutDataAccessConsentImport } from './routes/_auth/_layout/data-access-consent'
import { Route as AuthLayoutAccountImport } from './routes/_auth/_layout/account'
import { Route as AuthnoLayoutVerifiedImport } from './routes/_auth/(no-layout)/verified'
import { Route as redirectsFleetMembersImport } from './routes/(redirects)/fleet.members'
import { Route as AuthLayoutSubscriptionsIndexImport } from './routes/_auth/_layout/subscriptions/index'
import { Route as AuthLayoutStoreIndexImport } from './routes/_auth/_layout/store/index'
import { Route as AuthLayoutPairIndexImport } from './routes/_auth/_layout/pair/index'
import { Route as AuthLayoutIntegrationsIndexImport } from './routes/_auth/_layout/integrations.index'
import { Route as AuthLayoutEnterpriseIndexImport } from './routes/_auth/_layout/enterprise/index'
import { Route as AuthLayoutDeviceIndexImport } from './routes/_auth/_layout/device/index'
import { Route as AuthLayoutAccountIndexImport } from './routes/_auth/_layout/account/index'
import { Route as AuthLayoutSubscriptionsPlanImport } from './routes/_auth/_layout/subscriptions/plan'
import { Route as AuthLayoutSubscriptionsPaymentImport } from './routes/_auth/_layout/subscriptions/payment'
import { Route as AuthLayoutSubscriptionsMembersImport } from './routes/_auth/_layout/subscriptions/members'
import { Route as AuthLayoutStoreMethodsOfferImport } from './routes/_auth/_layout/store/methods-offer'
import { Route as AuthLayoutStoreDmOfferImport } from './routes/_auth/_layout/store/dm-offer'
import { Route as AuthLayoutStoreConnectOfferImport } from './routes/_auth/_layout/store/connect-offer'
import { Route as AuthLayoutInvitationSeatImport } from './routes/_auth/_layout/invitation/seat'
import { Route as AuthLayoutIntegrationsCallbackImport } from './routes/_auth/_layout/integrations.callback'
import { Route as AuthLayoutEnterpriseEnrollImport } from './routes/_auth/_layout/enterprise/enroll'
import { Route as AuthLayoutEnterpriseWithOrgImport } from './routes/_auth/_layout/enterprise/_with-org'
import { Route as AuthLayoutDeviceRemarkableImport } from './routes/_auth/_layout/device/remarkable'
import { Route as AuthLayoutDeviceMobileImport } from './routes/_auth/_layout/device/mobile'
import { Route as AuthLayoutDeviceDesktopImport } from './routes/_auth/_layout/device/desktop'
import { Route as AuthLayoutDeviceBrowserImport } from './routes/_auth/_layout/device/browser'
import { Route as AuthLayoutAccountDeleteImport } from './routes/_auth/_layout/account/delete'
import { Route as AuthnoLayoutAccountDeleteImport } from './routes/_auth/(no-layout)/account/delete'
import { Route as AuthLayoutEnterpriseEnrollIndexImport } from './routes/_auth/_layout/enterprise/enroll/index'
import { Route as AuthLayoutAccountMigrateIndexImport } from './routes/_auth/_layout/account/migrate.index'
import { Route as AuthLayoutAccountDeleteIndexImport } from './routes/_auth/_layout/account/delete.index'
import { Route as PublicLayoutAccountMigrateSamlImport } from './routes/_public/_layout/account.migrate.saml'
import { Route as AuthLayoutStoreMethodsOfferSetupCompleteImport } from './routes/_auth/_layout/store/methods-offer.setup-complete'
import { Route as AuthLayoutStoreMethodsOfferDownloadAppsImport } from './routes/_auth/_layout/store/methods-offer.download-apps'
import { Route as AuthLayoutStoreMethodsOfferConfirmationImport } from './routes/_auth/_layout/store/methods-offer.confirmation'
import { Route as AuthLayoutStoreMethodsOfferAllowedImport } from './routes/_auth/_layout/store/methods-offer._allowed'
import { Route as AuthLayoutStoreDmOfferSetupCompleteImport } from './routes/_auth/_layout/store/dm-offer.setup-complete'
import { Route as AuthLayoutStoreDmOfferConfirmationImport } from './routes/_auth/_layout/store/dm-offer.confirmation'
import { Route as AuthLayoutStoreDmOfferAllowedImport } from './routes/_auth/_layout/store/dm-offer._allowed'
import { Route as AuthLayoutStoreConnectConfirmationImport } from './routes/_auth/_layout/store/connect.confirmation'
import { Route as AuthLayoutStoreConnectAllowedImport } from './routes/_auth/_layout/store/connect._allowed'
import { Route as AuthLayoutStoreConnectOfferSetupCompleteImport } from './routes/_auth/_layout/store/connect-offer.setup-complete'
import { Route as AuthLayoutStoreConnectOfferDownloadAppsImport } from './routes/_auth/_layout/store/connect-offer.download-apps'
import { Route as AuthLayoutStoreConnectOfferConfirmationImport } from './routes/_auth/_layout/store/connect-offer.confirmation'
import { Route as AuthLayoutStoreConnectOfferAllowedImport } from './routes/_auth/_layout/store/connect-offer._allowed'
import { Route as AuthLayoutEnterpriseEnrollVerifySamlImport } from './routes/_auth/_layout/enterprise/enroll/verify-saml'
import { Route as AuthLayoutEnterpriseEnrollVerifyDomainImport } from './routes/_auth/_layout/enterprise/enroll/verify-domain'
import { Route as AuthLayoutEnterpriseEnrollSetupSamlImport } from './routes/_auth/_layout/enterprise/enroll/setup-saml'
import { Route as AuthLayoutEnterpriseEnrollSetupImport } from './routes/_auth/_layout/enterprise/enroll/setup'
import { Route as AuthLayoutEnterpriseEnrollCreateOrganizationImport } from './routes/_auth/_layout/enterprise/enroll/create-organization'
import { Route as AuthLayoutEnterpriseEnrollConfirmSetupImport } from './routes/_auth/_layout/enterprise/enroll/confirm-setup'
import { Route as AuthLayoutEnterpriseWithOrgLayoutImport } from './routes/_auth/_layout/enterprise/_with-org/_layout'
import { Route as AuthLayoutDeviceRemarkableConnectImport } from './routes/_auth/_layout/device/remarkable_.connect'
import { Route as AuthLayoutDeviceMobileConnectImport } from './routes/_auth/_layout/device/mobile_.connect'
import { Route as AuthLayoutDeviceDesktopConnectImport } from './routes/_auth/_layout/device/desktop_.connect'
import { Route as AuthLayoutDeviceBrowserConnectImport } from './routes/_auth/_layout/device/browser_.connect'
import { Route as AuthLayoutAccountMigrateSuccessImport } from './routes/_auth/_layout/account/migrate.success'
import { Route as AuthLayoutAccountDeleteLandingImport } from './routes/_auth/_layout/account/delete.landing'
import { Route as AuthLayoutAccountDeleteConfirmationImport } from './routes/_auth/_layout/account/delete.confirmation'
import { Route as AuthLayoutStoreMethodsOfferAllowedIndexImport } from './routes/_auth/_layout/store/methods-offer._allowed.index'
import { Route as AuthLayoutStoreDmOfferAllowedIndexImport } from './routes/_auth/_layout/store/dm-offer._allowed.index'
import { Route as AuthLayoutStoreConnectAllowedIndexImport } from './routes/_auth/_layout/store/connect._allowed.index'
import { Route as AuthLayoutStoreConnectOfferAllowedIndexImport } from './routes/_auth/_layout/store/connect-offer._allowed.index'
import { Route as AuthLayoutStoreMethodsOfferAllowedCheckoutImport } from './routes/_auth/_layout/store/methods-offer._allowed.checkout'
import { Route as AuthLayoutStoreDmOfferAllowedCheckoutImport } from './routes/_auth/_layout/store/dm-offer._allowed.checkout'
import { Route as AuthLayoutStoreConnectAllowedCheckoutImport } from './routes/_auth/_layout/store/connect._allowed.checkout'
import { Route as AuthLayoutStoreConnectOfferAllowedCheckoutImport } from './routes/_auth/_layout/store/connect-offer._allowed.checkout'
import { Route as AuthLayoutEnterpriseWithOrgLayoutSettingsImport } from './routes/_auth/_layout/enterprise/_with-org/_layout.settings'
import { Route as AuthLayoutEnterpriseWithOrgLayoutPlanImport } from './routes/_auth/_layout/enterprise/_with-org/_layout.plan'
import { Route as AuthLayoutEnterpriseWithOrgLayoutMembersImport } from './routes/_auth/_layout/enterprise/_with-org/_layout.members'
import { Route as AuthnoLayoutAccountDeleteLandingAppImport } from './routes/_auth/(no-layout)/account/delete/landing.app'
import { Route as AuthnoLayoutAccountDeleteConfirmationAppImport } from './routes/_auth/(no-layout)/account/delete/confirmation.app'

// Create Virtual Routes

const AuthLayoutStoreConnectImport = createFileRoute(
  '/_auth/_layout/store/connect',
)()
const AuthLayoutMyfilesSplatLazyImport = createFileRoute(
  '/_auth/_layout/myfiles/$',
)()

// Create/Update Routes

const PublicRoute = PublicImport.update({
  id: '/_public',
  getParentRoute: () => rootRoute,
} as any)

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const PublicTestDataRoute = PublicTestDataImport.update({
  id: '/test-data',
  path: '/test-data',
  getParentRoute: () => PublicRoute,
} as any)

const PublicSamlErrorRoute = PublicSamlErrorImport.update({
  id: '/saml-error',
  path: '/saml-error',
  getParentRoute: () => PublicRoute,
} as any)

const PublicLogoutRoute = PublicLogoutImport.update({
  id: '/logout',
  path: '/logout',
  getParentRoute: () => PublicRoute,
} as any)

const PublicLoginRoute = PublicLoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => PublicRoute,
} as any)

const PublicAuthenticationErrorRoute = PublicAuthenticationErrorImport.update({
  id: '/authentication-error',
  path: '/authentication-error',
  getParentRoute: () => PublicRoute,
} as any)

const PublicAccessDeniedRoute = PublicAccessDeniedImport.update({
  id: '/access-denied',
  path: '/access-denied',
  getParentRoute: () => PublicRoute,
} as any)

const PublicLayoutRoute = PublicLayoutImport.update({
  id: '/_layout',
  getParentRoute: () => PublicRoute,
} as any)

const AuthOnlyHeaderRoute = AuthOnlyHeaderImport.update({
  id: '/_only-header',
  getParentRoute: () => AuthRoute,
} as any)

const AuthLayoutRoute = AuthLayoutImport.update({
  id: '/_layout',
  getParentRoute: () => AuthRoute,
} as any)

const redirectsSettingsRoute = redirectsSettingsImport.update({
  id: '/(redirects)/settings',
  path: '/settings',
  getParentRoute: () => rootRoute,
} as any)

const redirectsFleetRoute = redirectsFleetImport.update({
  id: '/(redirects)/fleet',
  path: '/fleet',
  getParentRoute: () => rootRoute,
} as any)

const redirectsConnectForBusinessRoute =
  redirectsConnectForBusinessImport.update({
    id: '/(redirects)/connect-for-business',
    path: '/connect-for-business',
    getParentRoute: () => rootRoute,
  } as any)

const redirectsCheckoutRoute = redirectsCheckoutImport.update({
  id: '/(redirects)/checkout',
  path: '/checkout',
  getParentRoute: () => rootRoute,
} as any)

const AuthLayoutIndexRoute = AuthLayoutIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthLayoutRoute,
} as any)

const PublicLandingPageDeviceSetupRoute =
  PublicLandingPageDeviceSetupImport.update({
    id: '/landing-page/device-setup',
    path: '/landing-page/device-setup',
    getParentRoute: () => PublicRoute,
  } as any)

const PublicDownloadsMobileAppRoute = PublicDownloadsMobileAppImport.update({
  id: '/downloads/mobile-app',
  path: '/downloads/mobile-app',
  getParentRoute: () => PublicRoute,
} as any)

const AuthPairAppRoute = AuthPairAppImport.update({
  id: '/pair/app',
  path: '/pair/app',
  getParentRoute: () => AuthRoute,
} as any)

const AuthOnlyHeaderVerifyNewEmailRoute =
  AuthOnlyHeaderVerifyNewEmailImport.update({
    id: '/verify-new-email',
    path: '/verify-new-email',
    getParentRoute: () => AuthOnlyHeaderRoute,
  } as any)

const AuthLayoutSubscriptionsRoute = AuthLayoutSubscriptionsImport.update({
  id: '/subscriptions',
  path: '/subscriptions',
  getParentRoute: () => AuthLayoutRoute,
} as any)

const AuthLayoutStoreRoute = AuthLayoutStoreImport.update({
  id: '/store',
  path: '/store',
  getParentRoute: () => AuthLayoutRoute,
} as any)

const AuthLayoutScreenshareRoute = AuthLayoutScreenshareImport.update({
  id: '/screenshare',
  path: '/screenshare',
  getParentRoute: () => AuthLayoutRoute,
} as any).lazy(() =>
  import('./routes/_auth/_layout/screenshare.lazy').then((d) => d.Route),
)

const AuthLayoutMyfilesRoute = AuthLayoutMyfilesImport.update({
  id: '/myfiles',
  path: '/myfiles',
  getParentRoute: () => AuthLayoutRoute,
} as any)

const AuthLayoutIntegrationsRoute = AuthLayoutIntegrationsImport.update({
  id: '/integrations',
  path: '/integrations',
  getParentRoute: () => AuthLayoutRoute,
} as any)

const AuthLayoutEnterpriseRoute = AuthLayoutEnterpriseImport.update({
  id: '/enterprise',
  path: '/enterprise',
  getParentRoute: () => AuthLayoutRoute,
} as any)

const AuthLayoutDeviceRoute = AuthLayoutDeviceImport.update({
  id: '/device',
  path: '/device',
  getParentRoute: () => AuthLayoutRoute,
} as any)

const AuthLayoutDataAccessConsentRoute =
  AuthLayoutDataAccessConsentImport.update({
    id: '/data-access-consent',
    path: '/data-access-consent',
    getParentRoute: () => AuthLayoutRoute,
  } as any)

const AuthLayoutAccountRoute = AuthLayoutAccountImport.update({
  id: '/account',
  path: '/account',
  getParentRoute: () => AuthLayoutRoute,
} as any)

const AuthnoLayoutVerifiedRoute = AuthnoLayoutVerifiedImport.update({
  id: '/(no-layout)/verified',
  path: '/verified',
  getParentRoute: () => AuthRoute,
} as any)

const redirectsFleetMembersRoute = redirectsFleetMembersImport.update({
  id: '/members',
  path: '/members',
  getParentRoute: () => redirectsFleetRoute,
} as any)

const AuthLayoutStoreConnectRoute = AuthLayoutStoreConnectImport.update({
  id: '/connect',
  path: '/connect',
  getParentRoute: () => AuthLayoutStoreRoute,
} as any)

const AuthLayoutSubscriptionsIndexRoute =
  AuthLayoutSubscriptionsIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AuthLayoutSubscriptionsRoute,
  } as any)

const AuthLayoutStoreIndexRoute = AuthLayoutStoreIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthLayoutStoreRoute,
} as any)

const AuthLayoutPairIndexRoute = AuthLayoutPairIndexImport.update({
  id: '/pair/',
  path: '/pair/',
  getParentRoute: () => AuthLayoutRoute,
} as any)

const AuthLayoutIntegrationsIndexRoute =
  AuthLayoutIntegrationsIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AuthLayoutIntegrationsRoute,
  } as any)

const AuthLayoutEnterpriseIndexRoute = AuthLayoutEnterpriseIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthLayoutEnterpriseRoute,
} as any)

const AuthLayoutDeviceIndexRoute = AuthLayoutDeviceIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthLayoutDeviceRoute,
} as any)

const AuthLayoutAccountIndexRoute = AuthLayoutAccountIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthLayoutAccountRoute,
} as any)

const AuthLayoutMyfilesSplatLazyRoute = AuthLayoutMyfilesSplatLazyImport.update(
  {
    id: '/$',
    path: '/$',
    getParentRoute: () => AuthLayoutMyfilesRoute,
  } as any,
).lazy(() =>
  import('./routes/_auth/_layout/myfiles.$.lazy').then((d) => d.Route),
)

const AuthLayoutSubscriptionsPlanRoute =
  AuthLayoutSubscriptionsPlanImport.update({
    id: '/plan',
    path: '/plan',
    getParentRoute: () => AuthLayoutSubscriptionsRoute,
  } as any)

const AuthLayoutSubscriptionsPaymentRoute =
  AuthLayoutSubscriptionsPaymentImport.update({
    id: '/payment',
    path: '/payment',
    getParentRoute: () => AuthLayoutSubscriptionsRoute,
  } as any)

const AuthLayoutSubscriptionsMembersRoute =
  AuthLayoutSubscriptionsMembersImport.update({
    id: '/members',
    path: '/members',
    getParentRoute: () => AuthLayoutSubscriptionsRoute,
  } as any)

const AuthLayoutStoreMethodsOfferRoute =
  AuthLayoutStoreMethodsOfferImport.update({
    id: '/methods-offer',
    path: '/methods-offer',
    getParentRoute: () => AuthLayoutStoreRoute,
  } as any)

const AuthLayoutStoreDmOfferRoute = AuthLayoutStoreDmOfferImport.update({
  id: '/dm-offer',
  path: '/dm-offer',
  getParentRoute: () => AuthLayoutStoreRoute,
} as any)

const AuthLayoutStoreConnectOfferRoute =
  AuthLayoutStoreConnectOfferImport.update({
    id: '/connect-offer',
    path: '/connect-offer',
    getParentRoute: () => AuthLayoutStoreRoute,
  } as any)

const AuthLayoutInvitationSeatRoute = AuthLayoutInvitationSeatImport.update({
  id: '/invitation/seat',
  path: '/invitation/seat',
  getParentRoute: () => AuthLayoutRoute,
} as any)

const AuthLayoutIntegrationsCallbackRoute =
  AuthLayoutIntegrationsCallbackImport.update({
    id: '/callback',
    path: '/callback',
    getParentRoute: () => AuthLayoutIntegrationsRoute,
  } as any)

const AuthLayoutEnterpriseEnrollRoute = AuthLayoutEnterpriseEnrollImport.update(
  {
    id: '/enroll',
    path: '/enroll',
    getParentRoute: () => AuthLayoutEnterpriseRoute,
  } as any,
)

const AuthLayoutEnterpriseWithOrgRoute =
  AuthLayoutEnterpriseWithOrgImport.update({
    id: '/_with-org',
    getParentRoute: () => AuthLayoutEnterpriseRoute,
  } as any)

const AuthLayoutDeviceRemarkableRoute = AuthLayoutDeviceRemarkableImport.update(
  {
    id: '/remarkable',
    path: '/remarkable',
    getParentRoute: () => AuthLayoutDeviceRoute,
  } as any,
)

const AuthLayoutDeviceMobileRoute = AuthLayoutDeviceMobileImport.update({
  id: '/mobile',
  path: '/mobile',
  getParentRoute: () => AuthLayoutDeviceRoute,
} as any)

const AuthLayoutDeviceDesktopRoute = AuthLayoutDeviceDesktopImport.update({
  id: '/desktop',
  path: '/desktop',
  getParentRoute: () => AuthLayoutDeviceRoute,
} as any)

const AuthLayoutDeviceBrowserRoute = AuthLayoutDeviceBrowserImport.update({
  id: '/browser',
  path: '/browser',
  getParentRoute: () => AuthLayoutDeviceRoute,
} as any)

const AuthLayoutAccountDeleteRoute = AuthLayoutAccountDeleteImport.update({
  id: '/delete',
  path: '/delete',
  getParentRoute: () => AuthLayoutAccountRoute,
} as any)

const AuthnoLayoutAccountDeleteRoute = AuthnoLayoutAccountDeleteImport.update({
  id: '/(no-layout)/account/delete',
  path: '/account/delete',
  getParentRoute: () => AuthRoute,
} as any)

const AuthLayoutEnterpriseEnrollIndexRoute =
  AuthLayoutEnterpriseEnrollIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AuthLayoutEnterpriseEnrollRoute,
  } as any)

const AuthLayoutAccountMigrateIndexRoute =
  AuthLayoutAccountMigrateIndexImport.update({
    id: '/migrate/',
    path: '/migrate/',
    getParentRoute: () => AuthLayoutAccountRoute,
  } as any)

const AuthLayoutAccountDeleteIndexRoute =
  AuthLayoutAccountDeleteIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AuthLayoutAccountDeleteRoute,
  } as any)

const PublicLayoutAccountMigrateSamlRoute =
  PublicLayoutAccountMigrateSamlImport.update({
    id: '/account/migrate/saml',
    path: '/account/migrate/saml',
    getParentRoute: () => PublicLayoutRoute,
  } as any)

const AuthLayoutStoreMethodsOfferSetupCompleteRoute =
  AuthLayoutStoreMethodsOfferSetupCompleteImport.update({
    id: '/setup-complete',
    path: '/setup-complete',
    getParentRoute: () => AuthLayoutStoreMethodsOfferRoute,
  } as any)

const AuthLayoutStoreMethodsOfferDownloadAppsRoute =
  AuthLayoutStoreMethodsOfferDownloadAppsImport.update({
    id: '/download-apps',
    path: '/download-apps',
    getParentRoute: () => AuthLayoutStoreMethodsOfferRoute,
  } as any)

const AuthLayoutStoreMethodsOfferConfirmationRoute =
  AuthLayoutStoreMethodsOfferConfirmationImport.update({
    id: '/confirmation',
    path: '/confirmation',
    getParentRoute: () => AuthLayoutStoreMethodsOfferRoute,
  } as any)

const AuthLayoutStoreMethodsOfferAllowedRoute =
  AuthLayoutStoreMethodsOfferAllowedImport.update({
    id: '/_allowed',
    getParentRoute: () => AuthLayoutStoreMethodsOfferRoute,
  } as any)

const AuthLayoutStoreDmOfferSetupCompleteRoute =
  AuthLayoutStoreDmOfferSetupCompleteImport.update({
    id: '/setup-complete',
    path: '/setup-complete',
    getParentRoute: () => AuthLayoutStoreDmOfferRoute,
  } as any)

const AuthLayoutStoreDmOfferConfirmationRoute =
  AuthLayoutStoreDmOfferConfirmationImport.update({
    id: '/confirmation',
    path: '/confirmation',
    getParentRoute: () => AuthLayoutStoreDmOfferRoute,
  } as any)

const AuthLayoutStoreDmOfferAllowedRoute =
  AuthLayoutStoreDmOfferAllowedImport.update({
    id: '/_allowed',
    getParentRoute: () => AuthLayoutStoreDmOfferRoute,
  } as any)

const AuthLayoutStoreConnectConfirmationRoute =
  AuthLayoutStoreConnectConfirmationImport.update({
    id: '/confirmation',
    path: '/confirmation',
    getParentRoute: () => AuthLayoutStoreConnectRoute,
  } as any)

const AuthLayoutStoreConnectAllowedRoute =
  AuthLayoutStoreConnectAllowedImport.update({
    id: '/_allowed',
    getParentRoute: () => AuthLayoutStoreConnectRoute,
  } as any)

const AuthLayoutStoreConnectOfferSetupCompleteRoute =
  AuthLayoutStoreConnectOfferSetupCompleteImport.update({
    id: '/setup-complete',
    path: '/setup-complete',
    getParentRoute: () => AuthLayoutStoreConnectOfferRoute,
  } as any)

const AuthLayoutStoreConnectOfferDownloadAppsRoute =
  AuthLayoutStoreConnectOfferDownloadAppsImport.update({
    id: '/download-apps',
    path: '/download-apps',
    getParentRoute: () => AuthLayoutStoreConnectOfferRoute,
  } as any)

const AuthLayoutStoreConnectOfferConfirmationRoute =
  AuthLayoutStoreConnectOfferConfirmationImport.update({
    id: '/confirmation',
    path: '/confirmation',
    getParentRoute: () => AuthLayoutStoreConnectOfferRoute,
  } as any)

const AuthLayoutStoreConnectOfferAllowedRoute =
  AuthLayoutStoreConnectOfferAllowedImport.update({
    id: '/_allowed',
    getParentRoute: () => AuthLayoutStoreConnectOfferRoute,
  } as any)

const AuthLayoutEnterpriseEnrollVerifySamlRoute =
  AuthLayoutEnterpriseEnrollVerifySamlImport.update({
    id: '/verify-saml',
    path: '/verify-saml',
    getParentRoute: () => AuthLayoutEnterpriseEnrollRoute,
  } as any)

const AuthLayoutEnterpriseEnrollVerifyDomainRoute =
  AuthLayoutEnterpriseEnrollVerifyDomainImport.update({
    id: '/verify-domain',
    path: '/verify-domain',
    getParentRoute: () => AuthLayoutEnterpriseEnrollRoute,
  } as any)

const AuthLayoutEnterpriseEnrollSetupSamlRoute =
  AuthLayoutEnterpriseEnrollSetupSamlImport.update({
    id: '/setup-saml',
    path: '/setup-saml',
    getParentRoute: () => AuthLayoutEnterpriseEnrollRoute,
  } as any)

const AuthLayoutEnterpriseEnrollSetupRoute =
  AuthLayoutEnterpriseEnrollSetupImport.update({
    id: '/setup',
    path: '/setup',
    getParentRoute: () => AuthLayoutEnterpriseEnrollRoute,
  } as any)

const AuthLayoutEnterpriseEnrollCreateOrganizationRoute =
  AuthLayoutEnterpriseEnrollCreateOrganizationImport.update({
    id: '/create-organization',
    path: '/create-organization',
    getParentRoute: () => AuthLayoutEnterpriseEnrollRoute,
  } as any)

const AuthLayoutEnterpriseEnrollConfirmSetupRoute =
  AuthLayoutEnterpriseEnrollConfirmSetupImport.update({
    id: '/confirm-setup',
    path: '/confirm-setup',
    getParentRoute: () => AuthLayoutEnterpriseEnrollRoute,
  } as any)

const AuthLayoutEnterpriseWithOrgLayoutRoute =
  AuthLayoutEnterpriseWithOrgLayoutImport.update({
    id: '/_layout',
    getParentRoute: () => AuthLayoutEnterpriseWithOrgRoute,
  } as any)

const AuthLayoutDeviceRemarkableConnectRoute =
  AuthLayoutDeviceRemarkableConnectImport.update({
    id: '/remarkable_/connect',
    path: '/remarkable/connect',
    getParentRoute: () => AuthLayoutDeviceRoute,
  } as any)

const AuthLayoutDeviceMobileConnectRoute =
  AuthLayoutDeviceMobileConnectImport.update({
    id: '/mobile_/connect',
    path: '/mobile/connect',
    getParentRoute: () => AuthLayoutDeviceRoute,
  } as any)

const AuthLayoutDeviceDesktopConnectRoute =
  AuthLayoutDeviceDesktopConnectImport.update({
    id: '/desktop_/connect',
    path: '/desktop/connect',
    getParentRoute: () => AuthLayoutDeviceRoute,
  } as any)

const AuthLayoutDeviceBrowserConnectRoute =
  AuthLayoutDeviceBrowserConnectImport.update({
    id: '/browser_/connect',
    path: '/browser/connect',
    getParentRoute: () => AuthLayoutDeviceRoute,
  } as any)

const AuthLayoutAccountMigrateSuccessRoute =
  AuthLayoutAccountMigrateSuccessImport.update({
    id: '/migrate/success',
    path: '/migrate/success',
    getParentRoute: () => AuthLayoutAccountRoute,
  } as any)

const AuthLayoutAccountDeleteLandingRoute =
  AuthLayoutAccountDeleteLandingImport.update({
    id: '/landing',
    path: '/landing',
    getParentRoute: () => AuthLayoutAccountDeleteRoute,
  } as any)

const AuthLayoutAccountDeleteConfirmationRoute =
  AuthLayoutAccountDeleteConfirmationImport.update({
    id: '/confirmation',
    path: '/confirmation',
    getParentRoute: () => AuthLayoutAccountDeleteRoute,
  } as any)

const AuthLayoutStoreMethodsOfferAllowedIndexRoute =
  AuthLayoutStoreMethodsOfferAllowedIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AuthLayoutStoreMethodsOfferAllowedRoute,
  } as any)

const AuthLayoutStoreDmOfferAllowedIndexRoute =
  AuthLayoutStoreDmOfferAllowedIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AuthLayoutStoreDmOfferAllowedRoute,
  } as any)

const AuthLayoutStoreConnectAllowedIndexRoute =
  AuthLayoutStoreConnectAllowedIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AuthLayoutStoreConnectAllowedRoute,
  } as any)

const AuthLayoutStoreConnectOfferAllowedIndexRoute =
  AuthLayoutStoreConnectOfferAllowedIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AuthLayoutStoreConnectOfferAllowedRoute,
  } as any)

const AuthLayoutStoreMethodsOfferAllowedCheckoutRoute =
  AuthLayoutStoreMethodsOfferAllowedCheckoutImport.update({
    id: '/checkout',
    path: '/checkout',
    getParentRoute: () => AuthLayoutStoreMethodsOfferAllowedRoute,
  } as any)

const AuthLayoutStoreDmOfferAllowedCheckoutRoute =
  AuthLayoutStoreDmOfferAllowedCheckoutImport.update({
    id: '/checkout',
    path: '/checkout',
    getParentRoute: () => AuthLayoutStoreDmOfferAllowedRoute,
  } as any)

const AuthLayoutStoreConnectAllowedCheckoutRoute =
  AuthLayoutStoreConnectAllowedCheckoutImport.update({
    id: '/checkout',
    path: '/checkout',
    getParentRoute: () => AuthLayoutStoreConnectAllowedRoute,
  } as any)

const AuthLayoutStoreConnectOfferAllowedCheckoutRoute =
  AuthLayoutStoreConnectOfferAllowedCheckoutImport.update({
    id: '/checkout',
    path: '/checkout',
    getParentRoute: () => AuthLayoutStoreConnectOfferAllowedRoute,
  } as any)

const AuthLayoutEnterpriseWithOrgLayoutSettingsRoute =
  AuthLayoutEnterpriseWithOrgLayoutSettingsImport.update({
    id: '/settings',
    path: '/settings',
    getParentRoute: () => AuthLayoutEnterpriseWithOrgLayoutRoute,
  } as any)

const AuthLayoutEnterpriseWithOrgLayoutPlanRoute =
  AuthLayoutEnterpriseWithOrgLayoutPlanImport.update({
    id: '/plan',
    path: '/plan',
    getParentRoute: () => AuthLayoutEnterpriseWithOrgLayoutRoute,
  } as any)

const AuthLayoutEnterpriseWithOrgLayoutMembersRoute =
  AuthLayoutEnterpriseWithOrgLayoutMembersImport.update({
    id: '/members',
    path: '/members',
    getParentRoute: () => AuthLayoutEnterpriseWithOrgLayoutRoute,
  } as any)

const AuthnoLayoutAccountDeleteLandingAppRoute =
  AuthnoLayoutAccountDeleteLandingAppImport.update({
    id: '/landing/app',
    path: '/landing/app',
    getParentRoute: () => AuthnoLayoutAccountDeleteRoute,
  } as any)

const AuthnoLayoutAccountDeleteConfirmationAppRoute =
  AuthnoLayoutAccountDeleteConfirmationAppImport.update({
    id: '/confirmation/app',
    path: '/confirmation/app',
    getParentRoute: () => AuthnoLayoutAccountDeleteRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/_public': {
      id: '/_public'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof PublicImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/checkout': {
      id: '/(redirects)/checkout'
      path: '/checkout'
      fullPath: '/checkout'
      preLoaderRoute: typeof redirectsCheckoutImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/connect-for-business': {
      id: '/(redirects)/connect-for-business'
      path: '/connect-for-business'
      fullPath: '/connect-for-business'
      preLoaderRoute: typeof redirectsConnectForBusinessImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/fleet': {
      id: '/(redirects)/fleet'
      path: '/fleet'
      fullPath: '/fleet'
      preLoaderRoute: typeof redirectsFleetImport
      parentRoute: typeof rootRoute
    }
    '/(redirects)/settings': {
      id: '/(redirects)/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof redirectsSettingsImport
      parentRoute: typeof rootRoute
    }
    '/_auth/_layout': {
      id: '/_auth/_layout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthLayoutImport
      parentRoute: typeof AuthImport
    }
    '/_auth/_only-header': {
      id: '/_auth/_only-header'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthOnlyHeaderImport
      parentRoute: typeof AuthImport
    }
    '/_public/_layout': {
      id: '/_public/_layout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof PublicLayoutImport
      parentRoute: typeof PublicImport
    }
    '/_public/access-denied': {
      id: '/_public/access-denied'
      path: '/access-denied'
      fullPath: '/access-denied'
      preLoaderRoute: typeof PublicAccessDeniedImport
      parentRoute: typeof PublicImport
    }
    '/_public/authentication-error': {
      id: '/_public/authentication-error'
      path: '/authentication-error'
      fullPath: '/authentication-error'
      preLoaderRoute: typeof PublicAuthenticationErrorImport
      parentRoute: typeof PublicImport
    }
    '/_public/login': {
      id: '/_public/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof PublicLoginImport
      parentRoute: typeof PublicImport
    }
    '/_public/logout': {
      id: '/_public/logout'
      path: '/logout'
      fullPath: '/logout'
      preLoaderRoute: typeof PublicLogoutImport
      parentRoute: typeof PublicImport
    }
    '/_public/saml-error': {
      id: '/_public/saml-error'
      path: '/saml-error'
      fullPath: '/saml-error'
      preLoaderRoute: typeof PublicSamlErrorImport
      parentRoute: typeof PublicImport
    }
    '/_public/test-data': {
      id: '/_public/test-data'
      path: '/test-data'
      fullPath: '/test-data'
      preLoaderRoute: typeof PublicTestDataImport
      parentRoute: typeof PublicImport
    }
    '/(redirects)/fleet/members': {
      id: '/(redirects)/fleet/members'
      path: '/members'
      fullPath: '/fleet/members'
      preLoaderRoute: typeof redirectsFleetMembersImport
      parentRoute: typeof redirectsFleetImport
    }
    '/_auth/(no-layout)/verified': {
      id: '/_auth/(no-layout)/verified'
      path: '/verified'
      fullPath: '/verified'
      preLoaderRoute: typeof AuthnoLayoutVerifiedImport
      parentRoute: typeof AuthImport
    }
    '/_auth/_layout/account': {
      id: '/_auth/_layout/account'
      path: '/account'
      fullPath: '/account'
      preLoaderRoute: typeof AuthLayoutAccountImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_auth/_layout/data-access-consent': {
      id: '/_auth/_layout/data-access-consent'
      path: '/data-access-consent'
      fullPath: '/data-access-consent'
      preLoaderRoute: typeof AuthLayoutDataAccessConsentImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_auth/_layout/device': {
      id: '/_auth/_layout/device'
      path: '/device'
      fullPath: '/device'
      preLoaderRoute: typeof AuthLayoutDeviceImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_auth/_layout/enterprise': {
      id: '/_auth/_layout/enterprise'
      path: '/enterprise'
      fullPath: '/enterprise'
      preLoaderRoute: typeof AuthLayoutEnterpriseImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_auth/_layout/integrations': {
      id: '/_auth/_layout/integrations'
      path: '/integrations'
      fullPath: '/integrations'
      preLoaderRoute: typeof AuthLayoutIntegrationsImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_auth/_layout/myfiles': {
      id: '/_auth/_layout/myfiles'
      path: '/myfiles'
      fullPath: '/myfiles'
      preLoaderRoute: typeof AuthLayoutMyfilesImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_auth/_layout/screenshare': {
      id: '/_auth/_layout/screenshare'
      path: '/screenshare'
      fullPath: '/screenshare'
      preLoaderRoute: typeof AuthLayoutScreenshareImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_auth/_layout/store': {
      id: '/_auth/_layout/store'
      path: '/store'
      fullPath: '/store'
      preLoaderRoute: typeof AuthLayoutStoreImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_auth/_layout/subscriptions': {
      id: '/_auth/_layout/subscriptions'
      path: '/subscriptions'
      fullPath: '/subscriptions'
      preLoaderRoute: typeof AuthLayoutSubscriptionsImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_auth/_only-header/verify-new-email': {
      id: '/_auth/_only-header/verify-new-email'
      path: '/verify-new-email'
      fullPath: '/verify-new-email'
      preLoaderRoute: typeof AuthOnlyHeaderVerifyNewEmailImport
      parentRoute: typeof AuthOnlyHeaderImport
    }
    '/_auth/pair/app': {
      id: '/_auth/pair/app'
      path: '/pair/app'
      fullPath: '/pair/app'
      preLoaderRoute: typeof AuthPairAppImport
      parentRoute: typeof AuthImport
    }
    '/_public/downloads/mobile-app': {
      id: '/_public/downloads/mobile-app'
      path: '/downloads/mobile-app'
      fullPath: '/downloads/mobile-app'
      preLoaderRoute: typeof PublicDownloadsMobileAppImport
      parentRoute: typeof PublicImport
    }
    '/_public/landing-page/device-setup': {
      id: '/_public/landing-page/device-setup'
      path: '/landing-page/device-setup'
      fullPath: '/landing-page/device-setup'
      preLoaderRoute: typeof PublicLandingPageDeviceSetupImport
      parentRoute: typeof PublicImport
    }
    '/_auth/_layout/': {
      id: '/_auth/_layout/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthLayoutIndexImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_auth/(no-layout)/account/delete': {
      id: '/_auth/(no-layout)/account/delete'
      path: '/account/delete'
      fullPath: '/account/delete'
      preLoaderRoute: typeof AuthnoLayoutAccountDeleteImport
      parentRoute: typeof AuthImport
    }
    '/_auth/_layout/account/delete': {
      id: '/_auth/_layout/account/delete'
      path: '/delete'
      fullPath: '/account/delete'
      preLoaderRoute: typeof AuthLayoutAccountDeleteImport
      parentRoute: typeof AuthLayoutAccountImport
    }
    '/_auth/_layout/device/browser': {
      id: '/_auth/_layout/device/browser'
      path: '/browser'
      fullPath: '/device/browser'
      preLoaderRoute: typeof AuthLayoutDeviceBrowserImport
      parentRoute: typeof AuthLayoutDeviceImport
    }
    '/_auth/_layout/device/desktop': {
      id: '/_auth/_layout/device/desktop'
      path: '/desktop'
      fullPath: '/device/desktop'
      preLoaderRoute: typeof AuthLayoutDeviceDesktopImport
      parentRoute: typeof AuthLayoutDeviceImport
    }
    '/_auth/_layout/device/mobile': {
      id: '/_auth/_layout/device/mobile'
      path: '/mobile'
      fullPath: '/device/mobile'
      preLoaderRoute: typeof AuthLayoutDeviceMobileImport
      parentRoute: typeof AuthLayoutDeviceImport
    }
    '/_auth/_layout/device/remarkable': {
      id: '/_auth/_layout/device/remarkable'
      path: '/remarkable'
      fullPath: '/device/remarkable'
      preLoaderRoute: typeof AuthLayoutDeviceRemarkableImport
      parentRoute: typeof AuthLayoutDeviceImport
    }
    '/_auth/_layout/enterprise/_with-org': {
      id: '/_auth/_layout/enterprise/_with-org'
      path: ''
      fullPath: '/enterprise'
      preLoaderRoute: typeof AuthLayoutEnterpriseWithOrgImport
      parentRoute: typeof AuthLayoutEnterpriseImport
    }
    '/_auth/_layout/enterprise/enroll': {
      id: '/_auth/_layout/enterprise/enroll'
      path: '/enroll'
      fullPath: '/enterprise/enroll'
      preLoaderRoute: typeof AuthLayoutEnterpriseEnrollImport
      parentRoute: typeof AuthLayoutEnterpriseImport
    }
    '/_auth/_layout/integrations/callback': {
      id: '/_auth/_layout/integrations/callback'
      path: '/callback'
      fullPath: '/integrations/callback'
      preLoaderRoute: typeof AuthLayoutIntegrationsCallbackImport
      parentRoute: typeof AuthLayoutIntegrationsImport
    }
    '/_auth/_layout/invitation/seat': {
      id: '/_auth/_layout/invitation/seat'
      path: '/invitation/seat'
      fullPath: '/invitation/seat'
      preLoaderRoute: typeof AuthLayoutInvitationSeatImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_auth/_layout/store/connect-offer': {
      id: '/_auth/_layout/store/connect-offer'
      path: '/connect-offer'
      fullPath: '/store/connect-offer'
      preLoaderRoute: typeof AuthLayoutStoreConnectOfferImport
      parentRoute: typeof AuthLayoutStoreImport
    }
    '/_auth/_layout/store/dm-offer': {
      id: '/_auth/_layout/store/dm-offer'
      path: '/dm-offer'
      fullPath: '/store/dm-offer'
      preLoaderRoute: typeof AuthLayoutStoreDmOfferImport
      parentRoute: typeof AuthLayoutStoreImport
    }
    '/_auth/_layout/store/methods-offer': {
      id: '/_auth/_layout/store/methods-offer'
      path: '/methods-offer'
      fullPath: '/store/methods-offer'
      preLoaderRoute: typeof AuthLayoutStoreMethodsOfferImport
      parentRoute: typeof AuthLayoutStoreImport
    }
    '/_auth/_layout/subscriptions/members': {
      id: '/_auth/_layout/subscriptions/members'
      path: '/members'
      fullPath: '/subscriptions/members'
      preLoaderRoute: typeof AuthLayoutSubscriptionsMembersImport
      parentRoute: typeof AuthLayoutSubscriptionsImport
    }
    '/_auth/_layout/subscriptions/payment': {
      id: '/_auth/_layout/subscriptions/payment'
      path: '/payment'
      fullPath: '/subscriptions/payment'
      preLoaderRoute: typeof AuthLayoutSubscriptionsPaymentImport
      parentRoute: typeof AuthLayoutSubscriptionsImport
    }
    '/_auth/_layout/subscriptions/plan': {
      id: '/_auth/_layout/subscriptions/plan'
      path: '/plan'
      fullPath: '/subscriptions/plan'
      preLoaderRoute: typeof AuthLayoutSubscriptionsPlanImport
      parentRoute: typeof AuthLayoutSubscriptionsImport
    }
    '/_auth/_layout/myfiles/$': {
      id: '/_auth/_layout/myfiles/$'
      path: '/$'
      fullPath: '/myfiles/$'
      preLoaderRoute: typeof AuthLayoutMyfilesSplatLazyImport
      parentRoute: typeof AuthLayoutMyfilesImport
    }
    '/_auth/_layout/account/': {
      id: '/_auth/_layout/account/'
      path: '/'
      fullPath: '/account/'
      preLoaderRoute: typeof AuthLayoutAccountIndexImport
      parentRoute: typeof AuthLayoutAccountImport
    }
    '/_auth/_layout/device/': {
      id: '/_auth/_layout/device/'
      path: '/'
      fullPath: '/device/'
      preLoaderRoute: typeof AuthLayoutDeviceIndexImport
      parentRoute: typeof AuthLayoutDeviceImport
    }
    '/_auth/_layout/enterprise/': {
      id: '/_auth/_layout/enterprise/'
      path: '/'
      fullPath: '/enterprise/'
      preLoaderRoute: typeof AuthLayoutEnterpriseIndexImport
      parentRoute: typeof AuthLayoutEnterpriseImport
    }
    '/_auth/_layout/integrations/': {
      id: '/_auth/_layout/integrations/'
      path: '/'
      fullPath: '/integrations/'
      preLoaderRoute: typeof AuthLayoutIntegrationsIndexImport
      parentRoute: typeof AuthLayoutIntegrationsImport
    }
    '/_auth/_layout/pair/': {
      id: '/_auth/_layout/pair/'
      path: '/pair'
      fullPath: '/pair'
      preLoaderRoute: typeof AuthLayoutPairIndexImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_auth/_layout/store/': {
      id: '/_auth/_layout/store/'
      path: '/'
      fullPath: '/store/'
      preLoaderRoute: typeof AuthLayoutStoreIndexImport
      parentRoute: typeof AuthLayoutStoreImport
    }
    '/_auth/_layout/subscriptions/': {
      id: '/_auth/_layout/subscriptions/'
      path: '/'
      fullPath: '/subscriptions/'
      preLoaderRoute: typeof AuthLayoutSubscriptionsIndexImport
      parentRoute: typeof AuthLayoutSubscriptionsImport
    }
    '/_auth/_layout/account/delete/confirmation': {
      id: '/_auth/_layout/account/delete/confirmation'
      path: '/confirmation'
      fullPath: '/account/delete/confirmation'
      preLoaderRoute: typeof AuthLayoutAccountDeleteConfirmationImport
      parentRoute: typeof AuthLayoutAccountDeleteImport
    }
    '/_auth/_layout/account/delete/landing': {
      id: '/_auth/_layout/account/delete/landing'
      path: '/landing'
      fullPath: '/account/delete/landing'
      preLoaderRoute: typeof AuthLayoutAccountDeleteLandingImport
      parentRoute: typeof AuthLayoutAccountDeleteImport
    }
    '/_auth/_layout/account/migrate/success': {
      id: '/_auth/_layout/account/migrate/success'
      path: '/migrate/success'
      fullPath: '/account/migrate/success'
      preLoaderRoute: typeof AuthLayoutAccountMigrateSuccessImport
      parentRoute: typeof AuthLayoutAccountImport
    }
    '/_auth/_layout/device/browser_/connect': {
      id: '/_auth/_layout/device/browser_/connect'
      path: '/browser/connect'
      fullPath: '/device/browser/connect'
      preLoaderRoute: typeof AuthLayoutDeviceBrowserConnectImport
      parentRoute: typeof AuthLayoutDeviceImport
    }
    '/_auth/_layout/device/desktop_/connect': {
      id: '/_auth/_layout/device/desktop_/connect'
      path: '/desktop/connect'
      fullPath: '/device/desktop/connect'
      preLoaderRoute: typeof AuthLayoutDeviceDesktopConnectImport
      parentRoute: typeof AuthLayoutDeviceImport
    }
    '/_auth/_layout/device/mobile_/connect': {
      id: '/_auth/_layout/device/mobile_/connect'
      path: '/mobile/connect'
      fullPath: '/device/mobile/connect'
      preLoaderRoute: typeof AuthLayoutDeviceMobileConnectImport
      parentRoute: typeof AuthLayoutDeviceImport
    }
    '/_auth/_layout/device/remarkable_/connect': {
      id: '/_auth/_layout/device/remarkable_/connect'
      path: '/remarkable/connect'
      fullPath: '/device/remarkable/connect'
      preLoaderRoute: typeof AuthLayoutDeviceRemarkableConnectImport
      parentRoute: typeof AuthLayoutDeviceImport
    }
    '/_auth/_layout/enterprise/_with-org/_layout': {
      id: '/_auth/_layout/enterprise/_with-org/_layout'
      path: ''
      fullPath: '/enterprise'
      preLoaderRoute: typeof AuthLayoutEnterpriseWithOrgLayoutImport
      parentRoute: typeof AuthLayoutEnterpriseWithOrgImport
    }
    '/_auth/_layout/enterprise/enroll/confirm-setup': {
      id: '/_auth/_layout/enterprise/enroll/confirm-setup'
      path: '/confirm-setup'
      fullPath: '/enterprise/enroll/confirm-setup'
      preLoaderRoute: typeof AuthLayoutEnterpriseEnrollConfirmSetupImport
      parentRoute: typeof AuthLayoutEnterpriseEnrollImport
    }
    '/_auth/_layout/enterprise/enroll/create-organization': {
      id: '/_auth/_layout/enterprise/enroll/create-organization'
      path: '/create-organization'
      fullPath: '/enterprise/enroll/create-organization'
      preLoaderRoute: typeof AuthLayoutEnterpriseEnrollCreateOrganizationImport
      parentRoute: typeof AuthLayoutEnterpriseEnrollImport
    }
    '/_auth/_layout/enterprise/enroll/setup': {
      id: '/_auth/_layout/enterprise/enroll/setup'
      path: '/setup'
      fullPath: '/enterprise/enroll/setup'
      preLoaderRoute: typeof AuthLayoutEnterpriseEnrollSetupImport
      parentRoute: typeof AuthLayoutEnterpriseEnrollImport
    }
    '/_auth/_layout/enterprise/enroll/setup-saml': {
      id: '/_auth/_layout/enterprise/enroll/setup-saml'
      path: '/setup-saml'
      fullPath: '/enterprise/enroll/setup-saml'
      preLoaderRoute: typeof AuthLayoutEnterpriseEnrollSetupSamlImport
      parentRoute: typeof AuthLayoutEnterpriseEnrollImport
    }
    '/_auth/_layout/enterprise/enroll/verify-domain': {
      id: '/_auth/_layout/enterprise/enroll/verify-domain'
      path: '/verify-domain'
      fullPath: '/enterprise/enroll/verify-domain'
      preLoaderRoute: typeof AuthLayoutEnterpriseEnrollVerifyDomainImport
      parentRoute: typeof AuthLayoutEnterpriseEnrollImport
    }
    '/_auth/_layout/enterprise/enroll/verify-saml': {
      id: '/_auth/_layout/enterprise/enroll/verify-saml'
      path: '/verify-saml'
      fullPath: '/enterprise/enroll/verify-saml'
      preLoaderRoute: typeof AuthLayoutEnterpriseEnrollVerifySamlImport
      parentRoute: typeof AuthLayoutEnterpriseEnrollImport
    }
    '/_auth/_layout/store/connect-offer/_allowed': {
      id: '/_auth/_layout/store/connect-offer/_allowed'
      path: ''
      fullPath: '/store/connect-offer'
      preLoaderRoute: typeof AuthLayoutStoreConnectOfferAllowedImport
      parentRoute: typeof AuthLayoutStoreConnectOfferImport
    }
    '/_auth/_layout/store/connect-offer/confirmation': {
      id: '/_auth/_layout/store/connect-offer/confirmation'
      path: '/confirmation'
      fullPath: '/store/connect-offer/confirmation'
      preLoaderRoute: typeof AuthLayoutStoreConnectOfferConfirmationImport
      parentRoute: typeof AuthLayoutStoreConnectOfferImport
    }
    '/_auth/_layout/store/connect-offer/download-apps': {
      id: '/_auth/_layout/store/connect-offer/download-apps'
      path: '/download-apps'
      fullPath: '/store/connect-offer/download-apps'
      preLoaderRoute: typeof AuthLayoutStoreConnectOfferDownloadAppsImport
      parentRoute: typeof AuthLayoutStoreConnectOfferImport
    }
    '/_auth/_layout/store/connect-offer/setup-complete': {
      id: '/_auth/_layout/store/connect-offer/setup-complete'
      path: '/setup-complete'
      fullPath: '/store/connect-offer/setup-complete'
      preLoaderRoute: typeof AuthLayoutStoreConnectOfferSetupCompleteImport
      parentRoute: typeof AuthLayoutStoreConnectOfferImport
    }
    '/_auth/_layout/store/connect': {
      id: '/_auth/_layout/store/connect'
      path: '/connect'
      fullPath: '/store/connect'
      preLoaderRoute: typeof AuthLayoutStoreConnectImport
      parentRoute: typeof AuthLayoutStoreImport
    }
    '/_auth/_layout/store/connect/_allowed': {
      id: '/_auth/_layout/store/connect/_allowed'
      path: '/connect'
      fullPath: '/store/connect'
      preLoaderRoute: typeof AuthLayoutStoreConnectAllowedImport
      parentRoute: typeof AuthLayoutStoreConnectRoute
    }
    '/_auth/_layout/store/connect/confirmation': {
      id: '/_auth/_layout/store/connect/confirmation'
      path: '/confirmation'
      fullPath: '/store/connect/confirmation'
      preLoaderRoute: typeof AuthLayoutStoreConnectConfirmationImport
      parentRoute: typeof AuthLayoutStoreConnectImport
    }
    '/_auth/_layout/store/dm-offer/_allowed': {
      id: '/_auth/_layout/store/dm-offer/_allowed'
      path: ''
      fullPath: '/store/dm-offer'
      preLoaderRoute: typeof AuthLayoutStoreDmOfferAllowedImport
      parentRoute: typeof AuthLayoutStoreDmOfferImport
    }
    '/_auth/_layout/store/dm-offer/confirmation': {
      id: '/_auth/_layout/store/dm-offer/confirmation'
      path: '/confirmation'
      fullPath: '/store/dm-offer/confirmation'
      preLoaderRoute: typeof AuthLayoutStoreDmOfferConfirmationImport
      parentRoute: typeof AuthLayoutStoreDmOfferImport
    }
    '/_auth/_layout/store/dm-offer/setup-complete': {
      id: '/_auth/_layout/store/dm-offer/setup-complete'
      path: '/setup-complete'
      fullPath: '/store/dm-offer/setup-complete'
      preLoaderRoute: typeof AuthLayoutStoreDmOfferSetupCompleteImport
      parentRoute: typeof AuthLayoutStoreDmOfferImport
    }
    '/_auth/_layout/store/methods-offer/_allowed': {
      id: '/_auth/_layout/store/methods-offer/_allowed'
      path: ''
      fullPath: '/store/methods-offer'
      preLoaderRoute: typeof AuthLayoutStoreMethodsOfferAllowedImport
      parentRoute: typeof AuthLayoutStoreMethodsOfferImport
    }
    '/_auth/_layout/store/methods-offer/confirmation': {
      id: '/_auth/_layout/store/methods-offer/confirmation'
      path: '/confirmation'
      fullPath: '/store/methods-offer/confirmation'
      preLoaderRoute: typeof AuthLayoutStoreMethodsOfferConfirmationImport
      parentRoute: typeof AuthLayoutStoreMethodsOfferImport
    }
    '/_auth/_layout/store/methods-offer/download-apps': {
      id: '/_auth/_layout/store/methods-offer/download-apps'
      path: '/download-apps'
      fullPath: '/store/methods-offer/download-apps'
      preLoaderRoute: typeof AuthLayoutStoreMethodsOfferDownloadAppsImport
      parentRoute: typeof AuthLayoutStoreMethodsOfferImport
    }
    '/_auth/_layout/store/methods-offer/setup-complete': {
      id: '/_auth/_layout/store/methods-offer/setup-complete'
      path: '/setup-complete'
      fullPath: '/store/methods-offer/setup-complete'
      preLoaderRoute: typeof AuthLayoutStoreMethodsOfferSetupCompleteImport
      parentRoute: typeof AuthLayoutStoreMethodsOfferImport
    }
    '/_public/_layout/account/migrate/saml': {
      id: '/_public/_layout/account/migrate/saml'
      path: '/account/migrate/saml'
      fullPath: '/account/migrate/saml'
      preLoaderRoute: typeof PublicLayoutAccountMigrateSamlImport
      parentRoute: typeof PublicLayoutImport
    }
    '/_auth/_layout/account/delete/': {
      id: '/_auth/_layout/account/delete/'
      path: '/'
      fullPath: '/account/delete/'
      preLoaderRoute: typeof AuthLayoutAccountDeleteIndexImport
      parentRoute: typeof AuthLayoutAccountDeleteImport
    }
    '/_auth/_layout/account/migrate/': {
      id: '/_auth/_layout/account/migrate/'
      path: '/migrate'
      fullPath: '/account/migrate'
      preLoaderRoute: typeof AuthLayoutAccountMigrateIndexImport
      parentRoute: typeof AuthLayoutAccountImport
    }
    '/_auth/_layout/enterprise/enroll/': {
      id: '/_auth/_layout/enterprise/enroll/'
      path: '/'
      fullPath: '/enterprise/enroll/'
      preLoaderRoute: typeof AuthLayoutEnterpriseEnrollIndexImport
      parentRoute: typeof AuthLayoutEnterpriseEnrollImport
    }
    '/_auth/(no-layout)/account/delete/confirmation/app': {
      id: '/_auth/(no-layout)/account/delete/confirmation/app'
      path: '/confirmation/app'
      fullPath: '/account/delete/confirmation/app'
      preLoaderRoute: typeof AuthnoLayoutAccountDeleteConfirmationAppImport
      parentRoute: typeof AuthnoLayoutAccountDeleteImport
    }
    '/_auth/(no-layout)/account/delete/landing/app': {
      id: '/_auth/(no-layout)/account/delete/landing/app'
      path: '/landing/app'
      fullPath: '/account/delete/landing/app'
      preLoaderRoute: typeof AuthnoLayoutAccountDeleteLandingAppImport
      parentRoute: typeof AuthnoLayoutAccountDeleteImport
    }
    '/_auth/_layout/enterprise/_with-org/_layout/members': {
      id: '/_auth/_layout/enterprise/_with-org/_layout/members'
      path: '/members'
      fullPath: '/enterprise/members'
      preLoaderRoute: typeof AuthLayoutEnterpriseWithOrgLayoutMembersImport
      parentRoute: typeof AuthLayoutEnterpriseWithOrgLayoutImport
    }
    '/_auth/_layout/enterprise/_with-org/_layout/plan': {
      id: '/_auth/_layout/enterprise/_with-org/_layout/plan'
      path: '/plan'
      fullPath: '/enterprise/plan'
      preLoaderRoute: typeof AuthLayoutEnterpriseWithOrgLayoutPlanImport
      parentRoute: typeof AuthLayoutEnterpriseWithOrgLayoutImport
    }
    '/_auth/_layout/enterprise/_with-org/_layout/settings': {
      id: '/_auth/_layout/enterprise/_with-org/_layout/settings'
      path: '/settings'
      fullPath: '/enterprise/settings'
      preLoaderRoute: typeof AuthLayoutEnterpriseWithOrgLayoutSettingsImport
      parentRoute: typeof AuthLayoutEnterpriseWithOrgLayoutImport
    }
    '/_auth/_layout/store/connect-offer/_allowed/checkout': {
      id: '/_auth/_layout/store/connect-offer/_allowed/checkout'
      path: '/checkout'
      fullPath: '/store/connect-offer/checkout'
      preLoaderRoute: typeof AuthLayoutStoreConnectOfferAllowedCheckoutImport
      parentRoute: typeof AuthLayoutStoreConnectOfferAllowedImport
    }
    '/_auth/_layout/store/connect/_allowed/checkout': {
      id: '/_auth/_layout/store/connect/_allowed/checkout'
      path: '/checkout'
      fullPath: '/store/connect/checkout'
      preLoaderRoute: typeof AuthLayoutStoreConnectAllowedCheckoutImport
      parentRoute: typeof AuthLayoutStoreConnectAllowedImport
    }
    '/_auth/_layout/store/dm-offer/_allowed/checkout': {
      id: '/_auth/_layout/store/dm-offer/_allowed/checkout'
      path: '/checkout'
      fullPath: '/store/dm-offer/checkout'
      preLoaderRoute: typeof AuthLayoutStoreDmOfferAllowedCheckoutImport
      parentRoute: typeof AuthLayoutStoreDmOfferAllowedImport
    }
    '/_auth/_layout/store/methods-offer/_allowed/checkout': {
      id: '/_auth/_layout/store/methods-offer/_allowed/checkout'
      path: '/checkout'
      fullPath: '/store/methods-offer/checkout'
      preLoaderRoute: typeof AuthLayoutStoreMethodsOfferAllowedCheckoutImport
      parentRoute: typeof AuthLayoutStoreMethodsOfferAllowedImport
    }
    '/_auth/_layout/store/connect-offer/_allowed/': {
      id: '/_auth/_layout/store/connect-offer/_allowed/'
      path: '/'
      fullPath: '/store/connect-offer/'
      preLoaderRoute: typeof AuthLayoutStoreConnectOfferAllowedIndexImport
      parentRoute: typeof AuthLayoutStoreConnectOfferAllowedImport
    }
    '/_auth/_layout/store/connect/_allowed/': {
      id: '/_auth/_layout/store/connect/_allowed/'
      path: '/'
      fullPath: '/store/connect/'
      preLoaderRoute: typeof AuthLayoutStoreConnectAllowedIndexImport
      parentRoute: typeof AuthLayoutStoreConnectAllowedImport
    }
    '/_auth/_layout/store/dm-offer/_allowed/': {
      id: '/_auth/_layout/store/dm-offer/_allowed/'
      path: '/'
      fullPath: '/store/dm-offer/'
      preLoaderRoute: typeof AuthLayoutStoreDmOfferAllowedIndexImport
      parentRoute: typeof AuthLayoutStoreDmOfferAllowedImport
    }
    '/_auth/_layout/store/methods-offer/_allowed/': {
      id: '/_auth/_layout/store/methods-offer/_allowed/'
      path: '/'
      fullPath: '/store/methods-offer/'
      preLoaderRoute: typeof AuthLayoutStoreMethodsOfferAllowedIndexImport
      parentRoute: typeof AuthLayoutStoreMethodsOfferAllowedImport
    }
  }
}

// Create and export the route tree

interface AuthLayoutAccountDeleteRouteChildren {
  AuthLayoutAccountDeleteConfirmationRoute: typeof AuthLayoutAccountDeleteConfirmationRoute
  AuthLayoutAccountDeleteLandingRoute: typeof AuthLayoutAccountDeleteLandingRoute
  AuthLayoutAccountDeleteIndexRoute: typeof AuthLayoutAccountDeleteIndexRoute
}

const AuthLayoutAccountDeleteRouteChildren: AuthLayoutAccountDeleteRouteChildren =
  {
    AuthLayoutAccountDeleteConfirmationRoute:
      AuthLayoutAccountDeleteConfirmationRoute,
    AuthLayoutAccountDeleteLandingRoute: AuthLayoutAccountDeleteLandingRoute,
    AuthLayoutAccountDeleteIndexRoute: AuthLayoutAccountDeleteIndexRoute,
  }

const AuthLayoutAccountDeleteRouteWithChildren =
  AuthLayoutAccountDeleteRoute._addFileChildren(
    AuthLayoutAccountDeleteRouteChildren,
  )

interface AuthLayoutAccountRouteChildren {
  AuthLayoutAccountDeleteRoute: typeof AuthLayoutAccountDeleteRouteWithChildren
  AuthLayoutAccountIndexRoute: typeof AuthLayoutAccountIndexRoute
  AuthLayoutAccountMigrateSuccessRoute: typeof AuthLayoutAccountMigrateSuccessRoute
  AuthLayoutAccountMigrateIndexRoute: typeof AuthLayoutAccountMigrateIndexRoute
}

const AuthLayoutAccountRouteChildren: AuthLayoutAccountRouteChildren = {
  AuthLayoutAccountDeleteRoute: AuthLayoutAccountDeleteRouteWithChildren,
  AuthLayoutAccountIndexRoute: AuthLayoutAccountIndexRoute,
  AuthLayoutAccountMigrateSuccessRoute: AuthLayoutAccountMigrateSuccessRoute,
  AuthLayoutAccountMigrateIndexRoute: AuthLayoutAccountMigrateIndexRoute,
}

const AuthLayoutAccountRouteWithChildren =
  AuthLayoutAccountRoute._addFileChildren(AuthLayoutAccountRouteChildren)

interface AuthLayoutDeviceRouteChildren {
  AuthLayoutDeviceBrowserRoute: typeof AuthLayoutDeviceBrowserRoute
  AuthLayoutDeviceDesktopRoute: typeof AuthLayoutDeviceDesktopRoute
  AuthLayoutDeviceMobileRoute: typeof AuthLayoutDeviceMobileRoute
  AuthLayoutDeviceRemarkableRoute: typeof AuthLayoutDeviceRemarkableRoute
  AuthLayoutDeviceIndexRoute: typeof AuthLayoutDeviceIndexRoute
  AuthLayoutDeviceBrowserConnectRoute: typeof AuthLayoutDeviceBrowserConnectRoute
  AuthLayoutDeviceDesktopConnectRoute: typeof AuthLayoutDeviceDesktopConnectRoute
  AuthLayoutDeviceMobileConnectRoute: typeof AuthLayoutDeviceMobileConnectRoute
  AuthLayoutDeviceRemarkableConnectRoute: typeof AuthLayoutDeviceRemarkableConnectRoute
}

const AuthLayoutDeviceRouteChildren: AuthLayoutDeviceRouteChildren = {
  AuthLayoutDeviceBrowserRoute: AuthLayoutDeviceBrowserRoute,
  AuthLayoutDeviceDesktopRoute: AuthLayoutDeviceDesktopRoute,
  AuthLayoutDeviceMobileRoute: AuthLayoutDeviceMobileRoute,
  AuthLayoutDeviceRemarkableRoute: AuthLayoutDeviceRemarkableRoute,
  AuthLayoutDeviceIndexRoute: AuthLayoutDeviceIndexRoute,
  AuthLayoutDeviceBrowserConnectRoute: AuthLayoutDeviceBrowserConnectRoute,
  AuthLayoutDeviceDesktopConnectRoute: AuthLayoutDeviceDesktopConnectRoute,
  AuthLayoutDeviceMobileConnectRoute: AuthLayoutDeviceMobileConnectRoute,
  AuthLayoutDeviceRemarkableConnectRoute:
    AuthLayoutDeviceRemarkableConnectRoute,
}

const AuthLayoutDeviceRouteWithChildren =
  AuthLayoutDeviceRoute._addFileChildren(AuthLayoutDeviceRouteChildren)

interface AuthLayoutEnterpriseWithOrgLayoutRouteChildren {
  AuthLayoutEnterpriseWithOrgLayoutMembersRoute: typeof AuthLayoutEnterpriseWithOrgLayoutMembersRoute
  AuthLayoutEnterpriseWithOrgLayoutPlanRoute: typeof AuthLayoutEnterpriseWithOrgLayoutPlanRoute
  AuthLayoutEnterpriseWithOrgLayoutSettingsRoute: typeof AuthLayoutEnterpriseWithOrgLayoutSettingsRoute
}

const AuthLayoutEnterpriseWithOrgLayoutRouteChildren: AuthLayoutEnterpriseWithOrgLayoutRouteChildren =
  {
    AuthLayoutEnterpriseWithOrgLayoutMembersRoute:
      AuthLayoutEnterpriseWithOrgLayoutMembersRoute,
    AuthLayoutEnterpriseWithOrgLayoutPlanRoute:
      AuthLayoutEnterpriseWithOrgLayoutPlanRoute,
    AuthLayoutEnterpriseWithOrgLayoutSettingsRoute:
      AuthLayoutEnterpriseWithOrgLayoutSettingsRoute,
  }

const AuthLayoutEnterpriseWithOrgLayoutRouteWithChildren =
  AuthLayoutEnterpriseWithOrgLayoutRoute._addFileChildren(
    AuthLayoutEnterpriseWithOrgLayoutRouteChildren,
  )

interface AuthLayoutEnterpriseWithOrgRouteChildren {
  AuthLayoutEnterpriseWithOrgLayoutRoute: typeof AuthLayoutEnterpriseWithOrgLayoutRouteWithChildren
}

const AuthLayoutEnterpriseWithOrgRouteChildren: AuthLayoutEnterpriseWithOrgRouteChildren =
  {
    AuthLayoutEnterpriseWithOrgLayoutRoute:
      AuthLayoutEnterpriseWithOrgLayoutRouteWithChildren,
  }

const AuthLayoutEnterpriseWithOrgRouteWithChildren =
  AuthLayoutEnterpriseWithOrgRoute._addFileChildren(
    AuthLayoutEnterpriseWithOrgRouteChildren,
  )

interface AuthLayoutEnterpriseEnrollRouteChildren {
  AuthLayoutEnterpriseEnrollConfirmSetupRoute: typeof AuthLayoutEnterpriseEnrollConfirmSetupRoute
  AuthLayoutEnterpriseEnrollCreateOrganizationRoute: typeof AuthLayoutEnterpriseEnrollCreateOrganizationRoute
  AuthLayoutEnterpriseEnrollSetupRoute: typeof AuthLayoutEnterpriseEnrollSetupRoute
  AuthLayoutEnterpriseEnrollSetupSamlRoute: typeof AuthLayoutEnterpriseEnrollSetupSamlRoute
  AuthLayoutEnterpriseEnrollVerifyDomainRoute: typeof AuthLayoutEnterpriseEnrollVerifyDomainRoute
  AuthLayoutEnterpriseEnrollVerifySamlRoute: typeof AuthLayoutEnterpriseEnrollVerifySamlRoute
  AuthLayoutEnterpriseEnrollIndexRoute: typeof AuthLayoutEnterpriseEnrollIndexRoute
}

const AuthLayoutEnterpriseEnrollRouteChildren: AuthLayoutEnterpriseEnrollRouteChildren =
  {
    AuthLayoutEnterpriseEnrollConfirmSetupRoute:
      AuthLayoutEnterpriseEnrollConfirmSetupRoute,
    AuthLayoutEnterpriseEnrollCreateOrganizationRoute:
      AuthLayoutEnterpriseEnrollCreateOrganizationRoute,
    AuthLayoutEnterpriseEnrollSetupRoute: AuthLayoutEnterpriseEnrollSetupRoute,
    AuthLayoutEnterpriseEnrollSetupSamlRoute:
      AuthLayoutEnterpriseEnrollSetupSamlRoute,
    AuthLayoutEnterpriseEnrollVerifyDomainRoute:
      AuthLayoutEnterpriseEnrollVerifyDomainRoute,
    AuthLayoutEnterpriseEnrollVerifySamlRoute:
      AuthLayoutEnterpriseEnrollVerifySamlRoute,
    AuthLayoutEnterpriseEnrollIndexRoute: AuthLayoutEnterpriseEnrollIndexRoute,
  }

const AuthLayoutEnterpriseEnrollRouteWithChildren =
  AuthLayoutEnterpriseEnrollRoute._addFileChildren(
    AuthLayoutEnterpriseEnrollRouteChildren,
  )

interface AuthLayoutEnterpriseRouteChildren {
  AuthLayoutEnterpriseWithOrgRoute: typeof AuthLayoutEnterpriseWithOrgRouteWithChildren
  AuthLayoutEnterpriseEnrollRoute: typeof AuthLayoutEnterpriseEnrollRouteWithChildren
  AuthLayoutEnterpriseIndexRoute: typeof AuthLayoutEnterpriseIndexRoute
}

const AuthLayoutEnterpriseRouteChildren: AuthLayoutEnterpriseRouteChildren = {
  AuthLayoutEnterpriseWithOrgRoute:
    AuthLayoutEnterpriseWithOrgRouteWithChildren,
  AuthLayoutEnterpriseEnrollRoute: AuthLayoutEnterpriseEnrollRouteWithChildren,
  AuthLayoutEnterpriseIndexRoute: AuthLayoutEnterpriseIndexRoute,
}

const AuthLayoutEnterpriseRouteWithChildren =
  AuthLayoutEnterpriseRoute._addFileChildren(AuthLayoutEnterpriseRouteChildren)

interface AuthLayoutIntegrationsRouteChildren {
  AuthLayoutIntegrationsCallbackRoute: typeof AuthLayoutIntegrationsCallbackRoute
  AuthLayoutIntegrationsIndexRoute: typeof AuthLayoutIntegrationsIndexRoute
}

const AuthLayoutIntegrationsRouteChildren: AuthLayoutIntegrationsRouteChildren =
  {
    AuthLayoutIntegrationsCallbackRoute: AuthLayoutIntegrationsCallbackRoute,
    AuthLayoutIntegrationsIndexRoute: AuthLayoutIntegrationsIndexRoute,
  }

const AuthLayoutIntegrationsRouteWithChildren =
  AuthLayoutIntegrationsRoute._addFileChildren(
    AuthLayoutIntegrationsRouteChildren,
  )

interface AuthLayoutMyfilesRouteChildren {
  AuthLayoutMyfilesSplatLazyRoute: typeof AuthLayoutMyfilesSplatLazyRoute
}

const AuthLayoutMyfilesRouteChildren: AuthLayoutMyfilesRouteChildren = {
  AuthLayoutMyfilesSplatLazyRoute: AuthLayoutMyfilesSplatLazyRoute,
}

const AuthLayoutMyfilesRouteWithChildren =
  AuthLayoutMyfilesRoute._addFileChildren(AuthLayoutMyfilesRouteChildren)

interface AuthLayoutStoreConnectOfferAllowedRouteChildren {
  AuthLayoutStoreConnectOfferAllowedCheckoutRoute: typeof AuthLayoutStoreConnectOfferAllowedCheckoutRoute
  AuthLayoutStoreConnectOfferAllowedIndexRoute: typeof AuthLayoutStoreConnectOfferAllowedIndexRoute
}

const AuthLayoutStoreConnectOfferAllowedRouteChildren: AuthLayoutStoreConnectOfferAllowedRouteChildren =
  {
    AuthLayoutStoreConnectOfferAllowedCheckoutRoute:
      AuthLayoutStoreConnectOfferAllowedCheckoutRoute,
    AuthLayoutStoreConnectOfferAllowedIndexRoute:
      AuthLayoutStoreConnectOfferAllowedIndexRoute,
  }

const AuthLayoutStoreConnectOfferAllowedRouteWithChildren =
  AuthLayoutStoreConnectOfferAllowedRoute._addFileChildren(
    AuthLayoutStoreConnectOfferAllowedRouteChildren,
  )

interface AuthLayoutStoreConnectOfferRouteChildren {
  AuthLayoutStoreConnectOfferAllowedRoute: typeof AuthLayoutStoreConnectOfferAllowedRouteWithChildren
  AuthLayoutStoreConnectOfferConfirmationRoute: typeof AuthLayoutStoreConnectOfferConfirmationRoute
  AuthLayoutStoreConnectOfferDownloadAppsRoute: typeof AuthLayoutStoreConnectOfferDownloadAppsRoute
  AuthLayoutStoreConnectOfferSetupCompleteRoute: typeof AuthLayoutStoreConnectOfferSetupCompleteRoute
}

const AuthLayoutStoreConnectOfferRouteChildren: AuthLayoutStoreConnectOfferRouteChildren =
  {
    AuthLayoutStoreConnectOfferAllowedRoute:
      AuthLayoutStoreConnectOfferAllowedRouteWithChildren,
    AuthLayoutStoreConnectOfferConfirmationRoute:
      AuthLayoutStoreConnectOfferConfirmationRoute,
    AuthLayoutStoreConnectOfferDownloadAppsRoute:
      AuthLayoutStoreConnectOfferDownloadAppsRoute,
    AuthLayoutStoreConnectOfferSetupCompleteRoute:
      AuthLayoutStoreConnectOfferSetupCompleteRoute,
  }

const AuthLayoutStoreConnectOfferRouteWithChildren =
  AuthLayoutStoreConnectOfferRoute._addFileChildren(
    AuthLayoutStoreConnectOfferRouteChildren,
  )

interface AuthLayoutStoreDmOfferAllowedRouteChildren {
  AuthLayoutStoreDmOfferAllowedCheckoutRoute: typeof AuthLayoutStoreDmOfferAllowedCheckoutRoute
  AuthLayoutStoreDmOfferAllowedIndexRoute: typeof AuthLayoutStoreDmOfferAllowedIndexRoute
}

const AuthLayoutStoreDmOfferAllowedRouteChildren: AuthLayoutStoreDmOfferAllowedRouteChildren =
  {
    AuthLayoutStoreDmOfferAllowedCheckoutRoute:
      AuthLayoutStoreDmOfferAllowedCheckoutRoute,
    AuthLayoutStoreDmOfferAllowedIndexRoute:
      AuthLayoutStoreDmOfferAllowedIndexRoute,
  }

const AuthLayoutStoreDmOfferAllowedRouteWithChildren =
  AuthLayoutStoreDmOfferAllowedRoute._addFileChildren(
    AuthLayoutStoreDmOfferAllowedRouteChildren,
  )

interface AuthLayoutStoreDmOfferRouteChildren {
  AuthLayoutStoreDmOfferAllowedRoute: typeof AuthLayoutStoreDmOfferAllowedRouteWithChildren
  AuthLayoutStoreDmOfferConfirmationRoute: typeof AuthLayoutStoreDmOfferConfirmationRoute
  AuthLayoutStoreDmOfferSetupCompleteRoute: typeof AuthLayoutStoreDmOfferSetupCompleteRoute
}

const AuthLayoutStoreDmOfferRouteChildren: AuthLayoutStoreDmOfferRouteChildren =
  {
    AuthLayoutStoreDmOfferAllowedRoute:
      AuthLayoutStoreDmOfferAllowedRouteWithChildren,
    AuthLayoutStoreDmOfferConfirmationRoute:
      AuthLayoutStoreDmOfferConfirmationRoute,
    AuthLayoutStoreDmOfferSetupCompleteRoute:
      AuthLayoutStoreDmOfferSetupCompleteRoute,
  }

const AuthLayoutStoreDmOfferRouteWithChildren =
  AuthLayoutStoreDmOfferRoute._addFileChildren(
    AuthLayoutStoreDmOfferRouteChildren,
  )

interface AuthLayoutStoreMethodsOfferAllowedRouteChildren {
  AuthLayoutStoreMethodsOfferAllowedCheckoutRoute: typeof AuthLayoutStoreMethodsOfferAllowedCheckoutRoute
  AuthLayoutStoreMethodsOfferAllowedIndexRoute: typeof AuthLayoutStoreMethodsOfferAllowedIndexRoute
}

const AuthLayoutStoreMethodsOfferAllowedRouteChildren: AuthLayoutStoreMethodsOfferAllowedRouteChildren =
  {
    AuthLayoutStoreMethodsOfferAllowedCheckoutRoute:
      AuthLayoutStoreMethodsOfferAllowedCheckoutRoute,
    AuthLayoutStoreMethodsOfferAllowedIndexRoute:
      AuthLayoutStoreMethodsOfferAllowedIndexRoute,
  }

const AuthLayoutStoreMethodsOfferAllowedRouteWithChildren =
  AuthLayoutStoreMethodsOfferAllowedRoute._addFileChildren(
    AuthLayoutStoreMethodsOfferAllowedRouteChildren,
  )

interface AuthLayoutStoreMethodsOfferRouteChildren {
  AuthLayoutStoreMethodsOfferAllowedRoute: typeof AuthLayoutStoreMethodsOfferAllowedRouteWithChildren
  AuthLayoutStoreMethodsOfferConfirmationRoute: typeof AuthLayoutStoreMethodsOfferConfirmationRoute
  AuthLayoutStoreMethodsOfferDownloadAppsRoute: typeof AuthLayoutStoreMethodsOfferDownloadAppsRoute
  AuthLayoutStoreMethodsOfferSetupCompleteRoute: typeof AuthLayoutStoreMethodsOfferSetupCompleteRoute
}

const AuthLayoutStoreMethodsOfferRouteChildren: AuthLayoutStoreMethodsOfferRouteChildren =
  {
    AuthLayoutStoreMethodsOfferAllowedRoute:
      AuthLayoutStoreMethodsOfferAllowedRouteWithChildren,
    AuthLayoutStoreMethodsOfferConfirmationRoute:
      AuthLayoutStoreMethodsOfferConfirmationRoute,
    AuthLayoutStoreMethodsOfferDownloadAppsRoute:
      AuthLayoutStoreMethodsOfferDownloadAppsRoute,
    AuthLayoutStoreMethodsOfferSetupCompleteRoute:
      AuthLayoutStoreMethodsOfferSetupCompleteRoute,
  }

const AuthLayoutStoreMethodsOfferRouteWithChildren =
  AuthLayoutStoreMethodsOfferRoute._addFileChildren(
    AuthLayoutStoreMethodsOfferRouteChildren,
  )

interface AuthLayoutStoreConnectAllowedRouteChildren {
  AuthLayoutStoreConnectAllowedCheckoutRoute: typeof AuthLayoutStoreConnectAllowedCheckoutRoute
  AuthLayoutStoreConnectAllowedIndexRoute: typeof AuthLayoutStoreConnectAllowedIndexRoute
}

const AuthLayoutStoreConnectAllowedRouteChildren: AuthLayoutStoreConnectAllowedRouteChildren =
  {
    AuthLayoutStoreConnectAllowedCheckoutRoute:
      AuthLayoutStoreConnectAllowedCheckoutRoute,
    AuthLayoutStoreConnectAllowedIndexRoute:
      AuthLayoutStoreConnectAllowedIndexRoute,
  }

const AuthLayoutStoreConnectAllowedRouteWithChildren =
  AuthLayoutStoreConnectAllowedRoute._addFileChildren(
    AuthLayoutStoreConnectAllowedRouteChildren,
  )

interface AuthLayoutStoreConnectRouteChildren {
  AuthLayoutStoreConnectAllowedRoute: typeof AuthLayoutStoreConnectAllowedRouteWithChildren
  AuthLayoutStoreConnectConfirmationRoute: typeof AuthLayoutStoreConnectConfirmationRoute
}

const AuthLayoutStoreConnectRouteChildren: AuthLayoutStoreConnectRouteChildren =
  {
    AuthLayoutStoreConnectAllowedRoute:
      AuthLayoutStoreConnectAllowedRouteWithChildren,
    AuthLayoutStoreConnectConfirmationRoute:
      AuthLayoutStoreConnectConfirmationRoute,
  }

const AuthLayoutStoreConnectRouteWithChildren =
  AuthLayoutStoreConnectRoute._addFileChildren(
    AuthLayoutStoreConnectRouteChildren,
  )

interface AuthLayoutStoreRouteChildren {
  AuthLayoutStoreConnectOfferRoute: typeof AuthLayoutStoreConnectOfferRouteWithChildren
  AuthLayoutStoreDmOfferRoute: typeof AuthLayoutStoreDmOfferRouteWithChildren
  AuthLayoutStoreMethodsOfferRoute: typeof AuthLayoutStoreMethodsOfferRouteWithChildren
  AuthLayoutStoreIndexRoute: typeof AuthLayoutStoreIndexRoute
  AuthLayoutStoreConnectRoute: typeof AuthLayoutStoreConnectRouteWithChildren
}

const AuthLayoutStoreRouteChildren: AuthLayoutStoreRouteChildren = {
  AuthLayoutStoreConnectOfferRoute:
    AuthLayoutStoreConnectOfferRouteWithChildren,
  AuthLayoutStoreDmOfferRoute: AuthLayoutStoreDmOfferRouteWithChildren,
  AuthLayoutStoreMethodsOfferRoute:
    AuthLayoutStoreMethodsOfferRouteWithChildren,
  AuthLayoutStoreIndexRoute: AuthLayoutStoreIndexRoute,
  AuthLayoutStoreConnectRoute: AuthLayoutStoreConnectRouteWithChildren,
}

const AuthLayoutStoreRouteWithChildren = AuthLayoutStoreRoute._addFileChildren(
  AuthLayoutStoreRouteChildren,
)

interface AuthLayoutSubscriptionsRouteChildren {
  AuthLayoutSubscriptionsMembersRoute: typeof AuthLayoutSubscriptionsMembersRoute
  AuthLayoutSubscriptionsPaymentRoute: typeof AuthLayoutSubscriptionsPaymentRoute
  AuthLayoutSubscriptionsPlanRoute: typeof AuthLayoutSubscriptionsPlanRoute
  AuthLayoutSubscriptionsIndexRoute: typeof AuthLayoutSubscriptionsIndexRoute
}

const AuthLayoutSubscriptionsRouteChildren: AuthLayoutSubscriptionsRouteChildren =
  {
    AuthLayoutSubscriptionsMembersRoute: AuthLayoutSubscriptionsMembersRoute,
    AuthLayoutSubscriptionsPaymentRoute: AuthLayoutSubscriptionsPaymentRoute,
    AuthLayoutSubscriptionsPlanRoute: AuthLayoutSubscriptionsPlanRoute,
    AuthLayoutSubscriptionsIndexRoute: AuthLayoutSubscriptionsIndexRoute,
  }

const AuthLayoutSubscriptionsRouteWithChildren =
  AuthLayoutSubscriptionsRoute._addFileChildren(
    AuthLayoutSubscriptionsRouteChildren,
  )

interface AuthLayoutRouteChildren {
  AuthLayoutAccountRoute: typeof AuthLayoutAccountRouteWithChildren
  AuthLayoutDataAccessConsentRoute: typeof AuthLayoutDataAccessConsentRoute
  AuthLayoutDeviceRoute: typeof AuthLayoutDeviceRouteWithChildren
  AuthLayoutEnterpriseRoute: typeof AuthLayoutEnterpriseRouteWithChildren
  AuthLayoutIntegrationsRoute: typeof AuthLayoutIntegrationsRouteWithChildren
  AuthLayoutMyfilesRoute: typeof AuthLayoutMyfilesRouteWithChildren
  AuthLayoutScreenshareRoute: typeof AuthLayoutScreenshareRoute
  AuthLayoutStoreRoute: typeof AuthLayoutStoreRouteWithChildren
  AuthLayoutSubscriptionsRoute: typeof AuthLayoutSubscriptionsRouteWithChildren
  AuthLayoutIndexRoute: typeof AuthLayoutIndexRoute
  AuthLayoutInvitationSeatRoute: typeof AuthLayoutInvitationSeatRoute
  AuthLayoutPairIndexRoute: typeof AuthLayoutPairIndexRoute
}

const AuthLayoutRouteChildren: AuthLayoutRouteChildren = {
  AuthLayoutAccountRoute: AuthLayoutAccountRouteWithChildren,
  AuthLayoutDataAccessConsentRoute: AuthLayoutDataAccessConsentRoute,
  AuthLayoutDeviceRoute: AuthLayoutDeviceRouteWithChildren,
  AuthLayoutEnterpriseRoute: AuthLayoutEnterpriseRouteWithChildren,
  AuthLayoutIntegrationsRoute: AuthLayoutIntegrationsRouteWithChildren,
  AuthLayoutMyfilesRoute: AuthLayoutMyfilesRouteWithChildren,
  AuthLayoutScreenshareRoute: AuthLayoutScreenshareRoute,
  AuthLayoutStoreRoute: AuthLayoutStoreRouteWithChildren,
  AuthLayoutSubscriptionsRoute: AuthLayoutSubscriptionsRouteWithChildren,
  AuthLayoutIndexRoute: AuthLayoutIndexRoute,
  AuthLayoutInvitationSeatRoute: AuthLayoutInvitationSeatRoute,
  AuthLayoutPairIndexRoute: AuthLayoutPairIndexRoute,
}

const AuthLayoutRouteWithChildren = AuthLayoutRoute._addFileChildren(
  AuthLayoutRouteChildren,
)

interface AuthOnlyHeaderRouteChildren {
  AuthOnlyHeaderVerifyNewEmailRoute: typeof AuthOnlyHeaderVerifyNewEmailRoute
}

const AuthOnlyHeaderRouteChildren: AuthOnlyHeaderRouteChildren = {
  AuthOnlyHeaderVerifyNewEmailRoute: AuthOnlyHeaderVerifyNewEmailRoute,
}

const AuthOnlyHeaderRouteWithChildren = AuthOnlyHeaderRoute._addFileChildren(
  AuthOnlyHeaderRouteChildren,
)

interface AuthnoLayoutAccountDeleteRouteChildren {
  AuthnoLayoutAccountDeleteConfirmationAppRoute: typeof AuthnoLayoutAccountDeleteConfirmationAppRoute
  AuthnoLayoutAccountDeleteLandingAppRoute: typeof AuthnoLayoutAccountDeleteLandingAppRoute
}

const AuthnoLayoutAccountDeleteRouteChildren: AuthnoLayoutAccountDeleteRouteChildren =
  {
    AuthnoLayoutAccountDeleteConfirmationAppRoute:
      AuthnoLayoutAccountDeleteConfirmationAppRoute,
    AuthnoLayoutAccountDeleteLandingAppRoute:
      AuthnoLayoutAccountDeleteLandingAppRoute,
  }

const AuthnoLayoutAccountDeleteRouteWithChildren =
  AuthnoLayoutAccountDeleteRoute._addFileChildren(
    AuthnoLayoutAccountDeleteRouteChildren,
  )

interface AuthRouteChildren {
  AuthLayoutRoute: typeof AuthLayoutRouteWithChildren
  AuthOnlyHeaderRoute: typeof AuthOnlyHeaderRouteWithChildren
  AuthnoLayoutVerifiedRoute: typeof AuthnoLayoutVerifiedRoute
  AuthPairAppRoute: typeof AuthPairAppRoute
  AuthnoLayoutAccountDeleteRoute: typeof AuthnoLayoutAccountDeleteRouteWithChildren
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthLayoutRoute: AuthLayoutRouteWithChildren,
  AuthOnlyHeaderRoute: AuthOnlyHeaderRouteWithChildren,
  AuthnoLayoutVerifiedRoute: AuthnoLayoutVerifiedRoute,
  AuthPairAppRoute: AuthPairAppRoute,
  AuthnoLayoutAccountDeleteRoute: AuthnoLayoutAccountDeleteRouteWithChildren,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

interface PublicLayoutRouteChildren {
  PublicLayoutAccountMigrateSamlRoute: typeof PublicLayoutAccountMigrateSamlRoute
}

const PublicLayoutRouteChildren: PublicLayoutRouteChildren = {
  PublicLayoutAccountMigrateSamlRoute: PublicLayoutAccountMigrateSamlRoute,
}

const PublicLayoutRouteWithChildren = PublicLayoutRoute._addFileChildren(
  PublicLayoutRouteChildren,
)

interface PublicRouteChildren {
  PublicLayoutRoute: typeof PublicLayoutRouteWithChildren
  PublicAccessDeniedRoute: typeof PublicAccessDeniedRoute
  PublicAuthenticationErrorRoute: typeof PublicAuthenticationErrorRoute
  PublicLoginRoute: typeof PublicLoginRoute
  PublicLogoutRoute: typeof PublicLogoutRoute
  PublicSamlErrorRoute: typeof PublicSamlErrorRoute
  PublicTestDataRoute: typeof PublicTestDataRoute
  PublicDownloadsMobileAppRoute: typeof PublicDownloadsMobileAppRoute
  PublicLandingPageDeviceSetupRoute: typeof PublicLandingPageDeviceSetupRoute
}

const PublicRouteChildren: PublicRouteChildren = {
  PublicLayoutRoute: PublicLayoutRouteWithChildren,
  PublicAccessDeniedRoute: PublicAccessDeniedRoute,
  PublicAuthenticationErrorRoute: PublicAuthenticationErrorRoute,
  PublicLoginRoute: PublicLoginRoute,
  PublicLogoutRoute: PublicLogoutRoute,
  PublicSamlErrorRoute: PublicSamlErrorRoute,
  PublicTestDataRoute: PublicTestDataRoute,
  PublicDownloadsMobileAppRoute: PublicDownloadsMobileAppRoute,
  PublicLandingPageDeviceSetupRoute: PublicLandingPageDeviceSetupRoute,
}

const PublicRouteWithChildren =
  PublicRoute._addFileChildren(PublicRouteChildren)

interface redirectsFleetRouteChildren {
  redirectsFleetMembersRoute: typeof redirectsFleetMembersRoute
}

const redirectsFleetRouteChildren: redirectsFleetRouteChildren = {
  redirectsFleetMembersRoute: redirectsFleetMembersRoute,
}

const redirectsFleetRouteWithChildren = redirectsFleetRoute._addFileChildren(
  redirectsFleetRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof PublicLayoutRouteWithChildren
  '/checkout': typeof redirectsCheckoutRoute
  '/connect-for-business': typeof redirectsConnectForBusinessRoute
  '/fleet': typeof redirectsFleetRouteWithChildren
  '/settings': typeof redirectsSettingsRoute
  '/access-denied': typeof PublicAccessDeniedRoute
  '/authentication-error': typeof PublicAuthenticationErrorRoute
  '/login': typeof PublicLoginRoute
  '/logout': typeof PublicLogoutRoute
  '/saml-error': typeof PublicSamlErrorRoute
  '/test-data': typeof PublicTestDataRoute
  '/fleet/members': typeof redirectsFleetMembersRoute
  '/verified': typeof AuthnoLayoutVerifiedRoute
  '/account': typeof AuthLayoutAccountRouteWithChildren
  '/data-access-consent': typeof AuthLayoutDataAccessConsentRoute
  '/device': typeof AuthLayoutDeviceRouteWithChildren
  '/enterprise': typeof AuthLayoutEnterpriseWithOrgLayoutRouteWithChildren
  '/integrations': typeof AuthLayoutIntegrationsRouteWithChildren
  '/myfiles': typeof AuthLayoutMyfilesRouteWithChildren
  '/screenshare': typeof AuthLayoutScreenshareRoute
  '/store': typeof AuthLayoutStoreRouteWithChildren
  '/subscriptions': typeof AuthLayoutSubscriptionsRouteWithChildren
  '/verify-new-email': typeof AuthOnlyHeaderVerifyNewEmailRoute
  '/pair/app': typeof AuthPairAppRoute
  '/downloads/mobile-app': typeof PublicDownloadsMobileAppRoute
  '/landing-page/device-setup': typeof PublicLandingPageDeviceSetupRoute
  '/': typeof AuthLayoutIndexRoute
  '/account/delete': typeof AuthLayoutAccountDeleteRouteWithChildren
  '/device/browser': typeof AuthLayoutDeviceBrowserRoute
  '/device/desktop': typeof AuthLayoutDeviceDesktopRoute
  '/device/mobile': typeof AuthLayoutDeviceMobileRoute
  '/device/remarkable': typeof AuthLayoutDeviceRemarkableRoute
  '/enterprise/enroll': typeof AuthLayoutEnterpriseEnrollRouteWithChildren
  '/integrations/callback': typeof AuthLayoutIntegrationsCallbackRoute
  '/invitation/seat': typeof AuthLayoutInvitationSeatRoute
  '/store/connect-offer': typeof AuthLayoutStoreConnectOfferAllowedRouteWithChildren
  '/store/dm-offer': typeof AuthLayoutStoreDmOfferAllowedRouteWithChildren
  '/store/methods-offer': typeof AuthLayoutStoreMethodsOfferAllowedRouteWithChildren
  '/subscriptions/members': typeof AuthLayoutSubscriptionsMembersRoute
  '/subscriptions/payment': typeof AuthLayoutSubscriptionsPaymentRoute
  '/subscriptions/plan': typeof AuthLayoutSubscriptionsPlanRoute
  '/myfiles/$': typeof AuthLayoutMyfilesSplatLazyRoute
  '/account/': typeof AuthLayoutAccountIndexRoute
  '/device/': typeof AuthLayoutDeviceIndexRoute
  '/enterprise/': typeof AuthLayoutEnterpriseIndexRoute
  '/integrations/': typeof AuthLayoutIntegrationsIndexRoute
  '/pair': typeof AuthLayoutPairIndexRoute
  '/store/': typeof AuthLayoutStoreIndexRoute
  '/subscriptions/': typeof AuthLayoutSubscriptionsIndexRoute
  '/account/delete/confirmation': typeof AuthLayoutAccountDeleteConfirmationRoute
  '/account/delete/landing': typeof AuthLayoutAccountDeleteLandingRoute
  '/account/migrate/success': typeof AuthLayoutAccountMigrateSuccessRoute
  '/device/browser/connect': typeof AuthLayoutDeviceBrowserConnectRoute
  '/device/desktop/connect': typeof AuthLayoutDeviceDesktopConnectRoute
  '/device/mobile/connect': typeof AuthLayoutDeviceMobileConnectRoute
  '/device/remarkable/connect': typeof AuthLayoutDeviceRemarkableConnectRoute
  '/enterprise/enroll/confirm-setup': typeof AuthLayoutEnterpriseEnrollConfirmSetupRoute
  '/enterprise/enroll/create-organization': typeof AuthLayoutEnterpriseEnrollCreateOrganizationRoute
  '/enterprise/enroll/setup': typeof AuthLayoutEnterpriseEnrollSetupRoute
  '/enterprise/enroll/setup-saml': typeof AuthLayoutEnterpriseEnrollSetupSamlRoute
  '/enterprise/enroll/verify-domain': typeof AuthLayoutEnterpriseEnrollVerifyDomainRoute
  '/enterprise/enroll/verify-saml': typeof AuthLayoutEnterpriseEnrollVerifySamlRoute
  '/store/connect-offer/confirmation': typeof AuthLayoutStoreConnectOfferConfirmationRoute
  '/store/connect-offer/download-apps': typeof AuthLayoutStoreConnectOfferDownloadAppsRoute
  '/store/connect-offer/setup-complete': typeof AuthLayoutStoreConnectOfferSetupCompleteRoute
  '/store/connect': typeof AuthLayoutStoreConnectAllowedRouteWithChildren
  '/store/connect/confirmation': typeof AuthLayoutStoreConnectConfirmationRoute
  '/store/dm-offer/confirmation': typeof AuthLayoutStoreDmOfferConfirmationRoute
  '/store/dm-offer/setup-complete': typeof AuthLayoutStoreDmOfferSetupCompleteRoute
  '/store/methods-offer/confirmation': typeof AuthLayoutStoreMethodsOfferConfirmationRoute
  '/store/methods-offer/download-apps': typeof AuthLayoutStoreMethodsOfferDownloadAppsRoute
  '/store/methods-offer/setup-complete': typeof AuthLayoutStoreMethodsOfferSetupCompleteRoute
  '/account/migrate/saml': typeof PublicLayoutAccountMigrateSamlRoute
  '/account/delete/': typeof AuthLayoutAccountDeleteIndexRoute
  '/account/migrate': typeof AuthLayoutAccountMigrateIndexRoute
  '/enterprise/enroll/': typeof AuthLayoutEnterpriseEnrollIndexRoute
  '/account/delete/confirmation/app': typeof AuthnoLayoutAccountDeleteConfirmationAppRoute
  '/account/delete/landing/app': typeof AuthnoLayoutAccountDeleteLandingAppRoute
  '/enterprise/members': typeof AuthLayoutEnterpriseWithOrgLayoutMembersRoute
  '/enterprise/plan': typeof AuthLayoutEnterpriseWithOrgLayoutPlanRoute
  '/enterprise/settings': typeof AuthLayoutEnterpriseWithOrgLayoutSettingsRoute
  '/store/connect-offer/checkout': typeof AuthLayoutStoreConnectOfferAllowedCheckoutRoute
  '/store/connect/checkout': typeof AuthLayoutStoreConnectAllowedCheckoutRoute
  '/store/dm-offer/checkout': typeof AuthLayoutStoreDmOfferAllowedCheckoutRoute
  '/store/methods-offer/checkout': typeof AuthLayoutStoreMethodsOfferAllowedCheckoutRoute
  '/store/connect-offer/': typeof AuthLayoutStoreConnectOfferAllowedIndexRoute
  '/store/connect/': typeof AuthLayoutStoreConnectAllowedIndexRoute
  '/store/dm-offer/': typeof AuthLayoutStoreDmOfferAllowedIndexRoute
  '/store/methods-offer/': typeof AuthLayoutStoreMethodsOfferAllowedIndexRoute
}

export interface FileRoutesByTo {
  '': typeof PublicLayoutRouteWithChildren
  '/checkout': typeof redirectsCheckoutRoute
  '/connect-for-business': typeof redirectsConnectForBusinessRoute
  '/fleet': typeof redirectsFleetRouteWithChildren
  '/settings': typeof redirectsSettingsRoute
  '/access-denied': typeof PublicAccessDeniedRoute
  '/authentication-error': typeof PublicAuthenticationErrorRoute
  '/login': typeof PublicLoginRoute
  '/logout': typeof PublicLogoutRoute
  '/saml-error': typeof PublicSamlErrorRoute
  '/test-data': typeof PublicTestDataRoute
  '/fleet/members': typeof redirectsFleetMembersRoute
  '/verified': typeof AuthnoLayoutVerifiedRoute
  '/data-access-consent': typeof AuthLayoutDataAccessConsentRoute
  '/myfiles': typeof AuthLayoutMyfilesRouteWithChildren
  '/screenshare': typeof AuthLayoutScreenshareRoute
  '/verify-new-email': typeof AuthOnlyHeaderVerifyNewEmailRoute
  '/pair/app': typeof AuthPairAppRoute
  '/downloads/mobile-app': typeof PublicDownloadsMobileAppRoute
  '/landing-page/device-setup': typeof PublicLandingPageDeviceSetupRoute
  '/': typeof AuthLayoutIndexRoute
  '/account/delete': typeof AuthLayoutAccountDeleteIndexRoute
  '/device/browser': typeof AuthLayoutDeviceBrowserRoute
  '/device/desktop': typeof AuthLayoutDeviceDesktopRoute
  '/device/mobile': typeof AuthLayoutDeviceMobileRoute
  '/device/remarkable': typeof AuthLayoutDeviceRemarkableRoute
  '/enterprise': typeof AuthLayoutEnterpriseWithOrgLayoutRouteWithChildren
  '/integrations/callback': typeof AuthLayoutIntegrationsCallbackRoute
  '/invitation/seat': typeof AuthLayoutInvitationSeatRoute
  '/store/connect-offer': typeof AuthLayoutStoreConnectOfferAllowedIndexRoute
  '/store/dm-offer': typeof AuthLayoutStoreDmOfferAllowedIndexRoute
  '/store/methods-offer': typeof AuthLayoutStoreMethodsOfferAllowedIndexRoute
  '/subscriptions/members': typeof AuthLayoutSubscriptionsMembersRoute
  '/subscriptions/payment': typeof AuthLayoutSubscriptionsPaymentRoute
  '/subscriptions/plan': typeof AuthLayoutSubscriptionsPlanRoute
  '/myfiles/$': typeof AuthLayoutMyfilesSplatLazyRoute
  '/account': typeof AuthLayoutAccountIndexRoute
  '/device': typeof AuthLayoutDeviceIndexRoute
  '/integrations': typeof AuthLayoutIntegrationsIndexRoute
  '/pair': typeof AuthLayoutPairIndexRoute
  '/store': typeof AuthLayoutStoreIndexRoute
  '/subscriptions': typeof AuthLayoutSubscriptionsIndexRoute
  '/account/delete/confirmation': typeof AuthLayoutAccountDeleteConfirmationRoute
  '/account/delete/landing': typeof AuthLayoutAccountDeleteLandingRoute
  '/account/migrate/success': typeof AuthLayoutAccountMigrateSuccessRoute
  '/device/browser/connect': typeof AuthLayoutDeviceBrowserConnectRoute
  '/device/desktop/connect': typeof AuthLayoutDeviceDesktopConnectRoute
  '/device/mobile/connect': typeof AuthLayoutDeviceMobileConnectRoute
  '/device/remarkable/connect': typeof AuthLayoutDeviceRemarkableConnectRoute
  '/enterprise/enroll/confirm-setup': typeof AuthLayoutEnterpriseEnrollConfirmSetupRoute
  '/enterprise/enroll/create-organization': typeof AuthLayoutEnterpriseEnrollCreateOrganizationRoute
  '/enterprise/enroll/setup': typeof AuthLayoutEnterpriseEnrollSetupRoute
  '/enterprise/enroll/setup-saml': typeof AuthLayoutEnterpriseEnrollSetupSamlRoute
  '/enterprise/enroll/verify-domain': typeof AuthLayoutEnterpriseEnrollVerifyDomainRoute
  '/enterprise/enroll/verify-saml': typeof AuthLayoutEnterpriseEnrollVerifySamlRoute
  '/store/connect-offer/confirmation': typeof AuthLayoutStoreConnectOfferConfirmationRoute
  '/store/connect-offer/download-apps': typeof AuthLayoutStoreConnectOfferDownloadAppsRoute
  '/store/connect-offer/setup-complete': typeof AuthLayoutStoreConnectOfferSetupCompleteRoute
  '/store/connect': typeof AuthLayoutStoreConnectAllowedIndexRoute
  '/store/connect/confirmation': typeof AuthLayoutStoreConnectConfirmationRoute
  '/store/dm-offer/confirmation': typeof AuthLayoutStoreDmOfferConfirmationRoute
  '/store/dm-offer/setup-complete': typeof AuthLayoutStoreDmOfferSetupCompleteRoute
  '/store/methods-offer/confirmation': typeof AuthLayoutStoreMethodsOfferConfirmationRoute
  '/store/methods-offer/download-apps': typeof AuthLayoutStoreMethodsOfferDownloadAppsRoute
  '/store/methods-offer/setup-complete': typeof AuthLayoutStoreMethodsOfferSetupCompleteRoute
  '/account/migrate/saml': typeof PublicLayoutAccountMigrateSamlRoute
  '/account/migrate': typeof AuthLayoutAccountMigrateIndexRoute
  '/enterprise/enroll': typeof AuthLayoutEnterpriseEnrollIndexRoute
  '/account/delete/confirmation/app': typeof AuthnoLayoutAccountDeleteConfirmationAppRoute
  '/account/delete/landing/app': typeof AuthnoLayoutAccountDeleteLandingAppRoute
  '/enterprise/members': typeof AuthLayoutEnterpriseWithOrgLayoutMembersRoute
  '/enterprise/plan': typeof AuthLayoutEnterpriseWithOrgLayoutPlanRoute
  '/enterprise/settings': typeof AuthLayoutEnterpriseWithOrgLayoutSettingsRoute
  '/store/connect-offer/checkout': typeof AuthLayoutStoreConnectOfferAllowedCheckoutRoute
  '/store/connect/checkout': typeof AuthLayoutStoreConnectAllowedCheckoutRoute
  '/store/dm-offer/checkout': typeof AuthLayoutStoreDmOfferAllowedCheckoutRoute
  '/store/methods-offer/checkout': typeof AuthLayoutStoreMethodsOfferAllowedCheckoutRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_auth': typeof AuthRouteWithChildren
  '/_public': typeof PublicRouteWithChildren
  '/(redirects)/checkout': typeof redirectsCheckoutRoute
  '/(redirects)/connect-for-business': typeof redirectsConnectForBusinessRoute
  '/(redirects)/fleet': typeof redirectsFleetRouteWithChildren
  '/(redirects)/settings': typeof redirectsSettingsRoute
  '/_auth/_layout': typeof AuthLayoutRouteWithChildren
  '/_auth/_only-header': typeof AuthOnlyHeaderRouteWithChildren
  '/_public/_layout': typeof PublicLayoutRouteWithChildren
  '/_public/access-denied': typeof PublicAccessDeniedRoute
  '/_public/authentication-error': typeof PublicAuthenticationErrorRoute
  '/_public/login': typeof PublicLoginRoute
  '/_public/logout': typeof PublicLogoutRoute
  '/_public/saml-error': typeof PublicSamlErrorRoute
  '/_public/test-data': typeof PublicTestDataRoute
  '/(redirects)/fleet/members': typeof redirectsFleetMembersRoute
  '/_auth/(no-layout)/verified': typeof AuthnoLayoutVerifiedRoute
  '/_auth/_layout/account': typeof AuthLayoutAccountRouteWithChildren
  '/_auth/_layout/data-access-consent': typeof AuthLayoutDataAccessConsentRoute
  '/_auth/_layout/device': typeof AuthLayoutDeviceRouteWithChildren
  '/_auth/_layout/enterprise': typeof AuthLayoutEnterpriseRouteWithChildren
  '/_auth/_layout/integrations': typeof AuthLayoutIntegrationsRouteWithChildren
  '/_auth/_layout/myfiles': typeof AuthLayoutMyfilesRouteWithChildren
  '/_auth/_layout/screenshare': typeof AuthLayoutScreenshareRoute
  '/_auth/_layout/store': typeof AuthLayoutStoreRouteWithChildren
  '/_auth/_layout/subscriptions': typeof AuthLayoutSubscriptionsRouteWithChildren
  '/_auth/_only-header/verify-new-email': typeof AuthOnlyHeaderVerifyNewEmailRoute
  '/_auth/pair/app': typeof AuthPairAppRoute
  '/_public/downloads/mobile-app': typeof PublicDownloadsMobileAppRoute
  '/_public/landing-page/device-setup': typeof PublicLandingPageDeviceSetupRoute
  '/_auth/_layout/': typeof AuthLayoutIndexRoute
  '/_auth/(no-layout)/account/delete': typeof AuthnoLayoutAccountDeleteRouteWithChildren
  '/_auth/_layout/account/delete': typeof AuthLayoutAccountDeleteRouteWithChildren
  '/_auth/_layout/device/browser': typeof AuthLayoutDeviceBrowserRoute
  '/_auth/_layout/device/desktop': typeof AuthLayoutDeviceDesktopRoute
  '/_auth/_layout/device/mobile': typeof AuthLayoutDeviceMobileRoute
  '/_auth/_layout/device/remarkable': typeof AuthLayoutDeviceRemarkableRoute
  '/_auth/_layout/enterprise/_with-org': typeof AuthLayoutEnterpriseWithOrgRouteWithChildren
  '/_auth/_layout/enterprise/enroll': typeof AuthLayoutEnterpriseEnrollRouteWithChildren
  '/_auth/_layout/integrations/callback': typeof AuthLayoutIntegrationsCallbackRoute
  '/_auth/_layout/invitation/seat': typeof AuthLayoutInvitationSeatRoute
  '/_auth/_layout/store/connect-offer': typeof AuthLayoutStoreConnectOfferRouteWithChildren
  '/_auth/_layout/store/dm-offer': typeof AuthLayoutStoreDmOfferRouteWithChildren
  '/_auth/_layout/store/methods-offer': typeof AuthLayoutStoreMethodsOfferRouteWithChildren
  '/_auth/_layout/subscriptions/members': typeof AuthLayoutSubscriptionsMembersRoute
  '/_auth/_layout/subscriptions/payment': typeof AuthLayoutSubscriptionsPaymentRoute
  '/_auth/_layout/subscriptions/plan': typeof AuthLayoutSubscriptionsPlanRoute
  '/_auth/_layout/myfiles/$': typeof AuthLayoutMyfilesSplatLazyRoute
  '/_auth/_layout/account/': typeof AuthLayoutAccountIndexRoute
  '/_auth/_layout/device/': typeof AuthLayoutDeviceIndexRoute
  '/_auth/_layout/enterprise/': typeof AuthLayoutEnterpriseIndexRoute
  '/_auth/_layout/integrations/': typeof AuthLayoutIntegrationsIndexRoute
  '/_auth/_layout/pair/': typeof AuthLayoutPairIndexRoute
  '/_auth/_layout/store/': typeof AuthLayoutStoreIndexRoute
  '/_auth/_layout/subscriptions/': typeof AuthLayoutSubscriptionsIndexRoute
  '/_auth/_layout/account/delete/confirmation': typeof AuthLayoutAccountDeleteConfirmationRoute
  '/_auth/_layout/account/delete/landing': typeof AuthLayoutAccountDeleteLandingRoute
  '/_auth/_layout/account/migrate/success': typeof AuthLayoutAccountMigrateSuccessRoute
  '/_auth/_layout/device/browser_/connect': typeof AuthLayoutDeviceBrowserConnectRoute
  '/_auth/_layout/device/desktop_/connect': typeof AuthLayoutDeviceDesktopConnectRoute
  '/_auth/_layout/device/mobile_/connect': typeof AuthLayoutDeviceMobileConnectRoute
  '/_auth/_layout/device/remarkable_/connect': typeof AuthLayoutDeviceRemarkableConnectRoute
  '/_auth/_layout/enterprise/_with-org/_layout': typeof AuthLayoutEnterpriseWithOrgLayoutRouteWithChildren
  '/_auth/_layout/enterprise/enroll/confirm-setup': typeof AuthLayoutEnterpriseEnrollConfirmSetupRoute
  '/_auth/_layout/enterprise/enroll/create-organization': typeof AuthLayoutEnterpriseEnrollCreateOrganizationRoute
  '/_auth/_layout/enterprise/enroll/setup': typeof AuthLayoutEnterpriseEnrollSetupRoute
  '/_auth/_layout/enterprise/enroll/setup-saml': typeof AuthLayoutEnterpriseEnrollSetupSamlRoute
  '/_auth/_layout/enterprise/enroll/verify-domain': typeof AuthLayoutEnterpriseEnrollVerifyDomainRoute
  '/_auth/_layout/enterprise/enroll/verify-saml': typeof AuthLayoutEnterpriseEnrollVerifySamlRoute
  '/_auth/_layout/store/connect-offer/_allowed': typeof AuthLayoutStoreConnectOfferAllowedRouteWithChildren
  '/_auth/_layout/store/connect-offer/confirmation': typeof AuthLayoutStoreConnectOfferConfirmationRoute
  '/_auth/_layout/store/connect-offer/download-apps': typeof AuthLayoutStoreConnectOfferDownloadAppsRoute
  '/_auth/_layout/store/connect-offer/setup-complete': typeof AuthLayoutStoreConnectOfferSetupCompleteRoute
  '/_auth/_layout/store/connect': typeof AuthLayoutStoreConnectRouteWithChildren
  '/_auth/_layout/store/connect/_allowed': typeof AuthLayoutStoreConnectAllowedRouteWithChildren
  '/_auth/_layout/store/connect/confirmation': typeof AuthLayoutStoreConnectConfirmationRoute
  '/_auth/_layout/store/dm-offer/_allowed': typeof AuthLayoutStoreDmOfferAllowedRouteWithChildren
  '/_auth/_layout/store/dm-offer/confirmation': typeof AuthLayoutStoreDmOfferConfirmationRoute
  '/_auth/_layout/store/dm-offer/setup-complete': typeof AuthLayoutStoreDmOfferSetupCompleteRoute
  '/_auth/_layout/store/methods-offer/_allowed': typeof AuthLayoutStoreMethodsOfferAllowedRouteWithChildren
  '/_auth/_layout/store/methods-offer/confirmation': typeof AuthLayoutStoreMethodsOfferConfirmationRoute
  '/_auth/_layout/store/methods-offer/download-apps': typeof AuthLayoutStoreMethodsOfferDownloadAppsRoute
  '/_auth/_layout/store/methods-offer/setup-complete': typeof AuthLayoutStoreMethodsOfferSetupCompleteRoute
  '/_public/_layout/account/migrate/saml': typeof PublicLayoutAccountMigrateSamlRoute
  '/_auth/_layout/account/delete/': typeof AuthLayoutAccountDeleteIndexRoute
  '/_auth/_layout/account/migrate/': typeof AuthLayoutAccountMigrateIndexRoute
  '/_auth/_layout/enterprise/enroll/': typeof AuthLayoutEnterpriseEnrollIndexRoute
  '/_auth/(no-layout)/account/delete/confirmation/app': typeof AuthnoLayoutAccountDeleteConfirmationAppRoute
  '/_auth/(no-layout)/account/delete/landing/app': typeof AuthnoLayoutAccountDeleteLandingAppRoute
  '/_auth/_layout/enterprise/_with-org/_layout/members': typeof AuthLayoutEnterpriseWithOrgLayoutMembersRoute
  '/_auth/_layout/enterprise/_with-org/_layout/plan': typeof AuthLayoutEnterpriseWithOrgLayoutPlanRoute
  '/_auth/_layout/enterprise/_with-org/_layout/settings': typeof AuthLayoutEnterpriseWithOrgLayoutSettingsRoute
  '/_auth/_layout/store/connect-offer/_allowed/checkout': typeof AuthLayoutStoreConnectOfferAllowedCheckoutRoute
  '/_auth/_layout/store/connect/_allowed/checkout': typeof AuthLayoutStoreConnectAllowedCheckoutRoute
  '/_auth/_layout/store/dm-offer/_allowed/checkout': typeof AuthLayoutStoreDmOfferAllowedCheckoutRoute
  '/_auth/_layout/store/methods-offer/_allowed/checkout': typeof AuthLayoutStoreMethodsOfferAllowedCheckoutRoute
  '/_auth/_layout/store/connect-offer/_allowed/': typeof AuthLayoutStoreConnectOfferAllowedIndexRoute
  '/_auth/_layout/store/connect/_allowed/': typeof AuthLayoutStoreConnectAllowedIndexRoute
  '/_auth/_layout/store/dm-offer/_allowed/': typeof AuthLayoutStoreDmOfferAllowedIndexRoute
  '/_auth/_layout/store/methods-offer/_allowed/': typeof AuthLayoutStoreMethodsOfferAllowedIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/checkout'
    | '/connect-for-business'
    | '/fleet'
    | '/settings'
    | '/access-denied'
    | '/authentication-error'
    | '/login'
    | '/logout'
    | '/saml-error'
    | '/test-data'
    | '/fleet/members'
    | '/verified'
    | '/account'
    | '/data-access-consent'
    | '/device'
    | '/enterprise'
    | '/integrations'
    | '/myfiles'
    | '/screenshare'
    | '/store'
    | '/subscriptions'
    | '/verify-new-email'
    | '/pair/app'
    | '/downloads/mobile-app'
    | '/landing-page/device-setup'
    | '/'
    | '/account/delete'
    | '/device/browser'
    | '/device/desktop'
    | '/device/mobile'
    | '/device/remarkable'
    | '/enterprise/enroll'
    | '/integrations/callback'
    | '/invitation/seat'
    | '/store/connect-offer'
    | '/store/dm-offer'
    | '/store/methods-offer'
    | '/subscriptions/members'
    | '/subscriptions/payment'
    | '/subscriptions/plan'
    | '/myfiles/$'
    | '/account/'
    | '/device/'
    | '/enterprise/'
    | '/integrations/'
    | '/pair'
    | '/store/'
    | '/subscriptions/'
    | '/account/delete/confirmation'
    | '/account/delete/landing'
    | '/account/migrate/success'
    | '/device/browser/connect'
    | '/device/desktop/connect'
    | '/device/mobile/connect'
    | '/device/remarkable/connect'
    | '/enterprise/enroll/confirm-setup'
    | '/enterprise/enroll/create-organization'
    | '/enterprise/enroll/setup'
    | '/enterprise/enroll/setup-saml'
    | '/enterprise/enroll/verify-domain'
    | '/enterprise/enroll/verify-saml'
    | '/store/connect-offer/confirmation'
    | '/store/connect-offer/download-apps'
    | '/store/connect-offer/setup-complete'
    | '/store/connect'
    | '/store/connect/confirmation'
    | '/store/dm-offer/confirmation'
    | '/store/dm-offer/setup-complete'
    | '/store/methods-offer/confirmation'
    | '/store/methods-offer/download-apps'
    | '/store/methods-offer/setup-complete'
    | '/account/migrate/saml'
    | '/account/delete/'
    | '/account/migrate'
    | '/enterprise/enroll/'
    | '/account/delete/confirmation/app'
    | '/account/delete/landing/app'
    | '/enterprise/members'
    | '/enterprise/plan'
    | '/enterprise/settings'
    | '/store/connect-offer/checkout'
    | '/store/connect/checkout'
    | '/store/dm-offer/checkout'
    | '/store/methods-offer/checkout'
    | '/store/connect-offer/'
    | '/store/connect/'
    | '/store/dm-offer/'
    | '/store/methods-offer/'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/checkout'
    | '/connect-for-business'
    | '/fleet'
    | '/settings'
    | '/access-denied'
    | '/authentication-error'
    | '/login'
    | '/logout'
    | '/saml-error'
    | '/test-data'
    | '/fleet/members'
    | '/verified'
    | '/data-access-consent'
    | '/myfiles'
    | '/screenshare'
    | '/verify-new-email'
    | '/pair/app'
    | '/downloads/mobile-app'
    | '/landing-page/device-setup'
    | '/'
    | '/account/delete'
    | '/device/browser'
    | '/device/desktop'
    | '/device/mobile'
    | '/device/remarkable'
    | '/enterprise'
    | '/integrations/callback'
    | '/invitation/seat'
    | '/store/connect-offer'
    | '/store/dm-offer'
    | '/store/methods-offer'
    | '/subscriptions/members'
    | '/subscriptions/payment'
    | '/subscriptions/plan'
    | '/myfiles/$'
    | '/account'
    | '/device'
    | '/integrations'
    | '/pair'
    | '/store'
    | '/subscriptions'
    | '/account/delete/confirmation'
    | '/account/delete/landing'
    | '/account/migrate/success'
    | '/device/browser/connect'
    | '/device/desktop/connect'
    | '/device/mobile/connect'
    | '/device/remarkable/connect'
    | '/enterprise/enroll/confirm-setup'
    | '/enterprise/enroll/create-organization'
    | '/enterprise/enroll/setup'
    | '/enterprise/enroll/setup-saml'
    | '/enterprise/enroll/verify-domain'
    | '/enterprise/enroll/verify-saml'
    | '/store/connect-offer/confirmation'
    | '/store/connect-offer/download-apps'
    | '/store/connect-offer/setup-complete'
    | '/store/connect'
    | '/store/connect/confirmation'
    | '/store/dm-offer/confirmation'
    | '/store/dm-offer/setup-complete'
    | '/store/methods-offer/confirmation'
    | '/store/methods-offer/download-apps'
    | '/store/methods-offer/setup-complete'
    | '/account/migrate/saml'
    | '/account/migrate'
    | '/enterprise/enroll'
    | '/account/delete/confirmation/app'
    | '/account/delete/landing/app'
    | '/enterprise/members'
    | '/enterprise/plan'
    | '/enterprise/settings'
    | '/store/connect-offer/checkout'
    | '/store/connect/checkout'
    | '/store/dm-offer/checkout'
    | '/store/methods-offer/checkout'
  id:
    | '__root__'
    | '/_auth'
    | '/_public'
    | '/(redirects)/checkout'
    | '/(redirects)/connect-for-business'
    | '/(redirects)/fleet'
    | '/(redirects)/settings'
    | '/_auth/_layout'
    | '/_auth/_only-header'
    | '/_public/_layout'
    | '/_public/access-denied'
    | '/_public/authentication-error'
    | '/_public/login'
    | '/_public/logout'
    | '/_public/saml-error'
    | '/_public/test-data'
    | '/(redirects)/fleet/members'
    | '/_auth/(no-layout)/verified'
    | '/_auth/_layout/account'
    | '/_auth/_layout/data-access-consent'
    | '/_auth/_layout/device'
    | '/_auth/_layout/enterprise'
    | '/_auth/_layout/integrations'
    | '/_auth/_layout/myfiles'
    | '/_auth/_layout/screenshare'
    | '/_auth/_layout/store'
    | '/_auth/_layout/subscriptions'
    | '/_auth/_only-header/verify-new-email'
    | '/_auth/pair/app'
    | '/_public/downloads/mobile-app'
    | '/_public/landing-page/device-setup'
    | '/_auth/_layout/'
    | '/_auth/(no-layout)/account/delete'
    | '/_auth/_layout/account/delete'
    | '/_auth/_layout/device/browser'
    | '/_auth/_layout/device/desktop'
    | '/_auth/_layout/device/mobile'
    | '/_auth/_layout/device/remarkable'
    | '/_auth/_layout/enterprise/_with-org'
    | '/_auth/_layout/enterprise/enroll'
    | '/_auth/_layout/integrations/callback'
    | '/_auth/_layout/invitation/seat'
    | '/_auth/_layout/store/connect-offer'
    | '/_auth/_layout/store/dm-offer'
    | '/_auth/_layout/store/methods-offer'
    | '/_auth/_layout/subscriptions/members'
    | '/_auth/_layout/subscriptions/payment'
    | '/_auth/_layout/subscriptions/plan'
    | '/_auth/_layout/myfiles/$'
    | '/_auth/_layout/account/'
    | '/_auth/_layout/device/'
    | '/_auth/_layout/enterprise/'
    | '/_auth/_layout/integrations/'
    | '/_auth/_layout/pair/'
    | '/_auth/_layout/store/'
    | '/_auth/_layout/subscriptions/'
    | '/_auth/_layout/account/delete/confirmation'
    | '/_auth/_layout/account/delete/landing'
    | '/_auth/_layout/account/migrate/success'
    | '/_auth/_layout/device/browser_/connect'
    | '/_auth/_layout/device/desktop_/connect'
    | '/_auth/_layout/device/mobile_/connect'
    | '/_auth/_layout/device/remarkable_/connect'
    | '/_auth/_layout/enterprise/_with-org/_layout'
    | '/_auth/_layout/enterprise/enroll/confirm-setup'
    | '/_auth/_layout/enterprise/enroll/create-organization'
    | '/_auth/_layout/enterprise/enroll/setup'
    | '/_auth/_layout/enterprise/enroll/setup-saml'
    | '/_auth/_layout/enterprise/enroll/verify-domain'
    | '/_auth/_layout/enterprise/enroll/verify-saml'
    | '/_auth/_layout/store/connect-offer/_allowed'
    | '/_auth/_layout/store/connect-offer/confirmation'
    | '/_auth/_layout/store/connect-offer/download-apps'
    | '/_auth/_layout/store/connect-offer/setup-complete'
    | '/_auth/_layout/store/connect'
    | '/_auth/_layout/store/connect/_allowed'
    | '/_auth/_layout/store/connect/confirmation'
    | '/_auth/_layout/store/dm-offer/_allowed'
    | '/_auth/_layout/store/dm-offer/confirmation'
    | '/_auth/_layout/store/dm-offer/setup-complete'
    | '/_auth/_layout/store/methods-offer/_allowed'
    | '/_auth/_layout/store/methods-offer/confirmation'
    | '/_auth/_layout/store/methods-offer/download-apps'
    | '/_auth/_layout/store/methods-offer/setup-complete'
    | '/_public/_layout/account/migrate/saml'
    | '/_auth/_layout/account/delete/'
    | '/_auth/_layout/account/migrate/'
    | '/_auth/_layout/enterprise/enroll/'
    | '/_auth/(no-layout)/account/delete/confirmation/app'
    | '/_auth/(no-layout)/account/delete/landing/app'
    | '/_auth/_layout/enterprise/_with-org/_layout/members'
    | '/_auth/_layout/enterprise/_with-org/_layout/plan'
    | '/_auth/_layout/enterprise/_with-org/_layout/settings'
    | '/_auth/_layout/store/connect-offer/_allowed/checkout'
    | '/_auth/_layout/store/connect/_allowed/checkout'
    | '/_auth/_layout/store/dm-offer/_allowed/checkout'
    | '/_auth/_layout/store/methods-offer/_allowed/checkout'
    | '/_auth/_layout/store/connect-offer/_allowed/'
    | '/_auth/_layout/store/connect/_allowed/'
    | '/_auth/_layout/store/dm-offer/_allowed/'
    | '/_auth/_layout/store/methods-offer/_allowed/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthRoute: typeof AuthRouteWithChildren
  PublicRoute: typeof PublicRouteWithChildren
  redirectsCheckoutRoute: typeof redirectsCheckoutRoute
  redirectsConnectForBusinessRoute: typeof redirectsConnectForBusinessRoute
  redirectsFleetRoute: typeof redirectsFleetRouteWithChildren
  redirectsSettingsRoute: typeof redirectsSettingsRoute
}

const rootRouteChildren: RootRouteChildren = {
  AuthRoute: AuthRouteWithChildren,
  PublicRoute: PublicRouteWithChildren,
  redirectsCheckoutRoute: redirectsCheckoutRoute,
  redirectsConnectForBusinessRoute: redirectsConnectForBusinessRoute,
  redirectsFleetRoute: redirectsFleetRouteWithChildren,
  redirectsSettingsRoute: redirectsSettingsRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_auth",
        "/_public",
        "/(redirects)/checkout",
        "/(redirects)/connect-for-business",
        "/(redirects)/fleet",
        "/(redirects)/settings"
      ]
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/_layout",
        "/_auth/_only-header",
        "/_auth/(no-layout)/verified",
        "/_auth/pair/app",
        "/_auth/(no-layout)/account/delete"
      ]
    },
    "/_public": {
      "filePath": "_public.tsx",
      "children": [
        "/_public/_layout",
        "/_public/access-denied",
        "/_public/authentication-error",
        "/_public/login",
        "/_public/logout",
        "/_public/saml-error",
        "/_public/test-data",
        "/_public/downloads/mobile-app",
        "/_public/landing-page/device-setup"
      ]
    },
    "/(redirects)/checkout": {
      "filePath": "(redirects)/checkout.tsx"
    },
    "/(redirects)/connect-for-business": {
      "filePath": "(redirects)/connect-for-business.tsx"
    },
    "/(redirects)/fleet": {
      "filePath": "(redirects)/fleet.tsx",
      "children": [
        "/(redirects)/fleet/members"
      ]
    },
    "/(redirects)/settings": {
      "filePath": "(redirects)/settings.tsx"
    },
    "/_auth/_layout": {
      "filePath": "_auth/_layout.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/_layout/account",
        "/_auth/_layout/data-access-consent",
        "/_auth/_layout/device",
        "/_auth/_layout/enterprise",
        "/_auth/_layout/integrations",
        "/_auth/_layout/myfiles",
        "/_auth/_layout/screenshare",
        "/_auth/_layout/store",
        "/_auth/_layout/subscriptions",
        "/_auth/_layout/",
        "/_auth/_layout/invitation/seat",
        "/_auth/_layout/pair/"
      ]
    },
    "/_auth/_only-header": {
      "filePath": "_auth/_only-header.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/_only-header/verify-new-email"
      ]
    },
    "/_public/_layout": {
      "filePath": "_public/_layout.tsx",
      "parent": "/_public",
      "children": [
        "/_public/_layout/account/migrate/saml"
      ]
    },
    "/_public/access-denied": {
      "filePath": "_public/access-denied.tsx",
      "parent": "/_public"
    },
    "/_public/authentication-error": {
      "filePath": "_public/authentication-error.tsx",
      "parent": "/_public"
    },
    "/_public/login": {
      "filePath": "_public/login.tsx",
      "parent": "/_public"
    },
    "/_public/logout": {
      "filePath": "_public/logout.tsx",
      "parent": "/_public"
    },
    "/_public/saml-error": {
      "filePath": "_public/saml-error.tsx",
      "parent": "/_public"
    },
    "/_public/test-data": {
      "filePath": "_public/test-data.tsx",
      "parent": "/_public"
    },
    "/(redirects)/fleet/members": {
      "filePath": "(redirects)/fleet.members.tsx",
      "parent": "/(redirects)/fleet"
    },
    "/_auth/(no-layout)/verified": {
      "filePath": "_auth/(no-layout)/verified.tsx",
      "parent": "/_auth"
    },
    "/_auth/_layout/account": {
      "filePath": "_auth/_layout/account.tsx",
      "parent": "/_auth/_layout",
      "children": [
        "/_auth/_layout/account/delete",
        "/_auth/_layout/account/",
        "/_auth/_layout/account/migrate/success",
        "/_auth/_layout/account/migrate/"
      ]
    },
    "/_auth/_layout/data-access-consent": {
      "filePath": "_auth/_layout/data-access-consent.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/device": {
      "filePath": "_auth/_layout/device.tsx",
      "parent": "/_auth/_layout",
      "children": [
        "/_auth/_layout/device/browser",
        "/_auth/_layout/device/desktop",
        "/_auth/_layout/device/mobile",
        "/_auth/_layout/device/remarkable",
        "/_auth/_layout/device/",
        "/_auth/_layout/device/browser_/connect",
        "/_auth/_layout/device/desktop_/connect",
        "/_auth/_layout/device/mobile_/connect",
        "/_auth/_layout/device/remarkable_/connect"
      ]
    },
    "/_auth/_layout/enterprise": {
      "filePath": "_auth/_layout/enterprise.tsx",
      "parent": "/_auth/_layout",
      "children": [
        "/_auth/_layout/enterprise/_with-org",
        "/_auth/_layout/enterprise/enroll",
        "/_auth/_layout/enterprise/"
      ]
    },
    "/_auth/_layout/integrations": {
      "filePath": "_auth/_layout/integrations.tsx",
      "parent": "/_auth/_layout",
      "children": [
        "/_auth/_layout/integrations/callback",
        "/_auth/_layout/integrations/"
      ]
    },
    "/_auth/_layout/myfiles": {
      "filePath": "_auth/_layout/myfiles.tsx",
      "parent": "/_auth/_layout",
      "children": [
        "/_auth/_layout/myfiles/$"
      ]
    },
    "/_auth/_layout/screenshare": {
      "filePath": "_auth/_layout/screenshare.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/store": {
      "filePath": "_auth/_layout/store.tsx",
      "parent": "/_auth/_layout",
      "children": [
        "/_auth/_layout/store/connect-offer",
        "/_auth/_layout/store/dm-offer",
        "/_auth/_layout/store/methods-offer",
        "/_auth/_layout/store/",
        "/_auth/_layout/store/connect"
      ]
    },
    "/_auth/_layout/subscriptions": {
      "filePath": "_auth/_layout/subscriptions.tsx",
      "parent": "/_auth/_layout",
      "children": [
        "/_auth/_layout/subscriptions/members",
        "/_auth/_layout/subscriptions/payment",
        "/_auth/_layout/subscriptions/plan",
        "/_auth/_layout/subscriptions/"
      ]
    },
    "/_auth/_only-header/verify-new-email": {
      "filePath": "_auth/_only-header/verify-new-email.tsx",
      "parent": "/_auth/_only-header"
    },
    "/_auth/pair/app": {
      "filePath": "_auth/pair.app.tsx",
      "parent": "/_auth"
    },
    "/_public/downloads/mobile-app": {
      "filePath": "_public/downloads.mobile-app.tsx",
      "parent": "/_public"
    },
    "/_public/landing-page/device-setup": {
      "filePath": "_public/landing-page.device-setup.tsx",
      "parent": "/_public"
    },
    "/_auth/_layout/": {
      "filePath": "_auth/_layout/index.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/(no-layout)/account/delete": {
      "filePath": "_auth/(no-layout)/account/delete.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/(no-layout)/account/delete/confirmation/app",
        "/_auth/(no-layout)/account/delete/landing/app"
      ]
    },
    "/_auth/_layout/account/delete": {
      "filePath": "_auth/_layout/account/delete.tsx",
      "parent": "/_auth/_layout/account",
      "children": [
        "/_auth/_layout/account/delete/confirmation",
        "/_auth/_layout/account/delete/landing",
        "/_auth/_layout/account/delete/"
      ]
    },
    "/_auth/_layout/device/browser": {
      "filePath": "_auth/_layout/device/browser.tsx",
      "parent": "/_auth/_layout/device"
    },
    "/_auth/_layout/device/desktop": {
      "filePath": "_auth/_layout/device/desktop.tsx",
      "parent": "/_auth/_layout/device"
    },
    "/_auth/_layout/device/mobile": {
      "filePath": "_auth/_layout/device/mobile.tsx",
      "parent": "/_auth/_layout/device"
    },
    "/_auth/_layout/device/remarkable": {
      "filePath": "_auth/_layout/device/remarkable.tsx",
      "parent": "/_auth/_layout/device"
    },
    "/_auth/_layout/enterprise/_with-org": {
      "filePath": "_auth/_layout/enterprise/_with-org.tsx",
      "parent": "/_auth/_layout/enterprise",
      "children": [
        "/_auth/_layout/enterprise/_with-org/_layout"
      ]
    },
    "/_auth/_layout/enterprise/enroll": {
      "filePath": "_auth/_layout/enterprise/enroll.tsx",
      "parent": "/_auth/_layout/enterprise",
      "children": [
        "/_auth/_layout/enterprise/enroll/confirm-setup",
        "/_auth/_layout/enterprise/enroll/create-organization",
        "/_auth/_layout/enterprise/enroll/setup",
        "/_auth/_layout/enterprise/enroll/setup-saml",
        "/_auth/_layout/enterprise/enroll/verify-domain",
        "/_auth/_layout/enterprise/enroll/verify-saml",
        "/_auth/_layout/enterprise/enroll/"
      ]
    },
    "/_auth/_layout/integrations/callback": {
      "filePath": "_auth/_layout/integrations.callback.tsx",
      "parent": "/_auth/_layout/integrations"
    },
    "/_auth/_layout/invitation/seat": {
      "filePath": "_auth/_layout/invitation/seat.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/store/connect-offer": {
      "filePath": "_auth/_layout/store/connect-offer.tsx",
      "parent": "/_auth/_layout/store",
      "children": [
        "/_auth/_layout/store/connect-offer/_allowed",
        "/_auth/_layout/store/connect-offer/confirmation",
        "/_auth/_layout/store/connect-offer/download-apps",
        "/_auth/_layout/store/connect-offer/setup-complete"
      ]
    },
    "/_auth/_layout/store/dm-offer": {
      "filePath": "_auth/_layout/store/dm-offer.tsx",
      "parent": "/_auth/_layout/store",
      "children": [
        "/_auth/_layout/store/dm-offer/_allowed",
        "/_auth/_layout/store/dm-offer/confirmation",
        "/_auth/_layout/store/dm-offer/setup-complete"
      ]
    },
    "/_auth/_layout/store/methods-offer": {
      "filePath": "_auth/_layout/store/methods-offer.tsx",
      "parent": "/_auth/_layout/store",
      "children": [
        "/_auth/_layout/store/methods-offer/_allowed",
        "/_auth/_layout/store/methods-offer/confirmation",
        "/_auth/_layout/store/methods-offer/download-apps",
        "/_auth/_layout/store/methods-offer/setup-complete"
      ]
    },
    "/_auth/_layout/subscriptions/members": {
      "filePath": "_auth/_layout/subscriptions/members.tsx",
      "parent": "/_auth/_layout/subscriptions"
    },
    "/_auth/_layout/subscriptions/payment": {
      "filePath": "_auth/_layout/subscriptions/payment.tsx",
      "parent": "/_auth/_layout/subscriptions"
    },
    "/_auth/_layout/subscriptions/plan": {
      "filePath": "_auth/_layout/subscriptions/plan.tsx",
      "parent": "/_auth/_layout/subscriptions"
    },
    "/_auth/_layout/myfiles/$": {
      "filePath": "_auth/_layout/myfiles.$.lazy.tsx",
      "parent": "/_auth/_layout/myfiles"
    },
    "/_auth/_layout/account/": {
      "filePath": "_auth/_layout/account/index.tsx",
      "parent": "/_auth/_layout/account"
    },
    "/_auth/_layout/device/": {
      "filePath": "_auth/_layout/device/index.tsx",
      "parent": "/_auth/_layout/device"
    },
    "/_auth/_layout/enterprise/": {
      "filePath": "_auth/_layout/enterprise/index.tsx",
      "parent": "/_auth/_layout/enterprise"
    },
    "/_auth/_layout/integrations/": {
      "filePath": "_auth/_layout/integrations.index.tsx",
      "parent": "/_auth/_layout/integrations"
    },
    "/_auth/_layout/pair/": {
      "filePath": "_auth/_layout/pair/index.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/store/": {
      "filePath": "_auth/_layout/store/index.tsx",
      "parent": "/_auth/_layout/store"
    },
    "/_auth/_layout/subscriptions/": {
      "filePath": "_auth/_layout/subscriptions/index.tsx",
      "parent": "/_auth/_layout/subscriptions"
    },
    "/_auth/_layout/account/delete/confirmation": {
      "filePath": "_auth/_layout/account/delete.confirmation.tsx",
      "parent": "/_auth/_layout/account/delete"
    },
    "/_auth/_layout/account/delete/landing": {
      "filePath": "_auth/_layout/account/delete.landing.tsx",
      "parent": "/_auth/_layout/account/delete"
    },
    "/_auth/_layout/account/migrate/success": {
      "filePath": "_auth/_layout/account/migrate.success.tsx",
      "parent": "/_auth/_layout/account"
    },
    "/_auth/_layout/device/browser_/connect": {
      "filePath": "_auth/_layout/device/browser_.connect.tsx",
      "parent": "/_auth/_layout/device"
    },
    "/_auth/_layout/device/desktop_/connect": {
      "filePath": "_auth/_layout/device/desktop_.connect.tsx",
      "parent": "/_auth/_layout/device"
    },
    "/_auth/_layout/device/mobile_/connect": {
      "filePath": "_auth/_layout/device/mobile_.connect.tsx",
      "parent": "/_auth/_layout/device"
    },
    "/_auth/_layout/device/remarkable_/connect": {
      "filePath": "_auth/_layout/device/remarkable_.connect.tsx",
      "parent": "/_auth/_layout/device"
    },
    "/_auth/_layout/enterprise/_with-org/_layout": {
      "filePath": "_auth/_layout/enterprise/_with-org/_layout.tsx",
      "parent": "/_auth/_layout/enterprise/_with-org",
      "children": [
        "/_auth/_layout/enterprise/_with-org/_layout/members",
        "/_auth/_layout/enterprise/_with-org/_layout/plan",
        "/_auth/_layout/enterprise/_with-org/_layout/settings"
      ]
    },
    "/_auth/_layout/enterprise/enroll/confirm-setup": {
      "filePath": "_auth/_layout/enterprise/enroll/confirm-setup.tsx",
      "parent": "/_auth/_layout/enterprise/enroll"
    },
    "/_auth/_layout/enterprise/enroll/create-organization": {
      "filePath": "_auth/_layout/enterprise/enroll/create-organization.tsx",
      "parent": "/_auth/_layout/enterprise/enroll"
    },
    "/_auth/_layout/enterprise/enroll/setup": {
      "filePath": "_auth/_layout/enterprise/enroll/setup.tsx",
      "parent": "/_auth/_layout/enterprise/enroll"
    },
    "/_auth/_layout/enterprise/enroll/setup-saml": {
      "filePath": "_auth/_layout/enterprise/enroll/setup-saml.tsx",
      "parent": "/_auth/_layout/enterprise/enroll"
    },
    "/_auth/_layout/enterprise/enroll/verify-domain": {
      "filePath": "_auth/_layout/enterprise/enroll/verify-domain.tsx",
      "parent": "/_auth/_layout/enterprise/enroll"
    },
    "/_auth/_layout/enterprise/enroll/verify-saml": {
      "filePath": "_auth/_layout/enterprise/enroll/verify-saml.tsx",
      "parent": "/_auth/_layout/enterprise/enroll"
    },
    "/_auth/_layout/store/connect-offer/_allowed": {
      "filePath": "_auth/_layout/store/connect-offer._allowed.tsx",
      "parent": "/_auth/_layout/store/connect-offer",
      "children": [
        "/_auth/_layout/store/connect-offer/_allowed/checkout",
        "/_auth/_layout/store/connect-offer/_allowed/"
      ]
    },
    "/_auth/_layout/store/connect-offer/confirmation": {
      "filePath": "_auth/_layout/store/connect-offer.confirmation.tsx",
      "parent": "/_auth/_layout/store/connect-offer"
    },
    "/_auth/_layout/store/connect-offer/download-apps": {
      "filePath": "_auth/_layout/store/connect-offer.download-apps.tsx",
      "parent": "/_auth/_layout/store/connect-offer"
    },
    "/_auth/_layout/store/connect-offer/setup-complete": {
      "filePath": "_auth/_layout/store/connect-offer.setup-complete.tsx",
      "parent": "/_auth/_layout/store/connect-offer"
    },
    "/_auth/_layout/store/connect": {
      "filePath": "_auth/_layout/store",
      "parent": "/_auth/_layout/store",
      "children": [
        "/_auth/_layout/store/connect/_allowed",
        "/_auth/_layout/store/connect/confirmation"
      ]
    },
    "/_auth/_layout/store/connect/_allowed": {
      "filePath": "_auth/_layout/store/connect._allowed.tsx",
      "parent": "/_auth/_layout/store/connect",
      "children": [
        "/_auth/_layout/store/connect/_allowed/checkout",
        "/_auth/_layout/store/connect/_allowed/"
      ]
    },
    "/_auth/_layout/store/connect/confirmation": {
      "filePath": "_auth/_layout/store/connect.confirmation.tsx",
      "parent": "/_auth/_layout/store/connect"
    },
    "/_auth/_layout/store/dm-offer/_allowed": {
      "filePath": "_auth/_layout/store/dm-offer._allowed.tsx",
      "parent": "/_auth/_layout/store/dm-offer",
      "children": [
        "/_auth/_layout/store/dm-offer/_allowed/checkout",
        "/_auth/_layout/store/dm-offer/_allowed/"
      ]
    },
    "/_auth/_layout/store/dm-offer/confirmation": {
      "filePath": "_auth/_layout/store/dm-offer.confirmation.tsx",
      "parent": "/_auth/_layout/store/dm-offer"
    },
    "/_auth/_layout/store/dm-offer/setup-complete": {
      "filePath": "_auth/_layout/store/dm-offer.setup-complete.tsx",
      "parent": "/_auth/_layout/store/dm-offer"
    },
    "/_auth/_layout/store/methods-offer/_allowed": {
      "filePath": "_auth/_layout/store/methods-offer._allowed.tsx",
      "parent": "/_auth/_layout/store/methods-offer",
      "children": [
        "/_auth/_layout/store/methods-offer/_allowed/checkout",
        "/_auth/_layout/store/methods-offer/_allowed/"
      ]
    },
    "/_auth/_layout/store/methods-offer/confirmation": {
      "filePath": "_auth/_layout/store/methods-offer.confirmation.tsx",
      "parent": "/_auth/_layout/store/methods-offer"
    },
    "/_auth/_layout/store/methods-offer/download-apps": {
      "filePath": "_auth/_layout/store/methods-offer.download-apps.tsx",
      "parent": "/_auth/_layout/store/methods-offer"
    },
    "/_auth/_layout/store/methods-offer/setup-complete": {
      "filePath": "_auth/_layout/store/methods-offer.setup-complete.tsx",
      "parent": "/_auth/_layout/store/methods-offer"
    },
    "/_public/_layout/account/migrate/saml": {
      "filePath": "_public/_layout/account.migrate.saml.tsx",
      "parent": "/_public/_layout"
    },
    "/_auth/_layout/account/delete/": {
      "filePath": "_auth/_layout/account/delete.index.tsx",
      "parent": "/_auth/_layout/account/delete"
    },
    "/_auth/_layout/account/migrate/": {
      "filePath": "_auth/_layout/account/migrate.index.tsx",
      "parent": "/_auth/_layout/account"
    },
    "/_auth/_layout/enterprise/enroll/": {
      "filePath": "_auth/_layout/enterprise/enroll/index.tsx",
      "parent": "/_auth/_layout/enterprise/enroll"
    },
    "/_auth/(no-layout)/account/delete/confirmation/app": {
      "filePath": "_auth/(no-layout)/account/delete/confirmation.app.tsx",
      "parent": "/_auth/(no-layout)/account/delete"
    },
    "/_auth/(no-layout)/account/delete/landing/app": {
      "filePath": "_auth/(no-layout)/account/delete/landing.app.tsx",
      "parent": "/_auth/(no-layout)/account/delete"
    },
    "/_auth/_layout/enterprise/_with-org/_layout/members": {
      "filePath": "_auth/_layout/enterprise/_with-org/_layout.members.tsx",
      "parent": "/_auth/_layout/enterprise/_with-org/_layout"
    },
    "/_auth/_layout/enterprise/_with-org/_layout/plan": {
      "filePath": "_auth/_layout/enterprise/_with-org/_layout.plan.tsx",
      "parent": "/_auth/_layout/enterprise/_with-org/_layout"
    },
    "/_auth/_layout/enterprise/_with-org/_layout/settings": {
      "filePath": "_auth/_layout/enterprise/_with-org/_layout.settings.tsx",
      "parent": "/_auth/_layout/enterprise/_with-org/_layout"
    },
    "/_auth/_layout/store/connect-offer/_allowed/checkout": {
      "filePath": "_auth/_layout/store/connect-offer._allowed.checkout.tsx",
      "parent": "/_auth/_layout/store/connect-offer/_allowed"
    },
    "/_auth/_layout/store/connect/_allowed/checkout": {
      "filePath": "_auth/_layout/store/connect._allowed.checkout.tsx",
      "parent": "/_auth/_layout/store/connect/_allowed"
    },
    "/_auth/_layout/store/dm-offer/_allowed/checkout": {
      "filePath": "_auth/_layout/store/dm-offer._allowed.checkout.tsx",
      "parent": "/_auth/_layout/store/dm-offer/_allowed"
    },
    "/_auth/_layout/store/methods-offer/_allowed/checkout": {
      "filePath": "_auth/_layout/store/methods-offer._allowed.checkout.tsx",
      "parent": "/_auth/_layout/store/methods-offer/_allowed"
    },
    "/_auth/_layout/store/connect-offer/_allowed/": {
      "filePath": "_auth/_layout/store/connect-offer._allowed.index.tsx",
      "parent": "/_auth/_layout/store/connect-offer/_allowed"
    },
    "/_auth/_layout/store/connect/_allowed/": {
      "filePath": "_auth/_layout/store/connect._allowed.index.tsx",
      "parent": "/_auth/_layout/store/connect/_allowed"
    },
    "/_auth/_layout/store/dm-offer/_allowed/": {
      "filePath": "_auth/_layout/store/dm-offer._allowed.index.tsx",
      "parent": "/_auth/_layout/store/dm-offer/_allowed"
    },
    "/_auth/_layout/store/methods-offer/_allowed/": {
      "filePath": "_auth/_layout/store/methods-offer._allowed.index.tsx",
      "parent": "/_auth/_layout/store/methods-offer/_allowed"
    }
  }
}
ROUTE_MANIFEST_END */
