import { createFileRoute } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { z } from 'zod';

import { createPageMetaTitle } from 'src/utils/createPageMetaTitle';

const integrationsCallbackParamsSchema = z.object({
  callback: z.boolean().optional(),
  state: z.string().optional(),
  code: z.string().optional(),
  error: z.string().optional(),
  scope: z.string().optional(),
});

export const Route = createFileRoute('/_auth/_layout/integrations')({
  staticData: { title: createPageMetaTitle('Integrations') },
  validateSearch: zodValidator(integrationsCallbackParamsSchema),
});
