import { Typography } from '@remarkable/ark-web';
import { ArrowRight } from 'phosphor-react';

import { ButtonClicked } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { ComponentLocations } from 'src/analytics/trackingTypes';
import { Button, CardContent, FlexDivider } from 'src/components';
import { SUPPORT_URL } from 'src/utils/urls/supportUrls';
import { URLS } from 'src/utils/urls/urls';

export const NoSubscriptionPairedDeviceCard = () => {
  return (
    <CardContent dataCy="subscription-no-plan-paired-device">
      <div className="flex flex-col gap-32 lm:flex-row">
        <div className="flex flex-1 flex-col justify-between gap-24">
          <Typography variant="body-sm-regular" className="my-0">
            If your reMarkable was purchased{' '}
            <Typography variant="body-sm-bold" as="strong">
              after October 12, 2021
            </Typography>{' '}
            you can buy a new Connect subscription right here:
          </Typography>

          <Button
            data-cy="buy-connect"
            as="a"
            variant="primary"
            onClick={(e) => {
              tracker.trackEvent(
                new ButtonClicked({
                  component_location:
                    ComponentLocations.CONNECT.NO_ACTIVE_SUBSCRIPTION_CARD,
                  text: e.currentTarget.innerText,
                  link_source: URLS.STORE_CONNECT_LANDING,
                })
              );
            }}
            to={URLS.STORE_CONNECT_LANDING}
          >
            <span>Buy Connect</span>
            <ArrowRight />
          </Button>
        </div>

        <FlexDivider />

        <div className="flex flex-1 flex-col justify-between gap-24">
          <Typography variant="body-sm-regular" className="my-0">
            If your reMarkable was purchased{' '}
            <Typography variant="body-sm-bold" as="strong">
              before October 12, 2021
            </Typography>{' '}
            then you&apos;re entitled to claim full free access to Connect:
          </Typography>

          <Button
            variant="secondary"
            as="a"
            target="_blank"
            to={SUPPORT_URL.ARTICLES.ABOUT_CONNECT_SUBSCRIPTION}
            onClick={(e) => {
              tracker.trackEvent(
                new ButtonClicked({
                  component_location:
                    ComponentLocations.CONNECT.NO_ACTIVE_SUBSCRIPTION_CARD,
                  text: e.currentTarget.innerText,
                  link_source: URLS.STORE_CONNECT_LANDING,
                })
              );
            }}
          >
            Claim free access
          </Button>
        </div>
      </div>
    </CardContent>
  );
};
