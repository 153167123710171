import { components } from 'apis/test-data-service-api-public';

export type UserRequestBody = components['schemas']['User'];

export type DeviceTypes = components['schemas']['DeviceType'];

export type SubscriptionTypes = components['schemas']['SubscriptionType'];

export type TestDataApiError = components['schemas']['ApiError'];

export type TestDataApiValidationError =
  components['schemas']['HTTPValidationError'];

export class FailedUserCreationError extends Error {
  constructor(public message: string) {
    super(message);
  }
}
