import { ElementRef, forwardRef, HTMLProps, useMemo } from 'react';

import clsx from 'clsx';
import { CaretDown } from 'phosphor-react';

import { CA_PROVINCES, US_STATES } from 'src/utils/countryList';

import { cn } from '../utils/classNamesHelper';

interface StatePickerProps {
  country: string;
  hasError?: boolean;
}

export const StatePicker2 = forwardRef<
  ElementRef<'select'>,
  HTMLProps<HTMLSelectElement> & StatePickerProps
>(function RenderStatePicker2(
  { country, value, hasError, disabled, ...props },
  ref
) {
  const isUS = country === 'US';
  const isCanada = country === 'CA';

  const states = useMemo(() => {
    if (isUS) return US_STATES;

    if (isCanada) return CA_PROVINCES;

    return [];
  }, [isUS, isCanada]);

  return (
    <div className={cn('relative block rounded bg-white')}>
      <select
        data-cy={
          isCanada
            ? 'state-picker-canada'
            : isUS
            ? 'state-picker-us'
            : 'state-picker'
        }
        className={clsx(
          'h-48 w-full cursor-pointer appearance-none rounded border bg-none px-16 py-8',
          {
            'cursor-wait': disabled,
            'border-neutral-light-8': !hasError,
            'border-feedback-red-500': hasError,
          }
        )}
        disabled={disabled}
        value={value}
        ref={ref}
        {...props}
      >
        {!value && (
          <option value="">
            {isCanada ? 'Choose province...' : 'Choose state...'}
          </option>
        )}

        {states.map((option) => (
          <option
            key={option.value}
            value={option.value}
            aria-selected={value === option.value}
          >
            {option.label}
          </option>
        ))}
      </select>
      <CaretDown
        size={32}
        className="pointer-events-none absolute right-12 top-0 h-full text-pen-blue"
      />
    </div>
  );
});
