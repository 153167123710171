import * as Sentry from '@sentry/react';
import { createFileRoute } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import jwtDecode from 'jwt-decode';
import { z } from 'zod';

import { RmClaims } from 'src/api/queries/user';
import {
  AccessDeniedError,
  AccountLinkingMismatchError,
} from 'src/utils/errors';
import { LogCategory } from 'src/utils/logger.categories';
import { userHelpers } from 'src/utils/userHelpers';

const searchParamSchema = z.object({
  accountLinkingError: z.string().optional(),
});

export const Route = createFileRoute('/_auth/_layout/enterprise/enroll')({
  validateSearch: zodValidator(searchParamSchema),
  async beforeLoad({ context, location }) {
    const auth = await context.auth;

    if (!auth.user) {
      Sentry.addBreadcrumb({
        category: LogCategory.Auth,
        message: 'Enterprise enrollment: User not found',
      });
      throw new Error('Unauthorized');
    }

    if (!auth.user.email) {
      Sentry.addBreadcrumb({
        category: LogCategory.Auth,
        message: 'Enterprise enrollment: Email not found',
      });
      throw new Error('Email not found');
    }

    if (
      'accountLinkingToken' in location.search &&
      typeof location.search.accountLinkingToken === 'string'
    ) {
      let previousUser: RmClaims | undefined;

      try {
        previousUser = jwtDecode<RmClaims>(location.search.accountLinkingToken);
      } catch (error) {
        Sentry.addBreadcrumb({
          category: LogCategory.Auth,
          message:
            'Enterprise enrollment: Unable to parse account linking token',
        });
        throw new Error('Invalid account linking token');
      }

      if (!previousUser) {
        throw new Error('Previous user not found');
      }

      let linkableAccountTarget: string | undefined;
      let accountToMatch: string | undefined;

      if (userHelpers.isSAMLUser(auth.user)) {
        linkableAccountTarget = userHelpers.getLinkableUserId(auth.user);
        accountToMatch = previousUser.sub;
      } else {
        linkableAccountTarget = userHelpers.getLinkableUserId(previousUser);
        accountToMatch = auth.user.sub;
      }

      if (linkableAccountTarget !== accountToMatch) {
        throw new AccountLinkingMismatchError(
          '/enterprise/enroll/verify-saml?accountLinkingError=Account%20mismatch',
          auth.user,
          previousUser
        );
      }
    }

    const allowedDomains = [
      'remarkable.no',
      'remarkable.com',
      'inbox.testmail.app',
    ];
    const currentDomain = auth.user.email.split('@')[1];
    const accessToken: RmClaims = jwtDecode(
      await auth.getAccessTokenSilently()
    );

    if (
      allowedDomains.includes(currentDomain) ||
      userHelpers.hasPermissionAccessEnterpriseBeta(accessToken) ||
      userHelpers.isSAMLUser(auth.user) ||
      userHelpers.isOrganizationMember(auth.user)
    ) {
      return;
    }

    Sentry.addBreadcrumb({
      category: LogCategory.Auth,
      message: 'Enterprise enrollment: Unauthorized, redirecting to /',
    });

    throw new AccessDeniedError('Unauthorized');
  },
});
