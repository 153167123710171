import { useEffect } from 'react';

import { useCountry } from 'src/api/queries';
import { IS_DEVELOPMENT } from 'src/config';

import './styles.css';

export const UxSignals = ({ id }: { id: string }) => {
  const country = useCountry();
  const isInUSOrUK =
    country.isSuccess &&
    (country.data.value === 'US' || country.data.value === 'GB');
  const shouldLoadScript = isInUSOrUK;

  useEffect(() => {
    if (shouldLoadScript) {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://widget.uxsignals.com/embed.js';

      document.body.appendChild(script);

      return () => {
        try {
          document.body.removeChild(script);
        } catch (error) {
          console.error();
        }
      };
    }
  }, [shouldLoadScript]);

  if (!shouldLoadScript) return null;

  return (
    <div
      data-uxsignals-embed={id}
      data-uxsignals-mode={IS_DEVELOPMENT ? 'demo' : ''}
      className="uxsignals-embed min-h-48"
    ></div>
  );
};
