import { useEffect } from 'react';

import { useMatches } from '@tanstack/react-router';

export function Meta() {
  const matches = useMatches();
  const titles = matches.map((match) => match.staticData.title).filter(Boolean);
  const currentTitle = titles[titles.length - 1];

  useEffect(() => {
    document.title = currentTitle ?? 'my.reMarkable';
  }, [currentTitle]);
  return null;
}
