import { Middleware } from 'openapi-fetch';

import { auth0Client } from 'src/auth0Client';

export const addAuthHeader: Middleware = {
  async onRequest({ request }) {
    const auth0 = await auth0Client;
    const token = await auth0.getAccessTokenSilently();

    if (!request.headers.has('Accept')) {
      request.headers.set('Accept', 'application/json');
    }

    if (!request.headers.has('Authorization')) {
      request.headers.set('Authorization', `Bearer ${token}`);
    }

    return request;
  },
};
