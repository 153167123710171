import { HTMLProps, ReactNode } from 'react';

import { cn } from 'src/utils/classNamesHelper';

import { Tooltip } from './Tooltip';

export function Abbreviation({
  children,
  title,
  className,
  ...rest
}: HTMLProps<HTMLSpanElement> & { title: ReactNode }) {
  return (
    <Tooltip title={title} clickable asChild>
      <abbr
        className={cn(
          'cursor-help rounded underline decoration-dotted hover:bg-neutral-light-3',
          className
        )}
        aria-description={title}
        {...rest}
      >
        {children}
      </abbr>
    </Tooltip>
  );
}
