import { Typography } from '@remarkable/ark-web';
import { AppleLogo } from 'phosphor-react';

import { MACOS_ITUNES_URL } from 'src/apps/device/utils/constants';
import windowsColor from 'src/assets/icons/windowsColor.svg';
import { CONFIG } from 'src/config';

import { LinkExternal } from './LinkExternal';

export const DesktopAppLink = ({
  platform,
}: {
  platform: 'windows' | 'macos';
}) => (
  <LinkExternal
    className="hover:text-inherit flex w-fit items-center gap-8 rounded-[10px] bg-neutral-light-4 p-8 no-underline hover:bg-neutral-light-4 hover:no-underline"
    to={
      platform === 'macos'
        ? MACOS_ITUNES_URL
        : `${CONFIG.DownloadsURL}/latest/windows`
    }
    data-cy={`download-desktop-app-${platform}`}
  >
    {platform == 'macos' ? (
      <AppleLogo
        className="h-24 max-h-24 !transform-none"
        weight="fill"
        size={24}
      />
    ) : (
      <img height="24" width="24" src={windowsColor} alt="Word"></img>
    )}
    <div className="flex flex-col">
      <Typography
        className="m-0 flex flex-nowrap"
        variant="interface-xs-regular"
      >
        {platform == 'macos' ? 'Download on the' : 'Get it from'}
      </Typography>
      <Typography
        className="m-0 flex flex-nowrap"
        variant="interface-md-semibold"
      >
        {platform == 'macos' ? 'Mac App store' : 'Microsoft'}
      </Typography>
    </div>
  </LinkExternal>
);
