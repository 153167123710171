import { ArrowLeft, ArrowRight, SealQuestion } from '@phosphor-icons/react';
import { Typography } from '@remarkable/ark-web';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { Link2 } from 'src/components/Link2';
import { LinkExternal } from 'src/components/LinkExternal';
import { URLS } from 'src/utils/urls/urls';

import { StepIndicator } from './StepIndicator';

export const MrfStep4 = () => {
  return (
    <div
      className="flex h-full flex-col justify-between text-center"
      data-cy="mrf-step-4"
    >
      <div className="ls:p-64">
        <Typography variant="heading-subtle-xl">
          You&apos;ll miss out on
        </Typography>
        <Typography variant="responsive-heading-lg" className="my-24" as="h1">
          Future benefits
        </Typography>
        <Typography variant="responsive-body-sm">
          Read more about the benefits with Connect{' '}
          <LinkExternal
            inline
            to={URLS.RM_COM_STORE_CONNECT}
            componentLocation={ComponentLocations.MRF_MODAL.MRF_STEP_4}
          >
            here
          </LinkExternal>
          .
        </Typography>
        <ul className="flex w-full justify-center py-48">
          <li className="flex w-full flex-col items-center justify-start ls:w-[144px]">
            <SealQuestion className="h-32 w-32 ls:h-40 ls:w-40" />
            <Typography variant="body-sm-regular" className="mt-12 text-center">
              New and improved features
            </Typography>
          </li>
        </ul>
      </div>
      <div className="grid w-full grid-cols-3 py-4 align-middle">
        <div>
          <Link2
            size="medium"
            variant="tertiary-neutral"
            as="button"
            to="."
            search={{ cancel_subscription: 'step-3' }}
            componentLocation={ComponentLocations.MRF_MODAL.MRF_STEP_4}
          >
            <>
              <ArrowLeft /> <span> Back</span>
            </>
          </Link2>
        </div>
        <StepIndicator numSteps={5} activeStep={3} />

        <div className="flex w-full justify-end">
          <Link2
            size="medium"
            variant="tertiary-neutral"
            data-cy="mrf-next-step-4"
            as="button"
            to="."
            search={{ cancel_subscription: 'step-5' }}
            componentLocation={ComponentLocations.MRF_MODAL.MRF_STEP_4}
          >
            <>
              <span>Next</span> <ArrowRight />
            </>
          </Link2>
        </div>
      </div>
    </div>
  );
};
