import { ComponentProps } from 'react';

import { ClockCountdown } from '@phosphor-icons/react';
import { Tag } from '@remarkable/ark-web';
import { Check, XCircle } from 'phosphor-react';

import {
  Subscription,
  SubscriptionStatus,
} from 'src/api/endpoints/storeApi.types';
import { subscriptionHelpers } from 'src/utils/subscriptionHelpers';

type StatusTagProps = Partial<ComponentProps<typeof Tag>> & {
  status?: SubscriptionStatus;
  subscription?: Subscription;
};
export const StatusTag = ({
  status,
  subscription,
  ...props
}: StatusTagProps) => {
  const subscriptionStatus =
    status ?? subscriptionHelpers.getStatus(subscription);

  if (!subscriptionStatus) {
    return null;
  }

  switch (subscriptionStatus) {
    case 'Active':
      return (
        <Tag variant="success-light" {...props}>
          <Check /> Active
        </Tag>
      );
    case 'Cancelled':
      return (
        <Tag variant="themed-filled" className="bg-error-light" {...props}>
          <XCircle /> Cancelled
        </Tag>
      );
    case 'Deactivated':
      return (
        <Tag {...props}>
          <ClockCountdown /> On hold
        </Tag>
      );
    default:
      return null;
  }
};
