import { ArrowsClockwise } from '@phosphor-icons/react';
import {
  Check,
  CheckCircle,
  CloudArrowUp,
  Layout,
  NotePencil,
  ShieldCheck,
} from 'phosphor-react';

export const valueProps = {
  enterprise: {
    unlimitedCloudStorage: {
      title: 'Unlimited cloud storage',
      id: 'unlimitedCloudStorage',
      icon: CloudArrowUp,
    },
    automaticSync: {
      title: 'Automatic sync',
      id: 'automaticSync',
      icon: ArrowsClockwise,
    },
    createAndEditInOurApps: {
      title: 'Create and edit in our apps',
      id: 'createAndEditInOurApps',
      icon: NotePencil,
    },
    remarkableProtectionPlan: {
      title: 'reMarkable Protection Plan',
      id: 'remarkableProtectionPlan',
      icon: ShieldCheck,
    },
  },

  connectWithSeats: {
    unlimitedCloudStorage: {
      title: 'Unlimited cloud storage',
      id: 'unlimitedCloudStorage',
      icon: CloudArrowUp,
    },
    automaticSync: {
      title: 'Automatic sync',
      id: 'automaticSync',
      icon: ArrowsClockwise,
    },
    createAndEditInOurApps: {
      title: 'Create and edit in our apps',
      id: 'createAndEditInOurApps',
      icon: NotePencil,
    },
    remarkableProtectionPlan: {
      title: 'reMarkable Protection Plan',
      id: 'remarkableProtectionPlan',
      icon: ShieldCheck,
    },
  },
  connect: {
    unlimitedCloudStorage: {
      title: 'Unlimited cloud storage',
      id: 'unlimitedCloudStorage',
      icon: CloudArrowUp,
      description: (
        <>
          Connect gives you unlimited, secure cloud storage for all your
          documents.
        </>
      ),
    },
    automaticSync: {
      title: 'Automatic sync',
      id: 'automaticSync',
      icon: ArrowsClockwise,
      description: (
        <>
          Your documents are always accessible and ready to use on your
          reMarkable paper tablet.
        </>
      ),
    },
    createAndEditInOurApps: {
      title: 'Create and edit in our apps',
      id: 'createAndEditInOurApps',
      icon: NotePencil,
      description: (
        <>
          Capture a thought on your phone, develop it in detail on your paper
          tablet, and finish it on your computer.
        </>
      ),
    },
    remarkableProtectionPlan: {
      title: 'reMarkable Protection Plan',
      id: 'remarkableProtectionPlan',
      icon: ShieldCheck,
      description: (
        <>
          If you experience defects with your reMarkable that aren&apos;t caused
          by accidental damage or similar external factors, we&apos;ll replace
          it free of charge.
        </>
      ),
    },
  },
  connectOfferHero: {
    remarkableMethods: {
      title: 'Access to templates and workbooks with reMarkable Methods',
      id: 'remarkableMethods',
      icon: CheckCircle,
      isNew: true,
    },
    unlimitedCloudStorage: {
      title: 'Unlimited cloud storage and automatic sync',
      id: 'unlimitedCloudStorage',
      icon: CheckCircle,
      isNew: false,
    },
    createAndEditInOurApps: {
      title: 'Mobile and desktop note-taking',
      id: 'mobileAndDesktopNoteTaking',
      icon: CheckCircle,
      isNew: false,
    },
    remarkableProtectionPlan: {
      title: 'reMarkable Protection Plan',
      id: 'remarkableProtectionPlan',
      icon: CheckCircle,
      isNew: false,
    },
  },
  connectOfferBanner: {
    unlimitedCloudStorage: {
      title: 'Unlimited cloud storage and automatic sync',
      id: 'unlimitedCloudStorage',
      icon: CloudArrowUp,
      description: (
        <>
          Connect gives you unlimited, secure cloud storage for all your
          documents. Always accessible and ready to use on your reMarkable paper
          tablet.
        </>
      ),
      isNew: false,
    },
    createAndEditInOurApps: {
      title: 'Mobile and desktop note-taking',
      id: 'mobileAndDesktopNoteTaking',
      icon: NotePencil,
      description: (
        <>
          Capture a thought on your phone, develop it in detail on your paper
          tablet, and finish it on your computer.
        </>
      ),
      isNew: false,
    },
    remarkableProtectionPlan: {
      title: 'reMarkable Protection Plan',
      id: 'remarkableProtectionPlan',
      icon: ShieldCheck,
      description: (
        <>
          If you experience defects with your reMarkable that aren&apos;t caused
          by accidental damage or similar external factors, we&apos;ll replace
          it free of charge.
        </>
      ),
      isNew: false,
    },
    remarkableMethods: {
      title: 'reMarkable Methods',
      id: 'remarkableMethods',
      icon: Layout,
      description: (
        <>
          Download useful templates and workbooks from a community of expert
          thinkers.
        </>
      ),
      isNew: true,
    },
  },
  winbackOffer: {
    remarkableMethods: {
      title: 'Templates and workbooks from reMarkable Methods',
      id: 'remarkableMethods',
      icon: Check,
      isNew: true,
    },
    unlimitedCloudStorage: {
      title: 'Unlimited cloud storage',
      id: 'unlimitedCloudStorage',
      icon: Check,
      isNew: false,
    },
    createAndEditInOurApps: {
      title: 'Mobile and desktop note-taking',
      id: 'mobileAndDesktopNoteTaking',
      icon: Check,
      isNew: false,
    },
    remarkableProtectionPlan: {
      title: 'reMarkable Protection Plan',
      id: 'remarkableProtectionPlan',
      icon: Check,
      isNew: false,
    },
  },
  connectTestA: {
    unlimitedCloudStorage: {
      title: 'Unlimited cloud storage',
      id: 'unlimitedCloudStorage',
      icon: CheckCircle,
    },
    automaticSync: {
      title: 'Automatic sync',
      id: 'automaticSync',
      icon: CheckCircle,
    },
    mobileAndDesktopNoteTaking: {
      title: 'Mobile and desktop note-taking',
      id: 'mobileAndDesktopNoteTaking',
      icon: CheckCircle,
    },
    remarkableProtectionPlan: {
      title: 'reMarkable Protection Plan',
      id: 'remarkableProtectionPlan',
      icon: CheckCircle,
    },
  },
};
