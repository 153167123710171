import { createFileRoute, notFound } from '@tanstack/react-router';
import { Toaster } from 'react-hot-toast';

import { TestDataApp } from 'src/apps/testData/TestDataApp';
import { IS_DEVELOPMENT } from 'src/config';

export const Route = createFileRoute('/_public/test-data')({
  beforeLoad: () => {
    if (!IS_DEVELOPMENT) {
      throw notFound();
    }
  },

  component: () => {
    return (
      <>
        <Toaster />
        <TestDataApp />
      </>
    );
  },
});
