import { HTMLAttributes } from 'react';

import { useProductPrice } from 'src/api/queries';
import { formatAmountBrowserDefaultLocale } from 'src/utils/productUtils';
import { SKU } from 'src/utils/skus';

import { Spinner } from './Spinner';

export function Price({
  sku,
  ...props
}: HTMLAttributes<HTMLSpanElement> & {
  sku?: SKU;
}) {
  const productPrice = useProductPrice(sku);

  const formattedPrice = productPrice.data
    ? formatAmountBrowserDefaultLocale(
        productPrice.data?.amount ?? 0,
        productPrice.data?.currency
      )
    : '[n/a]';

  return (
    <span data-cy="price" {...props}>
      {productPrice.isLoading ? <Spinner inline /> : formattedPrice}
    </span>
  );
}
