import { HTMLProps } from 'react';

import { cn } from 'src/utils/classNamesHelper';

/**
 * Until Ark exposes a official Highlight component we have this as a
 * temporary approximation.
 * It needs the scale to be tweaked based on the length of the content.
 * It will not work on multiline content (hence nowrap).
 *
 * Should be replaced with Ark as soon as possible.
 */
export function Highlight({
  className,
  children,
  color = '#FF9F47',
  curvature = 4,
  scale,
  ...props
}: HTMLProps<HTMLSpanElement> & {
  color?: string;
  curvature?: number;
  scale: number;
}) {
  const curve = 25 - curvature;

  return (
    <span
      className={cn('relative inline-block whitespace-nowrap', className)}
      {...props}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${scale} 50`}
        className="absolute left-0 top-1/2 -z-10 h-[1.2em] w-full -translate-y-1/2"
      >
        <path
          d={`M0 25 Q50 ${curve}, ${scale / 2} 25 T${scale} 25`}
          stroke={color}
          strokeWidth="40"
          fill="none"
          opacity="0.6"
        />
      </svg>
      {children}
    </span>
  );
}
