import { createFileRoute, redirect } from '@tanstack/react-router';

import { createQueryDevices } from 'src/api/queries';
import { WinbackOfferDownloadAppsPage } from 'src/apps/checkout/app/pages/WinbackOffer.DownloadApps';
import { filterActivePaperTabletClients } from 'src/apps/device/utils/utils';

export const Route = createFileRoute(
  '/_auth/_layout/store/methods-offer/download-apps'
)({
  async beforeLoad({ context }) {
    const devices = await context.queryClient.ensureQueryData(
      createQueryDevices()
    );
    const activeTables = filterActivePaperTabletClients(devices);

    if (activeTables.length > 1) {
      throw redirect({ to: '/store/methods-offer/confirmation' });
    }
  },
  component: () => <WinbackOfferDownloadAppsPage />,
});
