import {
  DropdownMenuGroup,
  DropdownMenuLabel,
} from '@radix-ui/react-dropdown-menu';
import { CaretDown } from 'phosphor-react';

import { Button } from 'src/components';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
} from 'src/components/DropdownMenu';

interface UserPropertiesDropdownProps {
  label: string;
  selected: string[] | null;
  dropdownOptions: { value: string; label: string }[];
  onCheckedChange: (item: string, checked: boolean) => void;
  className: string;
  disabled?: boolean;
}
export const UserPropertiesDropdown = (opts: UserPropertiesDropdownProps) => {
  return (
    <DropdownMenuGroup className={opts.className}>
      <DropdownMenuLabel>{opts.label}</DropdownMenuLabel>
      <DropdownMenu
        trigger={
          <Button variant="secondary" size="medium" disabled={opts.disabled}>
            <span>{opts.selected?.join(',') || 'None'}</span>
            <CaretDown />
          </Button>
        }
      >
        {opts.dropdownOptions.map((item) => (
          <DropdownMenuCheckboxItem
            key={item.value}
            checked={opts.selected?.includes(item.value)}
            onCheckedChange={(checked) =>
              opts.onCheckedChange(item.value, checked)
            }
          >
            {item.label}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenu>
    </DropdownMenuGroup>
  );
};
