import { createFileRoute, redirect } from '@tanstack/react-router';

import { createQueryCheckoutAllowed } from 'src/api/queries';
import { userHelpers } from 'src/utils/userHelpers';

export const Route = createFileRoute('/_auth/_layout/store/')({
  errorComponent: ({ error }) => <div>Error: {error.message}</div>,
  beforeLoad: async ({ context }) => {
    const allowedData = await context.queryClient.ensureQueryData(
      createQueryCheckoutAllowed()
    );
    const auth = await context.auth;

    if (allowedData?.connectOffer.allowed) {
      throw redirect({ to: '/store/connect-offer', replace: true });
    }

    if (allowedData?.winbackOffer.allowed) {
      throw redirect({ to: '/store/methods-offer/checkout', replace: true });
    }

    if (allowedData?.dmOffer.allowed) {
      throw redirect({ to: '/store/dm-offer', replace: true });
    }

    if (allowedData?.store.allowed) {
      throw redirect({ to: '/store/connect', replace: true });
    }

    if (auth.user && userHelpers.isOrganizationMember(auth.user)) {
      throw redirect({
        to: '/enterprise/plan',
        replace: true,
        search: {
          access_denied: 'Already have subscription through enterprise',
        },
      });
    }

    if (auth.user && userHelpers.hasSubscription(auth.user)) {
      throw redirect({
        to: '/subscriptions',
        replace: true,
        search: { access_denied: 'Already have a subscription' },
      });
    }

    throw redirect({
      to: '/',
      replace: true,
      search: { access_denied: 'Checkout not allowed' },
    });
  },
});
