import { lazy, useEffect } from 'react';

import { Auth0ContextInterface, User } from '@auth0/auth0-react';
import { QueryClient } from '@tanstack/react-query';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import toast from 'react-hot-toast';
import { z } from 'zod';

import { Toast } from 'src/components';
import { IS_DEVELOPMENT } from 'src/config';
import { SearchParamSchemas } from 'src/utils/searchParamSchemas';

import { Meta } from './-Meta';

export interface RouterContext {
  auth: Promise<Auth0ContextInterface<User>>;
  queryClient: QueryClient;
}

export const validateSearch = zodValidator(
  z.union([
    SearchParamSchemas.EditPaymentMethodResponse,
    SearchParamSchemas.AccessDenied,
    z.object({}),
  ])
);

export const Route = createRootRouteWithContext<RouterContext>()({
  staticData: { title: 'my.reMarkable' },
  validateSearch,
  component: Root,
});

/**
 * The devtool is already lazy loaded with its own separate bundle, but in order for true tree shaking to work and not even link to the devtool bundle in production, the environment check needs to happen in a static environment.
 */
const Devtool = IS_DEVELOPMENT
  ? lazy(() =>
      import('../apps/devtool/Root').then((m) => ({ default: m.Root }))
    )
  : undefined;

function Root() {
  const search = Route.useSearch();
  const navigate = Route.useNavigate();

  useEffect(() => {
    if ('access_denied' in search) {
      toast.custom(({ visible }) => (
        <Toast
          variant="error"
          title="Access Denied"
          className={visible ? 'animate-fade-in-down' : 'animate-fade-out-up'}
        >
          {search.access_denied}
        </Toast>
      ));

      void navigate({
        search: {
          access_denied: undefined,
        },
      });
    }
  }, [search]);
  return (
    <>
      <Meta />
      <Outlet />
      {IS_DEVELOPMENT && Devtool && (
        <>
          <Devtool />
        </>
      )}
    </>
  );
}
