import { useMemo } from 'react';

import { addMinutes, isPast } from 'date-fns';
import { atom, useAtom } from 'jotai';

export type NotificationType =
  | 'JOINED_CONNECT_SEATS_AS_MEMBER'
  | 'JOINED_CONNECT_SEATS_AS_MEMBER_WITH_REFUND'
  | 'JOINED_CONNECT_SEATS_AS_OWNER'
  | 'JOINED_CONNECT_SEATS_AS_OWNER_WITH_REFUND'
  | 'JOINED_CONNECT_WITHOUT_CARD'
  | 'ENTERPRISE_ENROLLMENT_ONGOING'
  | 'EMAIL_DOMAIN_CLAIMED';

type Notification = {
  id: NotificationType;
  snoozedUntil?: string; // datestring
};

type NotificationState = Partial<Record<NotificationType, Notification>>;

const NotificationsAtom = atom<NotificationState>({});

export const useNotifications = () => {
  const [notifications, setNotifications] = useAtom(NotificationsAtom);

  const notificationsList = useMemo(
    () => Object.values(notifications),
    [notifications]
  );

  const activeNotifications = useMemo(
    () =>
      notificationsList.filter(
        (n) => !n.snoozedUntil || isPast(n.snoozedUntil)
      ),
    [notificationsList]
  );

  return {
    active: activeNotifications,
    all: notificationsList,
    byId: notifications,
    setAll: setNotifications,
    set: (
      notification: Notification,
      options: { force?: boolean } = { force: false }
    ) => {
      if (options.force !== true && notifications[notification.id]) return;

      setNotifications({ ...notifications, [notification.id]: notification });
    },
    snooze: (id: NotificationType, minutes = 60) => {
      const now = new Date();
      const snoozedUntil = addMinutes(now, minutes).toISOString();

      setNotifications({
        ...notifications,
        [id]: { ...notifications[id], snoozedUntil },
      });
    },
    remove: (id: NotificationType) => {
      if (!notifications[id]) return;

      setNotifications(
        Object.fromEntries(
          Object.entries(notifications).filter(
            ([notificationId]) => notificationId !== id
          )
        )
      );
    },
  };
};
