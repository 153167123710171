import { useEnterpriseOrgSettings } from 'src/api/queries/enterpriseAdmin';
import { useAccessTokenClaims } from 'src/api/queries/user';
import { AppPage } from 'src/components/AppPage';
import { StartEnterpriseDomainForwardingNotification } from 'src/components/StartEnterpriseDomainForwardingNotification';
import { StartEnterpriseMigrationNotifiaction } from 'src/components/StartEnterpriseMigrationNotification';
import { TestId } from 'src/testids';
import { enterpriseHelpers } from 'src/utils/enterpriseHelpers';
import { userHelpers } from 'src/utils/userHelpers';

import { FleetTable } from './components/FleetTable';
import { Overview } from './components/Overview';

export function EnterpriseMembersPage() {
  const accessTokenClaims = useAccessTokenClaims();
  const orgSettings = useEnterpriseOrgSettings();
  const primaryDomain = enterpriseHelpers.getPrimaryDomain(orgSettings.data);

  const canUpdateOrgSettings =
    accessTokenClaims.data &&
    userHelpers.hasPermissionUpdateOrgSettings(accessTokenClaims.data);

  const canStartMigration =
    canUpdateOrgSettings &&
    !enterpriseHelpers.isMigrationEnabled(primaryDomain) &&
    !enterpriseHelpers.isDomainForwardingEnabled(primaryDomain);

  const canStartDomainForwarding =
    canUpdateOrgSettings &&
    enterpriseHelpers.isMigrationEnabled(primaryDomain) &&
    !enterpriseHelpers.isDomainForwardingEnabled(primaryDomain);

  return (
    <AppPage.Content
      className="max-w-l2xl"
      data-cy={TestId.PageEnterpriseMembers}
    >
      <div className="flex flex-col gap-32">
        {canStartMigration && <StartEnterpriseMigrationNotifiaction />}

        {canStartDomainForwarding && (
          <StartEnterpriseDomainForwardingNotification />
        )}
        <Overview />
        <FleetTable />
      </div>
    </AppPage.Content>
  );
}
