import { Tag, Typography } from '@remarkable/ark-web';
import { ArrowRight } from 'phosphor-react';

import methodsHeroOffer from 'src/assets/img/methods-hero-offer.webp';
import { Button } from 'src/components';
import { Highlight } from 'src/components/Highlight';
import { Price } from 'src/components/Price';
import { StackedSheet } from 'src/components/StackedSheet';
import { SKU } from 'src/utils/skus';
import { URLS } from 'src/utils/urls/urls';
import { valueProps } from 'src/utils/valueProps';

export const WinbackOffer = ({
  componentLocation,
}: {
  componentLocation: string;
}) => {
  const buttonText = 'Try Connect for free';
  return (
    <StackedSheet dataCy="winback-offer-landing">
      <div
        data-cy="winback-offer"
        className="flex flex-col-reverse gap-24 lxl:flex-row lxl:gap-64"
      >
        <div className="flex basis-[45%]  flex-col justify-center">
          <img
            src={methodsHeroOffer}
            alt="Multiple templates stacked on top of each other"
            className="aspect-3/4 mx-auto w-full min-w-[272px] max-w-full lxl:w-auto"
          />
        </div>
        <div className="flex basis-[55%] flex-col items-start">
          <Tag size="large" variant="info-light">
            Limited Time Offer
          </Tag>
          <Typography
            variant="fluid-heading-2xl"
            className="my-24 text-left ll:my-16 ll:whitespace-nowrap"
          >
            Claim your <Highlight scale={320}>100-day free</Highlight> trial
          </Typography>
          <Typography
            className="m-0 text-pretty text-left"
            variant="body-md-regular"
          >
            Tried Connect yet? Get exclusive templates from reMarkable Methods
            today, and take your thinking further with everything else Connect
            has to offer:
          </Typography>
          <ul className="mt-24 grid grid-cols-1 justify-between gap-16 lm:grid-cols-2 ll:mt-32">
            {Object.values(valueProps.winbackOffer).map((vp) => (
              <li
                key={vp.id}
                className="flex items-start gap-8 justify-self-start"
              >
                <vp.icon className="shrink-0" size={24} />
                <Typography
                  className="m-0 flex-1 text-left"
                  variant="body-sm-regular"
                >
                  {vp.title}
                  {vp.isNew && (
                    <Tag
                      size="medium"
                      className="ml-8 inline align-middle"
                      variant="success-light"
                    >
                      NEW
                    </Tag>
                  )}
                </Typography>
              </li>
            ))}
          </ul>
          <Button
            as="a"
            variant="primary"
            to={URLS.STORE_METHODS_OFFER_CHECKOUT}
            className="my-24 w-full lm:w-fit ll:mt-32 "
            data-cy="landing-next-step"
            analytics={{
              action: 'activate winback offer',
              location: componentLocation,
              text: buttonText,
            }}
          >
            <span data-cy="winback-offer-activate-button">{buttonText}</span>
            <ArrowRight />
          </Button>

          <Typography variant="body-sm" className="text-pretty">
            Connect costs <Price sku={SKU.connectMonthly} />
            /mo. when your free trial ends. <b>Cancel anytime</b>.
          </Typography>
        </div>
      </div>
    </StackedSheet>
  );
};
