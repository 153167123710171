import { Typography } from '@remarkable/ark-web';
import { ArrowLeft, ArrowRight } from 'phosphor-react';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import mrfStep2jpg from 'src/assets/img/mrf_step_2.png';
import mrfStep2webp from 'src/assets/img/mrf_step_2.webp';
import { ImageWithFallback } from 'src/components';
import { Link2 } from 'src/components/Link2';
import { LinkExternal } from 'src/components/LinkExternal';
import { URLS } from 'src/utils/urls/urls';

import { StepIndicator } from './StepIndicator';

export const MrfStep2 = () => {
  return (
    <div className="flex h-full flex-col justify-between" data-cy="mrf-step-2">
      <div className="grid grid-rows-2 text-center ls:p-64 ls:text-left lm:grid-cols-2 lm:grid-rows-1">
        <div>
          <Typography variant="heading-subtle-xl">You&apos;ll lose</Typography>
          <Typography variant="responsive-heading-lg" className="my-24" as="h1">
            Full backup and sync of all your notes
          </Typography>
          <Typography variant="responsive-body-sm">
            When left unused, your documents will no longer be saved to the
            cloud.
          </Typography>
          <Typography className="ls:pt-16" variant="responsive-body-sm">
            Read more about the benefits with Connect{' '}
            <LinkExternal
              inline
              to={URLS.RM_COM_STORE_CONNECT}
              componentLocation={ComponentLocations.MRF_MODAL.MRF_STEP_2}
            >
              here
            </LinkExternal>
            .
          </Typography>
        </div>

        <ImageWithFallback
          className="p-16"
          sources={[
            { url: mrfStep2jpg, type: 'image/png' },
            { url: mrfStep2webp, type: 'image/webp' },
          ]}
          alt="Full backup"
        />
      </div>

      <div className="grid w-full grid-cols-3 py-4 align-middle">
        <div>
          <Link2
            as="button"
            size="medium"
            variant="tertiary-neutral"
            to="."
            search={{ cancel_subscription: 'step-1' }}
            componentLocation={ComponentLocations.MRF_MODAL.MRF_STEP_2}
          >
            <>
              <ArrowLeft /> <span> Back</span>
            </>
          </Link2>
        </div>
        <StepIndicator numSteps={5} activeStep={1} />

        <div className="flex w-full justify-end">
          <Link2
            size="medium"
            variant="tertiary-neutral"
            data-cy="mrf-next-step-2"
            as="button"
            to="."
            search={{ cancel_subscription: 'step-3' }}
            componentLocation={ComponentLocations.MRF_MODAL.MRF_STEP_2}
          >
            <>
              <span>Next</span> <ArrowRight />
            </>
          </Link2>
        </div>
      </div>
    </div>
  );
};
