import { Typography } from '@remarkable/ark-web';
import { ArrowRight } from 'phosphor-react';

import { LinkClicked } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { ComponentLocations } from 'src/analytics/trackingTypes';
import methodsBanner from 'src/assets/img/methods-banner.webp';
import { Button } from 'src/components/Button';
import { LinkExternal } from 'src/components/LinkExternal';
import { Sheet } from 'src/components/Sheet';
import { defaultConfig } from 'src/config';

interface MethodsCardProps {
  hasSubscription: boolean;
}

const ACTIVE_DESCRIPTION = `
Your Connect subscription now includes reMarkable Methods, an exciting new space for useful templates and workbooks designed to elevate your thinking.
  `;

const INACTIVE_DESCRIPTION = `
Lead meetings, plan your week, and solve complex problems with reMarkable Methods — an exciting new space for templates and workbooks designed to elevate your thinking.
`;

export const MethodsCard = ({ hasSubscription }: MethodsCardProps) => {
  const description = hasSubscription
    ? ACTIVE_DESCRIPTION
    : INACTIVE_DESCRIPTION;
  const buttonText = hasSubscription ? 'Explore Methods' : 'Discover Methods';
  const utmCampaign = hasSubscription
    ? 'Explore reMarkable Methods'
    : 'Discover reMarkable Methods';
  const utmSource = 'front page - methods card';
  return (
    <Sheet className="flex flex-col gap-0 !p-0 ll:flex-row">
      <LinkExternal
        className="w-full flex-1 ll:w-1/2"
        to={defaultConfig.MethodsUrl}
        onClick={() => {
          tracker.trackEvent(
            new LinkClicked({
              component_location: ComponentLocations.FRONT_PAGE.METHODS_CARD,
              text: buttonText,
              link_source: defaultConfig.MethodsUrl,
            })
          );
          tracker.identify({
            myrm_utm_parameters: JSON.stringify({
              myrm_utm_source: utmSource,
              myrm_utm_campaign: utmCampaign,
            }),
          });
        }}
      >
        <img
          src={methodsBanner}
          alt="A collection of thumbnail images from methods.remarkable.com."
          className="min-h-full w-full object-cover ll:max-h-full"
        />
      </LinkExternal>

      <div className="flex flex-1 p-24">
        <div className="mx-0 flex max-w-[450px] flex-col justify-start gap-[29px] ll:mx-auto ll:justify-center">
          <div className="flex flex-col items-start gap-16">
            <Typography className="m-0" variant="interface-sm-caps-medium">
              News
            </Typography>
            <Typography className="m-0 text-left" variant="heading-lg">
              {hasSubscription
                ? 'Explore reMarkable Methods'
                : 'Discover reMarkable Methods'}
            </Typography>
          </div>
          <Typography
            className="m-0 text-left"
            textWrap="balance"
            variant="body-md-regular"
          >
            {description}
          </Typography>

          <Button
            as="a"
            size="medium"
            variant="secondary"
            to={defaultConfig.MethodsUrl}
            analytics={{
              location: ComponentLocations.FRONT_PAGE.METHODS_CARD,
              text: buttonText,
              action: 'go to methods',
            }}
            onClick={() => {
              tracker.identify({
                myrm_utm_parameters: JSON.stringify({
                  myrm_utm_source: utmSource,
                  myrm_utm_campaign: utmCampaign,
                }),
              });
            }}
          >
            <span>{buttonText}</span>
            <ArrowRight />
          </Button>
        </div>
      </div>
    </Sheet>
  );
};
