import { createFileRoute } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { z } from 'zod';

import { SubscriptionPage } from 'src/apps/manageSubscription/app/pages/SubscriptionPage';

const subscriptionsPlanSearchSchema = z.object({
  cancel_subscription: z
    .enum(['step-1', 'step-2', 'step-3', 'step-4', 'step-5', 'confirmation'])
    .optional(),
});

export const Route = createFileRoute('/_auth/_layout/subscriptions/plan')({
  validateSearch: zodValidator(subscriptionsPlanSearchSchema),
  component: () => <SubscriptionPage />,
});
