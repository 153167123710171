import { createFileRoute, redirect } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { z } from 'zod';

import { AccountMigrate } from 'src/apps/account/app/pages/AccountMigrate';
import { userHelpers } from 'src/utils/userHelpers';

const accountMigrateSearchSchema = z.object({
  confirm: z.boolean().optional(),
  accountLinkingToken: z.string().optional(),
});

export const Route = createFileRoute('/_auth/_layout/account/migrate/')({
  async beforeLoad({ context, location }) {
    const auth = await context.auth;

    if (!auth.user) {
      throw redirect({ to: '/login' });
    }

    const isNotLinkableOrCurrentlyLinking =
      !userHelpers.isLinkable(auth.user) &&
      !userHelpers.isDomainClaimedByConnection(auth.user) &&
      !location.searchStr.includes('accountLinkingToken');

    if (isNotLinkableOrCurrentlyLinking) {
      throw redirect({ to: '/' });
    }
  },
  validateSearch: zodValidator(accountMigrateSearchSchema),
  component: () => <AccountMigrate />,
});
