import { CountryCode } from 'src/api/endpoints/myRmApi.types';

import { COUNTRIES, CountryData, DEFAULT_COUNTRY } from './countryList';

/**
 * Default all countries.
 * Optionally provide supported countries.
 * Always fallback to US.
 */
export const getCountryDataForCountryCodeWithFallback = (
  countryCode: CountryCode
): CountryData => {
  const countryData = COUNTRIES.find(
    (country) => country.value === countryCode
  );

  if (countryData) {
    return countryData;
  }

  // Fallback to US
  return COUNTRIES.find(
    (country) => country.value === DEFAULT_COUNTRY
  ) as CountryData;
};
