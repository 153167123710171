import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';

import { storeApiClient } from '../endpoints/storeApi';
import { FeedbackTargetValues } from '../endpoints/storeApi.types';
import { queryKeys } from './queryKeys';

export function useFeedbackValue(targetId: FeedbackTargetValues) {
  return useMutation({
    mutationKey: queryKeys.feedback.value(targetId),
    mutationFn: async (args: { feedbackValue: boolean }) => {
      return storeApiClient
        .POST('/v1/myrm/feedback/{feedbackTarget}', {
          params: { path: { feedbackTarget: targetId } },
          body: { feedbackValue: args.feedbackValue },
        })
        .then((res) => res.data?.data ?? null);
    },
  });
}

export function useAddFeedbackComment(targetId: FeedbackTargetValues) {
  return useMutation({
    mutationKey: queryKeys.feedback.addComment(targetId),
    mutationFn: async (args: { comment: string; instanceId: string }) => {
      Sentry?.addBreadcrumb({
        category: 'Feedback',
        message: 'Added feedback comment',
      });

      return storeApiClient
        .PUT('/v1/myrm/feedback/{feedbackTarget}/{feedbackUUID}/comment', {
          params: {
            path: {
              feedbackTarget: targetId,
              feedbackUUID: args.instanceId,
            },
          },
          body: { comment: args.comment },
        })
        .then((res) => res.data ?? null);
    },
  });
}

export function useFeedbackComment(targetId: FeedbackTargetValues) {
  return useMutation({
    mutationKey: queryKeys.feedback.comment(targetId),
    mutationFn: async (args: { comment: string }) => {
      Sentry?.addBreadcrumb({
        category: 'Feedback',
        message: 'Added feedback comment',
      });

      return storeApiClient
        .POST('/v1/myrm/feedback/{feedbackTarget}/comment', {
          params: { path: { feedbackTarget: targetId } },
          body: { comment: args.comment },
        })
        .then((res) => res.data ?? null);
    },
  });
}
