import { ArrowRight, CaretRight } from '@phosphor-icons/react';
import { Tag, Typography } from '@remarkable/ark-web';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { Button } from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { Highlight } from 'src/components/Highlight';
import { Price } from 'src/components/Price';
import { SKU } from 'src/utils/skus';
import { URLS } from 'src/utils/urls/urls';
import { valueProps } from 'src/utils/valueProps';

export const ConnectOfferLandingComponent = () => {
  return (
    <>
      <AppPage.Content
        data-cy="connect-offer-landing"
        className="max-w-l2xl py-32 text-center ls:py-48 lm:py-64 ll:py-96"
      >
        <div className="flex flex-col items-center">
          <Typography variant="interface-sm-caps" className="my-0">
            Connect subscription
          </Typography>

          <Typography
            variant="responsive-heading-xl"
            className="mb-24 mt-8 text-pretty lm:mb-40 lm:mt-24"
          >
            Start your <Highlight scale={300}>100-day free</Highlight> trial
          </Typography>

          <ul className="mb-24 grid grid-cols-1 gap-x-40 gap-y-16 lm:mb-48 lm:grid-cols-2 ">
            {Object.values(valueProps.connectOfferHero).map((vp) => (
              <li key={vp.id} className="flex items-start gap-8">
                <vp.icon className="shrink-0 text-pen-blue" size={24} />
                <Typography
                  className="m-0 flex-1 text-left lm:max-w-[240px]"
                  variant="body-sm-regular"
                >
                  {vp.title}
                  {vp.isNew && <Tag className="ml-8 inline">NEW</Tag>}
                </Typography>
              </li>
            ))}
          </ul>

          <div className="flex w-full flex-wrap items-center justify-center">
            <Button
              as="a"
              to={URLS.STORE_CONNECT_OFFER_CHECKOUT}
              variant="primary"
              className="w-full ls:w-fit"
              data-cy="connect-offer-activate-button"
              analytics={{
                action: 'Activate connect',
                location:
                  ComponentLocations.CHECKOUT.CONNECT_OFFER_LANDING_MAIN,
                text: 'Start free trial',
              }}
            >
              <span>Start free trial</span> <CaretRight />
            </Button>
          </div>

          <Typography
            variant="body-sm-regular"
            className="mt-24 max-w-lm text-pretty lm:mt-48"
          >
            The offer is valid for 30 days after pairing your device. Connect
            costs <Price sku={SKU.connectMonthly} />
            /mo. when your free trial ends. <b>Cancel anytime</b>.
          </Typography>
        </div>
      </AppPage.Content>
      <AppPage.Content
        data-cy="connect-offer-perks"
        className="px:24 lm:px-100 max-w-full bg-neutral-dark-6 px-24 pb-24 text-white"
      >
        <div className="m-auto flex max-w-l2xl flex-col gap-40 py-64 lm:gap-[63px] lm:py-80">
          <Typography variant="heading-md" id="learn">
            Why activate Connect?
          </Typography>

          <ul className="grid w-full grid-cols-1 justify-between gap-32 ls:grid-cols-2 lm:gap-40 ll:grid-cols-4">
            {Object.values(valueProps.connectOfferBanner).map((vp) => (
              <li key={vp.id} className="flex flex-col gap-16">
                <div className="flex size-56 shrink-0 items-center justify-center rounded-full bg-neutral-dark-3">
                  <vp.icon size={32} className="shrink-0" />
                </div>

                <Typography variant="fluid-body-xs" fontWeight="bold">
                  {vp.title}
                  {vp.isNew && (
                    <Tag
                      size="medium"
                      variant="info-light"
                      className="ml-8 inline align-middle"
                    >
                      NEW
                    </Tag>
                  )}
                </Typography>
                <Typography variant="body-sm-regular">
                  {vp.description}
                </Typography>
              </li>
            ))}
          </ul>

          <Button
            as="a"
            to={URLS.STORE_CONNECT_OFFER_CHECKOUT}
            variant="primary"
            className="m-auto w-full ls:w-fit"
            analytics={{
              action: 'Activate connect',
              location:
                ComponentLocations.CHECKOUT.CONNECT_OFFER_LANDING_BOTTOM,
              text: 'Try it for free',
            }}
          >
            <span>Try it for free</span> <ArrowRight />
          </Button>
        </div>
      </AppPage.Content>
    </>
  );
};
