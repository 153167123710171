type Accessor<T> = (item: T) => string | undefined;

export function ensureUniqueKeys<T>(
  items: T[],
  getId: Accessor<T>
): (T & { key: string })[] {
  const idMap = new Map<string, number>();

  return items.map((item) => {
    const baseId = getId(item) || 'key';
    const count = idMap.get(baseId) || 0;
    const uniqueId = count ? `${baseId}-${count}` : baseId;

    idMap.set(baseId, count + 1);
    return { ...item, key: uniqueId };
  });
}
