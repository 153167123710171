import { useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import * as falso from '@ngneat/falso';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { useCreateUserRequest } from 'src/api/endpoints/testDataApi';
import {
  DeviceTypes,
  SubscriptionTypes,
} from 'src/api/endpoints/testDataApi.types';
import {
  Button,
  Form,
  Input,
  NotificationBox,
  Spinner,
  SplitHorizontalLayout,
} from 'src/components';

import { UserPropertiesDropdown } from './UserPropertiesDropdown';

const deviceDropdownOptions: { value: DeviceTypes; label: string }[] = [
  { value: 'rm2', label: 'reMarkable 2' },
  { value: 'rmpp', label: 'reMarkable Paper Pro' },
  { value: 'rm1', label: 'reMarkable 1' },
];

const subscriptionDropdownOptions: {
  value: SubscriptionTypes;
  label: string;
}[] = [
  { value: 'regular', label: 'Regular' },
  { value: 'lifetime', label: 'Lifetime' },
  { value: 'trial', label: 'Trial' },
];

const generateUsername = () => {
  return `7rdzp.test-service.${falso.randFirstName()}.${falso.randLastName()}@inbox.testmail.app`;
};
interface UserForm {
  username: string;
  password: string;
  devices: DeviceTypes[];
  subscription: SubscriptionTypes | null;
}

export const UserPropertiesForm = () => {
  const { loginWithRedirect } = useAuth0();
  const [createdUsers, setCreatedUsers] = useState<
    { username: string; password: string }[]
  >([]);
  const createUserMutation = useCreateUserRequest();
  const [isEditable, setIsEditable] = useState(true);

  const form = useForm<UserForm>({
    defaultValues: {
      username: generateUsername(),
      password: 'dummy-password',
      devices: [],
      subscription: null,
    },
  });

  const onSubmit = (data: UserForm) => {
    createUserMutation.mutate(
      {
        username: data.username,
        password: data.password,
        devices: data.devices,
        subscription: data.subscription,
      },
      {
        onSuccess: () => {
          setIsEditable(false);
          toast.success('User created successfully');
          setCreatedUsers([
            ...createdUsers,
            { username: data.username, password: data.password },
          ]);
        },
        onError: (error) => {
          form.setError('root', { message: error.message });
        },
      }
    );
  };

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: { returnTo: '/' },
      authorizationParams: {
        prompt: 'login',
        login_hint: form.getValues('username'),
      },
    });
  };

  if (createUserMutation.isPending) {
    return <Spinner />;
  }

  return (
    <>
      <Form onSubmit={onSubmit} {...form}>
        <SplitHorizontalLayout className="w-full gap-4">
          <Input label="Username" name="username" isEditable={isEditable} />
          <Input label="Password" name="password" isEditable={isEditable} />
        </SplitHorizontalLayout>

        <SplitHorizontalLayout className="gap-4 py-20">
          <Controller
            name="devices"
            control={form.control}
            render={({ field }) => {
              const selectedDevices = field.value || []; // Ensure it's always an array
              return (
                <UserPropertiesDropdown
                  className="w-full"
                  label="Select devices (optional)"
                  selected={selectedDevices}
                  dropdownOptions={deviceDropdownOptions}
                  onCheckedChange={(item, checked) =>
                    field.onChange(
                      checked
                        ? [...selectedDevices, item]
                        : selectedDevices.filter((i) => i !== item)
                    )
                  }
                  disabled={!isEditable}
                />
              );
            }}
          />

          <Controller
            name="subscription"
            control={form.control}
            render={({ field }) => {
              const selectedSubscription = field.value;

              return (
                <UserPropertiesDropdown
                  className="w-full"
                  label="Select subscription (optional)"
                  selected={
                    selectedSubscription ? [selectedSubscription] : null
                  }
                  dropdownOptions={subscriptionDropdownOptions}
                  onCheckedChange={(item, checked) =>
                    field.onChange(checked ? item : null)
                  }
                  disabled={!isEditable}
                />
              );
            }}
          />
        </SplitHorizontalLayout>

        {isEditable && (
          <div className="py-20">
            <Button data-cy="submit-button" type="submit">
              Create User
            </Button>
          </div>
        )}
      </Form>

      {createUserMutation.isError && (
        <>
          <NotificationBox
            title="Something went wrong when creating the user"
            variant="error"
          >
            {form.formState.errors.root?.message}
          </NotificationBox>
          <Button
            variant="secondary"
            onClick={() => {
              form.reset();
              form.setValue('username', generateUsername());
            }}
          >
            Reload form
          </Button>
        </>
      )}

      {createUserMutation.isSuccess && !isEditable && (
        <>
          <SplitHorizontalLayout className="gap-4 py-20">
            <Button variant="primary" onClick={() => handleLogin()}>
              Login with created user
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                form.reset();
                form.setValue('username', generateUsername());
                setIsEditable(true);
              }}
            >
              Create new user
            </Button>
          </SplitHorizontalLayout>
        </>
      )}
    </>
  );
};
