import { useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Check, X } from '@phosphor-icons/react';
import { Typography } from '@remarkable/ark-web';
import { useNavigate } from '@tanstack/react-router';
import toast from 'react-hot-toast';

import {
  useEnterpriseEnrollmentStatus,
  useEnterpriseFinalize,
} from 'src/api/queries/enterpriseEnrollment';
import { useAuth0Claims } from 'src/api/queries/user';
import { Button, Modal, NotificationBox, Sheet } from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { Link2 } from 'src/components/Link2';
import { RadioButton } from 'src/components/RadioButton';
import { URLS } from 'src/utils/urls/urls';
import { userHelpers } from 'src/utils/userHelpers';

import { CancelSetupModal } from '../components/CancelSetup';
import { EnterpriseSteps } from '../components/EnterpriseSteps';

type FinalizeMethod =
  | 'NoTestingPeriod'
  | 'WithMigrationPeriod'
  | 'WithTestingPeriod';

export function ConfirmSetupPage() {
  const enrollmentStatus = useEnterpriseEnrollmentStatus();
  const finalizeEnrollment = useEnterpriseFinalize();
  const [showMigrationModal, setShowMigrationModal] = useState<boolean>(false);

  const navigate = useNavigate();
  const [finalizeMethod, setFinalizeMethod] =
    useState<FinalizeMethod>('WithTestingPeriod');

  const auth = useAuth0();
  const claims = useAuth0Claims();

  const enableMigrationModal = finalizeMethod === 'WithMigrationPeriod';
  const isLoggedInToSSO = !!claims.data?.org_id;

  const handleFinalize = () => {
    if (
      typeof enrollmentStatus.data?.connectionId !== 'string' ||
      !enrollmentStatus.data.primaryDomain
    )
      return;

    finalizeEnrollment.mutate(
      {
        domain: enrollmentStatus.data.primaryDomain,
        withDomainForwarding: finalizeMethod === 'NoTestingPeriod',
        withMigration:
          finalizeMethod === 'NoTestingPeriod' ||
          finalizeMethod === 'WithMigrationPeriod',
      },
      {
        async onSuccess() {
          const organization = auth.user
            ? userHelpers.getOrganizationId(auth.user)
            : undefined;

          toast.success('Enterprise enrollment complete!');

          return navigate({
            to: '/login',
            search: {
              prompt: 'none',
              redirect: '/enterprise',
              organization,
            },
          });
        },
      }
    );
  };

  return (
    <AppPage.Container>
      <AppPage.Header title="Single sign-on setup" />
      <AppPage.Content className="max-w-ll">
        <EnterpriseSteps />

        <Sheet className="gap-24">
          <div className="flex items-center justify-between gap-16">
            <Typography as="h2" variant="heading-sm">
              Add users
            </Typography>
          </div>

          <div className="flex justify-between gap-24 border-y py-16">
            <Typography variant="body-md-regular">
              Owner: {auth.user?.email ?? 'No email'}
            </Typography>

            {isLoggedInToSSO ? (
              <div className="flex items-center gap-8 text-pen-blue">
                <Check size={24} />
                <span>Verified</span>
              </div>
            ) : (
              <div className="flex items-center gap-8 text-neutral-dark-1">
                <X size={24} />
                <span>Not verified</span>
              </div>
            )}
          </div>

          <Typography variant="body-md-regular">
            Your SAML setup is complete! Next, manage user access through your
            identity provider (IdP) with one of the following options:
          </Typography>

          <div className="flex flex-col gap-16">
            <RadioButton
              disabled={enrollmentStatus.data?.enrollment === 'FINALIZED'}
              checked={finalizeMethod === 'WithTestingPeriod'}
              name="finalizeMethod"
              onChange={() => setFinalizeMethod('WithTestingPeriod')}
            >
              <Typography variant="body-xl-regular">
                Invite a limited set of users for testing
              </Typography>
              <Typography variant="body-sm-regular">
                Add users to your identity provider (IdP) and invite a limited
                set of users to test the SSO solution before going company-wide.
                Once you&apos;re ready, you can start the migration period.
              </Typography>
            </RadioButton>
            <RadioButton
              disabled={enrollmentStatus.data?.enrollment === 'FINALIZED'}
              checked={finalizeMethod === 'WithMigrationPeriod'}
              name="finalizeMethod"
              onChange={() => setFinalizeMethod('WithMigrationPeriod')}
            >
              <Typography variant="body-xl-regular">
                Start migration and mark all users as claimed
              </Typography>
              <Typography variant="body-sm-regular">
                All users with email addresses under your domain are flagged for
                migration, regardless of whether they are added to your identity
                provider (IdP) or not. During this period, users will access
                their existing accounts and see a banners prompting them to
                migrate to the new SSO system.
              </Typography>
            </RadioButton>
          </div>

          <div className="flex w-full flex-col-reverse flex-wrap-reverse gap-24 lm:flex-row">
            <div className="flex flex-1 ls:justify-center lm:justify-start">
              <CancelSetupModal
                trigger={
                  <Button
                    variant="tertiary-neutral"
                    className="w-full ls:w-fit"
                  >
                    Cancel
                  </Button>
                }
              />
            </div>

            <div className="flex flex-col-reverse flex-wrap-reverse gap-24 ls:flex-row">
              <Button
                as="a"
                variant="secondary"
                to={URLS.ENTERPRISE_VERIFY_SAML}
                className="w-full ls:w-fit ls:flex-1 lm:flex-initial"
              >
                Previous
              </Button>

              {enrollmentStatus.data?.enrollment === 'FINALIZED' ? (
                <Link2
                  as="button"
                  to={URLS.ENTERPRISE}
                  variant="primary"
                  className="w-full ls:w-fit ls:flex-1 lm:flex-initial"
                >
                  Go to Overview
                </Link2>
              ) : (
                <Modal.Root
                  open={showMigrationModal}
                  onOpenChange={(open) => {
                    if (!enableMigrationModal) {
                      setShowMigrationModal(false);
                      return;
                    }

                    finalizeEnrollment.reset();

                    setShowMigrationModal(open);
                  }}
                >
                  <Modal.Trigger asChild>
                    <Button
                      variant="primary"
                      disabled={finalizeEnrollment.isPending}
                      loading={finalizeEnrollment.isPending}
                      className="w-full ls:w-fit ls:flex-1 lm:flex-initial"
                      onClick={() => {
                        if (
                          typeof enrollmentStatus.data?.connectionId !==
                          'string'
                        )
                          return;

                        if (!enableMigrationModal) {
                          handleFinalize();
                        }
                      }}
                    >
                      Complete
                    </Button>
                  </Modal.Trigger>

                  <Modal.Content className="flex max-w-3xl flex-col gap-24">
                    <Typography variant="heading-lg" as="h2">
                      Are you sure you want to continue?
                    </Typography>
                    <Typography variant="body-md-regular">
                      Users with email addresses under your domain, whether
                      added to your identity provider (IdP) or not, will be
                      flagged for migration and notified by email. They can
                      continue accessing their accounts but will see a banner
                      prompting them to switch to the new SSO system.
                    </Typography>

                    <NotificationBox
                      variant="warning"
                      title="Make sure users are added to the IdP before starting the migration phase"
                    >
                      If you have not added users to your IdP, they will not be
                      able complete the migration, as they will be unable to
                      authenticate through SSO.
                    </NotificationBox>

                    <div className="flex justify-center gap-24">
                      <Modal.Close asChild>
                        <Button variant="secondary">Cancel</Button>
                      </Modal.Close>
                      <Button
                        variant="primary"
                        disabled={finalizeEnrollment.isPending}
                        loading={finalizeEnrollment.isPending}
                        onClick={() => {
                          handleFinalize();
                        }}
                      >
                        Continue
                      </Button>
                    </div>

                    <NotificationBox error={finalizeEnrollment.error} />
                  </Modal.Content>
                </Modal.Root>
              )}
            </div>
          </div>

          <NotificationBox error={finalizeEnrollment.error} />
        </Sheet>
      </AppPage.Content>
    </AppPage.Container>
  );
}
