import {
  ClientInformation,
  DeviceV1,
  ProfileDeviceV1,
} from 'src/api/endpoints/cloudApi.types';
import { FleetDevice } from 'src/api/endpoints/cloudEnterprise.types';

export const DeviceType = {
  RM1: 'RM1',
  RM2: 'RM2',
  RMPP: 'RMPP',
  RM_UNKNOWN: 'RM_UNKNOWN',
  DESKTOP_LINUX: 'DESKTOP_LINUX',
  DESKTOP_WINDOWS: 'DESKTOP_WINDOWS',
  DESKTOP_MACOS: 'DESKTOP_MACOS',
  DESKTOP_UNKNOWN: 'DESKTOP_UNKNOWN',
  MOBILE_IOS: 'MOBILE_IOS',
  MOBILE_ANDROID: 'MOBILE_ANDROID',
  MOBILE_UNKNOWN: 'MOBILE_UNKNOWN',
  BROWSER_CHROME: 'BROWSER_CHROME',
  BROWSER_UNKNOWN: 'BROWSER_UNKNOWN',
  WORD_EXTENSION: 'WORD_EXTENSION',
  POWERPOINT_EXTENSION: 'POWERPOINT_EXTENSION',
  UNKNOWN: 'UNKNOWN',
} as const;

export type DeviceType = (typeof DeviceType)[keyof typeof DeviceType];

export type MinimalDevice = {
  type?: string;
  serialNumber?: string;
};

export type AnyDevice =
  | MinimalDevice
  | DeviceV1
  | ProfileDeviceV1
  | ClientInformation
  | FleetDevice;

function getDeviceTypeFromSerialNumber(
  serialNumber: string,
  fallback?: DeviceType
): DeviceType {
  if (serialNumber.startsWith('RM10')) {
    return DeviceType.RM1;
  }
  if (serialNumber.startsWith('RM11')) {
    return DeviceType.RM2;
  }
  if (serialNumber.startsWith('RM02')) {
    return DeviceType.RMPP;
  }

  return fallback ?? DeviceType.UNKNOWN;
}

function getDeviceType(device: AnyDevice): DeviceType {
  const { type = '', serialNumber = '' } = convertToMinimalDevice(device);
  switch (type) {
    case 'desktop-linux':
      return DeviceType.DESKTOP_LINUX;
    case 'desktop-macos':
      return DeviceType.DESKTOP_MACOS;
    case 'desktop-windows':
      return DeviceType.DESKTOP_WINDOWS;
    case 'mobile-android':
      return DeviceType.MOBILE_ANDROID;
    case 'mobile-ios':
      return DeviceType.MOBILE_IOS;
    case 'browser-chrome':
      return DeviceType.BROWSER_CHROME;
    case 'remarkable':
      return getDeviceTypeFromSerialNumber(serialNumber, DeviceType.RM_UNKNOWN);
    case 'remarkable-rm1':
      return getDeviceTypeFromSerialNumber(serialNumber, DeviceType.RM1);
    case 'remarkable-rm2':
      return getDeviceTypeFromSerialNumber(serialNumber, DeviceType.RM2);
    case 'remarkable-ferrari':
      return getDeviceTypeFromSerialNumber(serialNumber, DeviceType.RMPP);
    default: {
      if (type.includes('word')) {
        return DeviceType.WORD_EXTENSION;
      }

      if (type.includes('powerpoint')) {
        return DeviceType.POWERPOINT_EXTENSION;
      }

      if (type.includes('desktop')) {
        return DeviceType.DESKTOP_UNKNOWN;
      }

      if (type.includes('mobile')) {
        return DeviceType.MOBILE_UNKNOWN;
      }

      if (type.includes('extension') || type.includes('browser')) {
        return DeviceType.BROWSER_UNKNOWN;
      }

      if (type.includes('remarkable')) {
        return getDeviceTypeFromSerialNumber(
          serialNumber,
          DeviceType.RM_UNKNOWN
        );
      }

      if (type.includes('emulator')) {
        return getDeviceTypeFromSerialNumber(
          serialNumber,
          DeviceType.RM_UNKNOWN
        );
      }

      return getDeviceTypeFromSerialNumber(serialNumber);
    }
  }
}

function isPaperTablet(device: AnyDevice): boolean {
  switch (getDeviceType(device)) {
    case DeviceType.RM1:
    case DeviceType.RM2:
    case DeviceType.RMPP:
    case DeviceType.RM_UNKNOWN:
      return true;
    default:
      return false;
  }
}

function isMobileApp(device: AnyDevice): boolean {
  switch (getDeviceType(device)) {
    case DeviceType.MOBILE_ANDROID:
    case DeviceType.MOBILE_IOS:
    case DeviceType.MOBILE_UNKNOWN:
      return true;
    default:
      return false;
  }
}

function isDesktopApp(device: AnyDevice): boolean {
  switch (getDeviceType(device)) {
    case DeviceType.DESKTOP_LINUX:
    case DeviceType.DESKTOP_MACOS:
    case DeviceType.DESKTOP_WINDOWS:
    case DeviceType.DESKTOP_UNKNOWN:
      return true;
    default:
      return false;
  }
}

function isApp(device: AnyDevice): boolean {
  return isMobileApp(device) || isDesktopApp(device);
}

function isBrowserExtension(device: AnyDevice): boolean {
  switch (getDeviceType(device)) {
    case DeviceType.BROWSER_CHROME:
    case DeviceType.BROWSER_UNKNOWN:
      return true;
    default:
      return false;
  }
}

function isEmulator(device: AnyDevice): boolean {
  const { type = '' } = convertToMinimalDevice(device);

  return type.includes('emulator');
}

function getDeviceNameFromDeviceType(deviceType: DeviceType): string {
  switch (deviceType) {
    case DeviceType.RM1:
      return 'reMarkable 1';
    case DeviceType.RM2:
      return 'reMarkable 2';
    case DeviceType.RMPP:
      return 'reMarkable Paper Pro';
    case DeviceType.RM_UNKNOWN:
      return 'reMarkable';
    case DeviceType.DESKTOP_LINUX:
      return 'Linux app';
    case DeviceType.DESKTOP_MACOS:
      return 'MacOS app';
    case DeviceType.DESKTOP_WINDOWS:
      return 'Windows app';
    case DeviceType.DESKTOP_UNKNOWN:
      return 'Desktop app';
    case DeviceType.MOBILE_ANDROID:
      return 'Android app';
    case DeviceType.MOBILE_IOS:
      return 'iOS app';
    case DeviceType.MOBILE_UNKNOWN:
      return 'Mobile app';
    case DeviceType.BROWSER_CHROME:
      return 'Chrome extension';
    case DeviceType.BROWSER_UNKNOWN:
      return 'Browser extension';
    case DeviceType.WORD_EXTENSION:
      return 'Word extension';
    case DeviceType.POWERPOINT_EXTENSION:
      return 'PowerPoint extension';
    case DeviceType.UNKNOWN:
    default:
      return 'Unknown device';
  }
}

const getDeviceName = (device: AnyDevice) =>
  getDeviceNameFromDeviceType(getDeviceType(device));

const convertDeviceV1ToMinimalDevice = (device: DeviceV1): MinimalDevice => ({
  type: device.DeviceDesc,
  serialNumber: device.DeviceID,
});

const convertDeviceV2ToMinimalDevice = (
  device: ProfileDeviceV1
): MinimalDevice => ({
  type: device.deviceType,
  serialNumber: device.deviceId,
});

const convertClientInformationToMinimalDevice = (
  clientInformation: ClientInformation
): MinimalDevice => ({
  type: clientInformation.clientType,
  serialNumber: clientInformation.clientId,
});

const convertToMinimalDevice = (device: AnyDevice): MinimalDevice => {
  if ('DeviceDesc' in device) {
    return convertDeviceV1ToMinimalDevice(device);
  }

  if ('deviceType' in device) {
    return convertDeviceV2ToMinimalDevice(device);
  }

  if ('clientType' in device) {
    return convertClientInformationToMinimalDevice(device);
  }

  return device; // FleetDevice and MinimalDevice are the same
};

export const deviceHelpers = {
  convertToMinimalDevice,
  getDeviceName,
  getDeviceNameFromDeviceType,
  getDeviceType,
  isApp,
  isBrowserExtension,
  isDesktopApp,
  isEmulator,
  isMobileApp,
  isPaperTablet,
};
