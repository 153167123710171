import { Typography } from '@remarkable/ark-web';
import { Info } from 'phosphor-react';

import { cn } from 'src/utils/classNamesHelper';
import { COUNTRIES } from 'src/utils/countryList';

import { Input, InputProps } from './Input';
import { Tooltip } from './Tooltip';

interface InputTaxIdProps {
  country?: string;
}

export function InputTaxId({
  country,
  className,
  ...props
}: InputTaxIdProps & Partial<InputProps>) {
  const countryData = COUNTRIES.find((c) => c.value === country);
  const taxIds = Object.values(countryData?.taxIds || {});

  return (
    <Input
      data-cy="billing-tax-id"
      name="VAT"
      label="Tax ID number"
      className={cn('min-w-0', className)}
      placeholder={`${taxIds
        .slice(0, 4)
        .map((taxId) => taxId.example)
        .join(', ')}${taxIds.length > 4 ? ', ...' : ''}`}
      description={
        taxIds[0] && (
          <div className="flex w-full justify-between">
            <Typography
              variant="interface-xs-regular"
              className="my-2 truncate leading-4"
            >
              {taxIds[0].description}
              {taxIds[1] && <>, {taxIds[1].description}</>}
              {taxIds.length > 2 ? (
                <>
                  , or {taxIds.length - 2}
                  {taxIds.length - 2 === 1 ? ' other' : ' others'}
                </>
              ) : (
                ''
              )}
            </Typography>
            {taxIds.length > 1 && (
              <Tooltip
                title={
                  <span className="text-white">
                    <ul>
                      {taxIds.map((taxId) => (
                        <li key={taxId.id}>
                          <Typography
                            variant="interface-sm-regular"
                            className="text-white"
                          >
                            {taxId.description} - {taxId.example}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  </span>
                }
              >
                <Info size={20} className="shrink-0" />
              </Tooltip>
            )}
          </div>
        )
      }
      {...props}
    />
  );
}
