import { Spinner as ArkSpinner } from '@remarkable/ark-web';

export const Spinner = ({ inline }: { inline?: boolean }) => {
  if (inline) {
    return (
      <span data-cy="spinner" className="mx-4 inline-block w-fit">
        <ArkSpinner size="small" />
      </span>
    );
  }

  return (
    <span data-cy="spinner" className="m-auto flex w-fit">
      <ArkSpinner size="large" />
    </span>
  );
};
