import { useState } from 'react';

import { Typography } from '@remarkable/ark-web';
import { getRouteApi } from '@tanstack/react-router';
import { ArrowLeft, ArrowRight } from 'phosphor-react';

import {
  ButtonClicked,
  SubscriptionCancellationFlowCompleted,
} from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useCancelSubscription } from 'src/api/queries';
import { Button } from 'src/components';
import { Link2 } from 'src/components/Link2';

import { MrfSurvey } from './MrfSurvey';
import { StepIndicator } from './StepIndicator';

const routeApi = getRouteApi('/_auth/_layout/subscriptions/plan');

export const MrfStep5 = () => {
  const navigate = routeApi.useNavigate();
  const [selectedSurveyAnswer, setSelectedSurveyAnswer] = useState<
    string | null
  >(null);

  const cancelSubscription = useCancelSubscription();

  const handleCancelConnect = () => {
    tracker.trackEvent(
      new ButtonClicked({
        component_location: ComponentLocations.SURVEY.ANSWER,
        text: selectedSurveyAnswer || '',
        action: 'respond to survey',
      })
    );
    tracker.trackEvent(
      new SubscriptionCancellationFlowCompleted({
        component_location: ComponentLocations.MRF_MODAL.MRF_STEP_5,
        total_steps: 5,
        step_number: 5,
        cancellation_reason: selectedSurveyAnswer || '',
      })
    );
    cancelSubscription.mutate(undefined, {
      onSuccess: () => {
        return navigate({ search: { cancel_subscription: 'confirmation' } });
      },
    });
  };

  return (
    <div
      className="flex h-full flex-col items-center justify-between text-center"
      data-cy="mrf-step-5"
    >
      <div className="ls:pt-64">
        <Typography variant="heading-subtle-xl">One last thing</Typography>
        <Typography variant="responsive-heading-lg" className="my-24" as="h1">
          Can you tell us why you&apos;re leaving?
        </Typography>
      </div>
      <MrfSurvey
        selectedAnswer={selectedSurveyAnswer}
        setSelectedAnswer={setSelectedSurveyAnswer}
      />
      <div className="w-full">
        <StepIndicator className="mb-8 ls:hidden" numSteps={5} activeStep={4} />
        <div className="flex w-full flex-row justify-between py-4 align-middle ls:grid ls:grid-cols-3">
          <Link2
            as="button"
            size="medium"
            variant="tertiary-neutral"
            to="."
            search={{ cancel_subscription: 'step-4' }}
            componentLocation={ComponentLocations.MRF_MODAL.MRF_STEP_5}
          >
            <>
              <ArrowLeft /> <span> Back</span>
            </>
          </Link2>

          <StepIndicator
            className="hidden ls:flex"
            numSteps={5}
            activeStep={4}
          />

          <div className="flex w-auto  justify-end">
            <Button
              disabled={selectedSurveyAnswer === null}
              size="medium"
              as="button"
              variant="tertiary-neutral"
              data-cy="mrf-cancel-subscription"
              onClick={handleCancelConnect}
              loading={cancelSubscription.isPending}
            >
              <>
                <span>Cancel subscription</span> <ArrowRight />
              </>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
