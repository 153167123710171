import { ImgHTMLAttributes, useMemo } from 'react';

import { Cloud } from '@phosphor-icons/react';

import { useIntegrationProviders } from 'src/api/queries';

export function IntegrationIcon({
  iconId,
  provider,
  ...props
}: ImgHTMLAttributes<HTMLImageElement> & {
  iconId?: string;
  provider?: string;
}) {
  const integrationProviders = useIntegrationProviders();

  const providerData = useMemo(() => {
    if (!integrationProviders.data) return null;
    return integrationProviders.data.find((p) => p.typeName === provider);
  }, [integrationProviders.data]);

  if (integrationProviders.isLoading) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return <Cloud size={24} {...(props as any)} />;
  }

  if (iconId) {
    // eslint-disable-next-line jsx-a11y/alt-text
    return <img src={`/${iconId}.svg`} {...props} />;
  }

  if (!providerData) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return <Cloud size={24} {...(props as any)} />;
  }

  // eslint-disable-next-line jsx-a11y/alt-text
  return <img src={`/${providerData.iconId}.svg`} {...props} />;
}
