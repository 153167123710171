import { useMemo } from 'react';

import { Typography } from '@remarkable/ark-web';

import { Subscription } from 'src/api/endpoints/storeApi.types';
import { useSubscription } from 'src/api/queries';
import { Divider, Label } from 'src/components';
import { StatusTag } from 'src/components/StatusTag';
import { formatDate } from 'src/utils/formatDate';
import { subscriptionHelpers } from 'src/utils/subscriptionHelpers';
import { subscriptionTrialWillCancel } from 'src/utils/subscriptionTrialWillCancel';
import { valueProps } from 'src/utils/valueProps';

import { CardHeading } from './CardHeading';

const getActivationDate = (sub?: Subscription) => {
  switch (sub?.type) {
    case 'stripe':
      return formatDate(sub.createdAt);
    case 'free-connect':
      return null; // TODO: Update this when backend ships the data
    default:
      return null;
  }
};

const perks = [
  [valueProps.connect.unlimitedCloudStorage, valueProps.connect.automaticSync],
  [
    valueProps.connect.createAndEditInOurApps,
    valueProps.connect.remarkableProtectionPlan,
  ],
];

export const ConnectSimpleDetails = () => {
  const subscription = useSubscription();
  const activationDate = getActivationDate(subscription.data);

  const endDate = useMemo(() => {
    if (!subscription.data || subscription.data.type !== 'stripe') return null;

    if (
      subscriptionHelpers.isDeactivated(subscription.data) ||
      subscriptionHelpers.isCancelled(subscription.data)
    ) {
      return `. Ends ${formatDate(
        subscription.data.paymentInformation.cancellationAt
      )}`;
    }
    if (subscriptionTrialWillCancel(subscription.data)) {
      return `. Trial ends ${formatDate(
        subscription.data.paymentInformation?.nextInvoice?.dueDate
      )}`;
    }
    return null;
  }, [subscription.data]);

  return (
    <div data-cy="subscription-card-activated">
      <div className="flex flex-row items-start justify-between gap-8">
        <div>
          <CardHeading>Connect</CardHeading>
          <Label
            className="inline-block"
            data-cy="subscription-card-activated-label"
          >
            Activated {activationDate} {endDate}
          </Label>
        </div>

        <StatusTag size="fluid" subscription={subscription.data} />
      </div>

      <Divider className="my-16" />

      <div>
        <Typography variant="body-lg-regular">
          Enjoy seamless note-taking with unlimited cloud storage and sync, and
          up to three years added device protection.
        </Typography>

        <div
          data-cy="connect-offer-perks"
          className="mt-24 flex flex-col gap-16 md:flex-row"
        >
          {perks.map((list, index) => (
            <ul
              className="m-0 inline-block w-full list-none p-0 md:w-1/2"
              key={index}
            >
              {list.map((item) => (
                <li key={item.id} className="mb-8 flex items-start gap-8 p-0">
                  <item.icon size={24} className="flex-none text-pen-blue" />
                  <p className="m-0 text-left">{item.title}</p>
                </li>
              ))}
            </ul>
          ))}
        </div>
      </div>
    </div>
  );
};
