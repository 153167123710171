import { Sheet } from 'src/components';

import { CardHeading } from './CardHeading';
import { NextBillingDetails } from './NextBillingDetails';

export const NextBillingCard = () => {
  return (
    <Sheet className="gap-24">
      <CardHeading>Next billing</CardHeading>

      <NextBillingDetails />
    </Sheet>
  );
};
