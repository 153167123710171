import * as Sentry from '@sentry/react';
import { createFileRoute, invariant, redirect } from '@tanstack/react-router';

import { queryEnterpriseEnrollmentStatus } from 'src/api/queries/enterpriseEnrollment';
import { AccessDeniedError } from 'src/utils/errors';
import { LogCategory } from 'src/utils/logger.categories';
import { userHelpers } from 'src/utils/userHelpers';

export const Route = createFileRoute('/_auth/_layout/enterprise/_with-org')({
  async beforeLoad({ context }) {
    const auth = await context.auth;

    invariant(auth.user, 'User not found');

    if (userHelpers.isLoggedInToOrganization(auth.user)) {
      return;
    }

    if (userHelpers.hasActiveEnterpriseEnrollment(auth.user)) {
      // We can't fully trust the ID Token to be up to date, so we need to
      // double check the enrollment status with the server.
      const enrollmentStatus = await context.queryClient.ensureQueryData(
        queryEnterpriseEnrollmentStatus()
      );

      const doesNotHaveActiveEnrollment =
        !enrollmentStatus ||
        enrollmentStatus.enrollment === 'FINALIZED' ||
        enrollmentStatus.enrollment === 'CANCELLED';

      if (doesNotHaveActiveEnrollment) {
        Sentry.addBreadcrumb({
          category: LogCategory.Auth,
          message:
            'User has active enterprise enrollment claim, but no active enrollment found, allowing access',
        });
        return;
      }

      Sentry.addBreadcrumb({
        category: LogCategory.Auth,
        message: 'User has active enterprise enrollment, redirecting to enroll',
      });
      throw redirect({
        from: '/enterprise',
        to: '/enterprise/enroll',
        replace: true,
      });
    }

    Sentry.addBreadcrumb({
      category: LogCategory.Auth,
      message: '_with-org: Access Denied: User is not an organization member',
    });

    throw new AccessDeniedError(
      'Only users in an organization can access this page.'
    );
  },
});
