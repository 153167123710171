import { useRef } from 'react';

import { Typography } from '@remarkable/ark-web';
import { ArrowRight } from 'phosphor-react';

import { CheckoutType } from 'src/api/endpoints/storeApi.types';
import { getCheckoutUrl } from 'src/apps/checkout/app/useCheckoutType';
import offerImage from 'src/assets/img/rM_Pro_connect_offer.webp';
import { Button } from 'src/components';
import { ImageWithFallback } from 'src/components/ImageWithFallback';
import { UxSignals } from 'src/components/UxSignals/UxSignals';
import { valueProps } from 'src/utils/valueProps';

export const ActivateConnectOffer = ({
  type,
  title,
  text,
  buttonText,
  analytics,
}: {
  type: CheckoutType;
  title: string;
  text: string;
  buttonText?: string;
  buttonFixed?: boolean;
  analytics?: {
    text: string;
    location: string;
    action: string;
  };
}) => {
  const ctaRef = useRef<HTMLAnchorElement>(null);

  // todo: Add missing value prop. Omitted to not conflict with new checkout flow designs
  const listItems = [
    [
      valueProps.connectTestA.unlimitedCloudStorage,
      valueProps.connectTestA.mobileAndDesktopNoteTaking,
    ],
    [
      valueProps.connectTestA.automaticSync,
      valueProps.connectTestA.remarkableProtectionPlan,
    ],
  ];

  return (
    <>
      <div
        data-cy="connect-offer-landing"
        className="flex flex-col-reverse items-center px-8 py-32 lm:flex-col lm:px-0 ll:flex-row ll:py-80"
      >
        <div className="w-full ll:block ll:w-1/2 ll:pt-0">
          <ImageWithFallback
            className="flex justify-center"
            imgClassName="ll:w-full ll:object-cover max-h-[300px] ll:max-h-full"
            sources={[
              {
                type: 'webp',
                url: offerImage,
              },
            ]}
          />
        </div>
        <div className="flex items-center justify-center gap-16 ll:w-1/2">
          <div className="flex flex-col gap-16 text-left">
            <h1 className="heading-md text-pretty ll:heading-lg">{title}</h1>
            <Typography variant="body-md-regular" className="text-balance">
              {text}
            </Typography>

            <div
              data-cy="connect-offer-perks"
              className="flex w-full flex-col items-start justify-center gap-16 text-ink-4 ls:flex-row ll:flex-col lxl:flex-row"
            >
              {listItems.map((list, index) => (
                <ul
                  className="flex w-full flex-col gap-16 ls:w-1/2 ll:w-full lxl:w-1/2"
                  key={index}
                >
                  {list.map((item) => (
                    <li
                      key={item.title}
                      className="flex items-center gap-8 py-2"
                    >
                      <item.icon size={24} className="shrink-0 text-pen-blue" />

                      <Typography
                        variant="interface-sm-regular"
                        className="m-0 text-left leading-125"
                      >
                        {item.title}
                      </Typography>
                    </li>
                  ))}
                </ul>
              ))}
            </div>
            <div className="relative flex flex-col gap-16 ll:flex-row">
              <Button
                //eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
                ref={ctaRef as any}
                as="a"
                variant="primary"
                to={getCheckoutUrl(type)}
                className="my-20 w-full ll:w-fit"
                data-cy="landing-next-step"
                analytics={analytics}
              >
                <span data-cy={`${type}-activate-button`}>{buttonText}</span>
                <ArrowRight />
              </Button>
              <UxSignals id="panel-t75t5wiv7p" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
