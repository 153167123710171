import { useEffect, useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { useNavigate, useSearch } from '@tanstack/react-router';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useStripeSubscription } from 'src/api/queries';
import {
  Button,
  ChangeCreditCardButton,
  NotificationBox,
} from 'src/components';
import { stripePromise } from 'src/stripePromise';
import { subscriptionHelpers } from 'src/utils/subscriptionHelpers';
import { useReauthorizePaymentMethod } from 'src/utils/useReauthorizePaymentMethod';

import { CardHeading } from './CardHeading';
import { CreditCardDetails } from './CreditCardDetails';
import { PayPalDetails } from './PayPalDetails';
import { PaymentNotifications } from './PaymentNotifications';

export const PaymentMethod = () => {
  return (
    <Elements stripe={stripePromise}>
      <WithStripeElements />
    </Elements>
  );
};

const WithStripeElements = () => {
  const subscription = useStripeSubscription();
  const reauthorizePayementMethod = useReauthorizePaymentMethod();
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const clientSecret =
    subscription.data?.paymentInformation?.requiresAction?.clientSecret;
  const { redirect_status } = useSearch({ strict: false });
  useEffect(() => {
    if (redirect_status === 'failed') {
      setError(true);
      void navigate({ to: '', search: '', replace: true });
    }
  }, [redirect_status]);

  return (
    <div className="flex flex-col gap-16">
      <div className="flex items-start gap-8 xs:justify-between">
        <CardHeading>Payment method</CardHeading>
      </div>

      <div className="flex flex-col gap-16 lm:flex-row lm:items-start">
        {subscriptionHelpers.isStripe(subscription.data) &&
        subscription.data.paymentInformation?.payment?.type == 'paypal' ? (
          <PayPalDetails />
        ) : (
          <CreditCardDetails />
        )}

        <div className="flex flex-col items-end gap-16 ls:flex-row ls:flex-wrap lm:flex-col">
          <ChangeCreditCardButton
            componentLocation={ComponentLocations.MANAGE_CONNECT.PAYMENT_PAGE}
          />

          {subscription.data?.access.canSeeFailedPaymentInformation &&
            subscription.data.paymentInformation?.requiresAction &&
            clientSecret && (
              <Elements stripe={stripePromise}>
                <Button
                  data-cy="reauthorize-payment-method-button"
                  size="medium"
                  loading={reauthorizePayementMethod.isPending}
                  onClick={() => reauthorizePayementMethod.mutate(clientSecret)}
                  className="w-full text-nowrap ls:w-fit"
                  analytics={{
                    text: 'Authorize payment',
                    location: ComponentLocations.MANAGE_CONNECT.PAYMENT_PAGE,
                    action: 'reauthorize payment',
                  }}
                >
                  Authorize payment
                </Button>
              </Elements>
            )}
        </div>
      </div>

      {(reauthorizePayementMethod.error || error) && (
        <NotificationBox
          onClose={() => {
            setError(false);
          }}
          variant="error"
          title="An error occured while trying to authorize payment"
          className="w-full"
        >
          {reauthorizePayementMethod.error?.message}
        </NotificationBox>
      )}

      {subscription.data?.access.canSeeFailedPaymentInformation && (
        <PaymentNotifications />
      )}
    </div>
  );
};
