import { createFileRoute, Outlet } from '@tanstack/react-router';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import {
  createQueryPaymentHistory,
  createQuerySubscriptions,
  usePaymentHistory,
  useSubscriptionSuspense,
} from 'src/api/queries';
import { PausedSubscriptionNotification } from 'src/apps/manageSubscription/app/components/PausedNotification';
import { SubscriptionCanceledNotification } from 'src/apps/manageSubscription/app/components/SubscriptionCanceledNotification';
import { AppPage } from 'src/components/AppPage';
import { PillNav, PillTab } from 'src/components/PillNav';
import { createPageMetaTitle } from 'src/utils/createPageMetaTitle';
import { URLS } from 'src/utils/urls/urls';

export const Route = createFileRoute('/_auth/_layout/subscriptions')({
  staticData: { title: createPageMetaTitle('Subscription') },
  loader: async ({ context }) => {
    await Promise.all([
      context.queryClient.ensureQueryData(createQueryPaymentHistory()),
      context.queryClient.ensureQueryData(createQuerySubscriptions()),
    ]);
  },
  component: () => <SubscriptionPage />,
});

const SubscriptionPage = () => {
  const subscription = useSubscriptionSuspense();
  const paymentHistory = usePaymentHistory();

  const isSeatManagement =
    subscription.data?.type === 'stripe' &&
    subscription.data.managementType === 'seats';

  const access = subscription.data?.access;

  const canSeeMembersPage = isSeatManagement && access?.canSeeMembers;

  const hasPaymentHistory = (paymentHistory.data?.length ?? 0) > 0;

  const canSeePaymentPage =
    hasPaymentHistory ||
    access?.canSeeFailedPaymentInformation ||
    access?.canSeePaymentInformation ||
    access?.canSeeNextInvoiceAmount ||
    access?.canSeePaymentHistory ||
    access?.canChangePaymentInterval;

  const showNavbar = canSeeMembersPage || canSeePaymentPage;

  return (
    <AppPage.Container>
      <div className="mx-auto max-w-lxl px-16 lm:px-32">
        <SubscriptionCanceledNotification />
      </div>

      <AppPage.Header title="Subscription" />

      <AppPage.Content className="max-w-lxl">
        {showNavbar && (
          <PillNav>
            <PillTab
              to={URLS.SUBSCRIPTION_PLAN}
              data-cy="plan-tab-link"
              componentLocation={ComponentLocations.MANAGE_CONNECT.MAIN}
            >
              Plan
            </PillTab>
            <PillTab
              to={URLS.SUBSCRIPTION_PAYMENT}
              data-cy="payment-tab-link"
              componentLocation={ComponentLocations.MANAGE_CONNECT.MAIN}
            >
              Payment
            </PillTab>
            {canSeeMembersPage && (
              <PillTab
                to={URLS.SUBSCRIPTION_MEMBERS}
                data-cy="members-tab-link"
                componentLocation={ComponentLocations.MANAGE_CONNECT.MAIN}
              >
                Members
              </PillTab>
            )}
          </PillNav>
        )}
        <PausedSubscriptionNotification subscription={subscription.data} />

        <Outlet />
      </AppPage.Content>
    </AppPage.Container>
  );
};
